import React from "react";
import classNames from "classnames";
import Text from "../../components/ui/Text";
import "./Tag.css";

export default function Tag({ className, children: content, onClick }) {
  return (
    <div onClick={onClick} className={classNames("ingrid-tag", className)}>
      <Text small color="white">
        {content}
      </Text>
    </div>
  );
}
