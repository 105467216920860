import { useQuery } from "@apollo/react-hooks";
import { navigate } from "@reach/router";
import gql from "graphql-tag";
import React from "react";
import IconWarning from "../../assets/icons/icon-warning.svg";
import Organization from "../../components/ingrid/Organization";
import { createLinkToModal } from "../../components/Modal.js";
import Button from "../../components/ui/Button";
import DropdownButton from "../../components/ui/DropdownButton";
import useUrlParams from "../../hooks/useUrlParams";
import FilterRow from "./FilterRow";
import "./SuperAdmin.css";
import Loading from "../../components/ui/Loading";

const ORG_PER_SCREEN = 100;

const ORGANIZATIONS_QUERY = gql`
  query SAOrganizationList($offset: Int, $where: SequelizeJSON, $order: String) {
    organizations(offset: $offset, limit: ${ORG_PER_SCREEN}, order: $order, where: $where) {
      id
      name
      usersOverview {
        count
      }
      created_at
    }
  }
`;

export default function SuperAdminScreen({ location }) {
  const [urlParams, { updateUrlParam }] = useUrlParams(location);

  const { data: { organizations } = {}, loading, error } = useQuery(
    ORGANIZATIONS_QUERY,
    {
      variables: {
        offset: 0,
        order: "reverse:created_at",
        where: {
          ...(urlParams.search
            ? { name: { iLike: "%" + urlParams.search + "%" } }
            : {})
        }
      }
    }
  );

  const handleClickCreate = () => {
    const link = createLinkToModal(location)("/organization/create");
    navigate(link);
  };

  const handleClickRemove = () => {
    const link = createLinkToModal(location)("/organization/remove");
    navigate(link);
  };

  return (
    <div className="superadmin-screen">
      <FilterRow
        className="superadmin-screen__filter-row"
        values={urlParams}
        onChange={updateUrlParam}
        itemsFilteredCount={{
          data: organizations?.length,
          label: " organizations"
        }}
      >
        <div>
          <DropdownButton bottom left small title="Actions">
            {[
              {
                Icon: IconWarning,
                label: "Remove organization",
                color: "danger",
                handler: handleClickRemove
              }
            ]}
          </DropdownButton>
        </div>
        <div>
          <Button small onClick={handleClickCreate}>
            Create organization
          </Button>
        </div>
      </FilterRow>
      <div>
        {loading && <Loading>Loading organizations ...</Loading>}
        {error && error.toString()}
      </div>
      <div className="organizations__grid">
        {organizations?.map(organization => (
          <Organization key={organization.id} organization={organization} />
        ))}
      </div>

      <p></p>
    </div>
  );
}
