import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import timezonesList from "tz-ids";
import IconTeam from "../../assets/icons/icon-team-create.svg";
import IconTeamEdit from "../../assets/icons/icon-team-edit.svg";
import Button from "../../components/ui/Button";
import Headline from "../../components/ui/Headline";
import Select from "../../components/ui/Select";
import Text from "../../components/ui/Text";
import "./TeamForm.css";

export const TEAMS_QUERY = gql`
  query organizationTeams($id: ID) {
    organization(id: $id) {
      id
      teams {
        id
        name
        timezone
        ideaCount
        userCount
      }
    }
  }
`;

export const UPDATE_TEAM_MUT = gql`
  mutation($id: ID, $organizationId: ID!, $name: String!, $timezone: String!) {
    upsertTeam(
      id: $id
      organizationId: $organizationId
      name: $name
      timezone: $timezone
    ) {
      id
      organizationId
      name
      timezone
    }
  }
`;

export default function TeamFormScreen({ organization, teamId, modal }) {
  const [formFields, setFormFields] = useState({
    id: teamId,
    name: "",
    timezone: ""
  });

  const alert = useAlert();
  useQuery(TEAMS_QUERY, {
    variables: { id: organization?.id },
    skip: !organization,
    onCompleted: ({ organization }) => {
      if (!teamId) return;
      const team = organization.teams.find(team => team.id === Number(teamId));
      const fullifiedFields = Object.keys(formFields).reduce(
        (fields, typeName) => ({
          [typeName]: team[typeName],
          ...fields
        }),
        {}
      );
      setFormFields(fullifiedFields);
    }
  });

  const [updateTeam, { error: err }] = useMutation(UPDATE_TEAM_MUT, {
    refetchQueries: ["orgTeams"]
  });

  if (err) console.warn("todo error handler", err);

  const handleFieldChange = typeName => arg => {
    const value = arg?.target?.value || arg?.value;
    setFormFields({ ...formFields, [typeName]: value });
  };

  const handleSubmit = async event => {
    event.preventDefault();
    await updateTeam({
      variables: {
        organizationId: organization?.id,
        ...formFields
      }
    });
    modal.close();
    alert.show("Team " + (teamId ? "updated" : "created") + " successfully", {
      type: "success"
    });
  };

  return (
    <div className="team-form-screen">
      <form onSubmit={handleSubmit}>
        <div className="team-form-screen__icon">
          {teamId ? (
            <IconTeamEdit width="96" fill="var(--color-primary)" />
          ) : (
            <IconTeam width="96" fill="var(--color-primary)" />
          )}
        </div>
        <Headline center>{teamId ? "Edit team" : "Create new team"}</Headline>
        <Text center block className="mb16">
          {teamId
            ? "Edit your team name and timezone."
            : "Pick a name and timezone of your new team. Users without a team can select it after their next login."}
        </Text>
        <div className="team-form-screen__form">
          <div className="mb16">
            <Headline small>Team name</Headline>
            <input
              className="ingrid-input ingrid-input--small"
              onChange={handleFieldChange("name")}
              name="name"
              value={formFields.name}
            />
          </div>
          <div className="mb32">
            <Headline small>Timezone</Headline>
            <Select
              maxMenuHeight={180}
              searchable
              dropup
              name="timezone"
              value={formFields.timezone}
              onChange={handleFieldChange("timezone")}
              options={timezonesList.map(value => ({
                label: value,
                value
              }))}
            />
          </div>
          <Button small fullwidth>
            {teamId ? "Edit" : "Create"} team
          </Button>
        </div>
      </form>
    </div>
  );
}
