import React from "react";
import IconCampaigns from "../../assets/icons/icon-campaign.svg";
import IconVoting from "../../assets/icons/icon-inno-approved.svg";
import IconInno from "../../assets/icons/icon-inno.svg";
import IconInvitation from "../../assets/icons/icon-invite.svg";
import IconPoll from "../../assets/icons/icon-poll.svg";
import IconTeams from "../../assets/icons/icon-team.svg";
import Text from "../../components/ui/Text";
import "./InfoPanel.css";

// import cn from "classnames";
// import PropTypes from "prop-types$";

export default function InfoPanel({ organization }) {
  const setFeatureColor = value => {
    const color = value ? "var(--color-green)" : "var(--color-gray-lighter)";
    return color;
  };
  return (
    <div className="organization-info">
      <div className="organization-info__container">
        <div className="organization-info__boxes">
          <div className="organization-info__box">
            <IconTeams
              width="16px"
              fill={setFeatureColor(organization.teamsEnabled)}
            />
            <Text small>Teams</Text>
          </div>
          <div className="organization-info__box">
            <IconInno
              width="16px"
              fill={setFeatureColor(organization.innovationProcessEnabled)}
            />
            <Text small>Innovations</Text>
          </div>
          <div className="organization-info__box">
            <IconPoll
              width="16px"
              fill={setFeatureColor(organization.pollsEnabled)}
            />
            <Text small>Polls</Text>
          </div>
          <div className="organization-info__box">
            <IconVoting
              width="16px"
              fill={setFeatureColor(organization.voteOutsideTeamEnabled)}
            />
            <Text small>Global voting</Text>
          </div>
          <div className="organization-info__box">
            <IconCampaigns
              width="16px"
              fill={setFeatureColor(organization.activeCampaignsEnabled)}
            />
            <Text small>Campaigns</Text>
          </div>
          <div className="organization-info__box">
            <IconInvitation
              width="16px"
              fill={setFeatureColor(organization.sendInvitationEmailsEnabled)}
            />
            <Text small>User invites</Text>
          </div>
        </div>
      </div>
    </div>
  );
}
