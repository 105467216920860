import React from "react";
import "./AddCard.css";
// import cn from "classnames";
// import PropTypes from "prop-types";
import { Link } from "@reach/router";
import Button from "../ui/Button";

import IconPlus from "../../assets/icons/icon-plus.svg";

export default function IngridAddCard({ link, ...pass }) {
  const Container = link ? Link : Button;
  return (
    <Container className="ingrid-add-card" {...pass} to={link}>
      <IconPlus width="24px" fill="white" />
    </Container>
  );
}
