import { useQuery } from "@apollo/react-hooks";
import React, { useState } from "react";
import Button from "../../components/ui/Button";
import Select from "../../components/ui/Select";
import Text from "../../components/ui/Text";
import "./CampaignReuse.css";
import { navigate } from "@reach/router";

import useUrlParams from "../../hooks/useUrlParams";
import { useAlert } from "react-alert";
import { CAMPAIGNS_QUERY } from "../Campaigns";
import IngridCampaign from "../../components/ingrid/Campaign";
import useLoadFromTemplate from "../../hooks/useLoadFromTemplate";

const findCampaign = campaigns => campaignId =>
  campaigns.find(({ id }) => id === Number(campaignId));

export default function CampaignReuseScreen({ organization, location, modal }) {
  const [urlParams] = useUrlParams(modal.location);
  const [selectedCampaign, selectCampaign] = useState(urlParams.campaign);

  const { data: { campaigns: { items: campaigns = [] } = {} } = {} } = useQuery(
    CAMPAIGNS_QUERY,
    {
      variables: {
        organizationId: organization?.id
      },
      skip: !organization
    }
  );

  const alert = useAlert();
  const loadFromTemplate = useLoadFromTemplate(({ id }) => {
    if (id) {
      alert.success("Campaign reused");
      modal.close();
      navigate(`/campaigns/create/${id}`);
    }
  });

  const handleSelectCampaign = option => {
    if (!option) return;
    if (option.value) selectCampaign(String(option.value));
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (!selectedCampaign) return;
    loadFromTemplate(selectedCampaign);
  };

  return (
    <div className="campaign-reuse-screen">
      <h2>
        <Text large>Reuse campaign </Text>
      </h2>
      <form onSubmit={handleSubmit} className="campaign-reuse-screen__form">
        <div>
          <label>
            <h3>Select campaign:</h3>
            <Select
              maxOptionsHeight="180px"
              searchable
              value={Number(selectedCampaign)}
              onChange={handleSelectCampaign}
              options={campaigns
                ?.filter(({ state }) => state.toLowerCase() !== "draft")
                .map(({ id, name }) => ({
                  value: id,
                  label: name
                }))}
            />
          </label>
        </div>
        <div className="campaign-reuse-screen__preview">
          {selectedCampaign ? (
            <IngridCampaign
              campaign={findCampaign(campaigns)(selectedCampaign)}
              noMenu
            />
          ) : (
            <div className="campaign-reuse-screen__preview-placeholder" />
          )}
        </div>
        <div>
          <Button fullwidth type="submit" disabled={!selectedCampaign}>
            Reuse
          </Button>
        </div>
      </form>
    </div>
  );
}
