import React from "react";
import "./Notifications.css";
import Notification from "../../../components/ingrid/Notification";

Notifications.validate = () => true;

export default function Notifications({
  template: { planTemplateNotifications: notifications = [] } = {}
}) {
  return (
    <div className="create-campaign-wizard__notifications">
      <p className="color-danger">
        Notifikace jsou pro šablony jen ty automatický a nejni je teď možný
        měnit.
      </p>
      <div className="notifications__timeline__container">
        <div>
          {notifications.map(notification => (
            <div
              key={notification.id}
              className="notifications__timeline notifications__timeline--upcoming"
            >
              <Notification notification={notification} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
