import React from "react";
import { Link } from "@reach/router";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

import Card from "../ui/Card";
import Text from "../ui/Text";
import classNames from "classnames";
import "./Template.css";

import CampaignBadgeInno from "../../assets/icons/campaign-badges/badge-category-inno.svg";
import CampaignBadgeFun from "../../assets/icons/campaign-badges/badge-category-fun.svg";
import IconCampaigns from "../../assets/icons/icon-campaign.svg";

import IconMenu from "../../assets/icons/icon-kebab-big.svg";
import IconEdit from "../../assets/icons/icon-edit.svg";
import IconDelete from "../../assets/icons/icon-cross.svg";
import Dropdown from "../ui/Dropdown";

const DELETE_TEMPLATE_MUTT = gql`
  mutation($id: ID!) {
    deletePlanTemplate(id: $id)
  }
`;

const templateIdeasCount = ({ categories }) =>
  categories?.reduce((total, category) => total + category.ideaIds.length, 0);

export default function IngridTemplate({
  template: { id, planId, name, planTemplateCategories: categories } = {},
  ...pass
}) {
  const [deleteTemplate] = useMutation(DELETE_TEMPLATE_MUTT, {
    refetchQueries: ["PlanTemplates"]
  });

  const handleRemoveTemplate = async () => {
    await deleteTemplate({ variables: { id } });
  };

  return (
    <div className="ingrid-template">
      <Card fullwidth {...pass}>
        <div className={classNames("ingrid-template__header-container")}>
          <div className="ingrid-template__header">
            <Link
              className={classNames("ingrid-template__header-link")}
              to={`/templates/${planId}/${id}`}
            >
              <div className="ingrid-template__header-name">
                {name || <i>Not named yet</i>}
              </div>
            </Link>
          </div>

          <div className="ingrid-template__header-menu">
            <IconMenu width="24" />

            <div className="ingrid-template__header-dropdown">
              <Dropdown
                actions={[
                  {
                    Icon: IconEdit,
                    label: "Edit template",
                    link: `/templates/${planId}/${id}`
                  },
                  {
                    Icon: IconDelete,
                    color: "danger",
                    label: "Delete template",
                    handler: handleRemoveTemplate
                  }
                ]}
              />
            </div>
          </div>
        </div>
        <div className="ingrid-template__content">
          {categories?.slice(0, 2).map(category => (
            <div className="ingrid-template__row" key={category.id}>
              {category.type === "INNO" ? (
                <div className="ingrid-template__icon-box">
                  <CampaignBadgeInno width="16px" height="16px" />
                </div>
              ) : (
                <div className="ingrid-template__icon-box">
                  <CampaignBadgeFun width="16px" height="16px" />
                </div>
              )}
              <div className="ingrid-template__category-type">
                <Text small bold color="gray-dark">
                  {category.type}
                </Text>
              </div>
              <div className="ingrid-template__category-name">
                <Text small bold truncate>
                  {category.name}
                </Text>
              </div>
              <div className="ingrid-template__category-count">
                <Link to={`/ideas/?campaign=${id}&categories=${category.id}`}>
                  {" "}
                  <Text small>{category.ideaCount}</Text>
                </Link>
              </div>
            </div>
          ))}
          <div className="ingrid-template__row">
            <div className="ingrid-template__icon-box ingrid-template__icon-box--nocircle">
              <IconCampaigns width="12px" />
            </div>
            <div className="ingrid-template__category-name ingrid-template__category-users">
              <Text small>Ideas total</Text>
            </div>
            <div className="ingrid-template__category-count">
              <Link to={`/ideas/?campaign=${id}`}>
                <Text small>{templateIdeasCount({ categories })}</Text>
              </Link>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
