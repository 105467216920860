/*
 * @flow
 */

export type PhotoType = {
  uri: string,
  size: number,
  type: string,
  lastModified: number
};

// All idea photos may be squares
export const PHOTO_SIZE = 1024;
export const PHOTO_QUALITY = 90;

export const getPhotoCompressFormat = ({ type }) => {
  // https://github.com/bamlab/react-native-image-resizer#api
  switch (type) {
    case "image/jpeg":
      return "JPEG";
    case "image/png":
      return "PNG";
    default:
  }
};

export const getPhotoExtenstion = (photo: PhotoType) => {
  switch (photo.type) {
    case "image/jpeg":
      return "jpg";
    case "image/png":
      return "png";
    case "image/gif":
      return "gif";
    default:
      return "wtf";
  }
};

export const getPhotoMimeType = node => {
  if (node.type && node.type.startsWith("image")) {
    return node.type;
  }

  switch (getPhotoExtenstion(node.image)) {
    case "jpg":
    case "jpeg":
    case "heic": // iOS 11 (iPhone X)
      return "image/jpeg";
    case "png":
      return "image/png";
    default:
      return "image/png";
  }
};
