import React from "react";
import "./IdeasExport.css";

export default function IdeaExport({ location, organization }) {
  return (
    <div className="export-body">
      <div className="export-idea__grid">
        {[...Array(8)].map((e, i) => (
          <div className="export-idea" key={i}>
            <div className="export-idea__image" />
            <div className="export-idea__description">
              <p>
                This idea has an amazing description, even it says nothing
                important or interesting.
              </p>
            </div>
            <div className="export-idea__user">
              <p>perkele@ingrid.io</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
