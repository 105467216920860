import React from "react";
import IconStarEmpty from "../../assets/icons/icon-star-empty.svg";
import IconStarFull from "../../assets/icons/icon-star-full.svg";

const defaultProps = {
  total: 5,
  filled: 4
};

export default function IconStars(initialProps) {
  const props = { ...defaultProps, ...initialProps };

  let icons = [];
  for (let i = 0; i < props.total; i++) {
    if (i < props.filled) {
      icons.push(<IconStarFull key={i} width="100%" />);
    } else {
      icons.push(<IconStarEmpty key={i} width="100%" />);
    }
  }

  return (
    <React.Fragment>
      {icons.map(i => {
        return i;
      })}
    </React.Fragment>
  );
}
