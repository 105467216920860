import { useQuery } from "@apollo/react-hooks";
import { Link, navigate } from "@reach/router";
import cs from "classnames";
import gql from "graphql-tag";
import React from "react";
import Idea from "../../components/ingrid/Idea";
import { createLinkToModal } from "../../components/Modal";
import Button from "../../components/ui/Button";
import Card from "../../components/ui/Card";
import Headline from "../../components/ui/Headline";
import Text from "../../components/ui/Text";
import useCreateCampaign from "../../hooks/useCreateCampaign";
import IconRunning from "../../assets/icons/campaign-states/icon-state-running.svg";
import * as format from "../../library/format";
// import * as format from "../../library/format";
import "./CurrentCampaigns.css";

// const notificationsWhere = () => {
//   const currentDate = new Date().toISOString().split("T")[0];
//   return { sendAt: { gt: currentDate } };
// };

const RUNNING_CAMPAIGNS_QUERY = gql`
  query RunningCampaigns($organizationId: ID!) {
    campaigns(
      organizationId: $organizationId
      state: RUNNING
      where: { type: "ACTIVE" }
    ) {
      items {
        id
        name
        from
        to
        users(limit: 3) {
          id
          email
          rank
        }
        teams(limit: 3) {
          id
          name
          rank
        }

        campaignIdeas(limit: 9, order: "organization_order") {
          id
          order
          idea {
            id
            image
            value
            innovationProcess {
              state
            }
            description
            createdBy {
              id
              email
            }
          }
        }
      }
    }
  }
`;

const READY_CAMPAIGNS_QUERY = gql`
  query ReadyCampaigns($organizationId: ID!) {
    campaigns(organizationId: $organizationId, state: READY) {
      items {
        id
        name
      }
    }
  }
`;

export default function CurrentCampaigns({ location, organization }) {
  const organizationId = organization?.id;

  const { error, data: { campaigns } = {} } = useQuery(
    RUNNING_CAMPAIGNS_QUERY,
    {
      variables: { organizationId },
      skip: !organizationId,
      fetchPolicy: "cache-and-network"
    }
  );

  const { data: { campaigns: nextCampaigns } = {} } = useQuery(
    READY_CAMPAIGNS_QUERY,
    {
      variables: { organizationId },
      skip: !organizationId
    }
  );

  const createCampaign = useCreateCampaign(
    { organization, type: "ACTIVE" },
    campaignId => {
      if (campaignId) navigate(`/campaigns/create/${campaignId}`);
    }
  );

  if (error) {
    return (
      <div className="current-campaigns current-campaigns--error">
        <Text small center color="error">
          {error.toString()}
        </Text>
      </div>
    );
  }

  if (campaigns?.items.length === 0) {
    return (
      <Card className="current-campaigns--empty">
        <Text block className="mb16">
          There are no campaigns running in the moment{" "}
          {nextCampaigns?.items.length > 0 ? (
            <span>
              and <b>{nextCampaigns?.items.length}</b> scheduled.
            </span>
          ) : (
            <span>and nothing scheduled.</span>
          )}
        </Text>

        <Button onClick={createCampaign}>Create new campaign</Button>
      </Card>
    );
  }

  // const showNotificationDetail = (notificationId, location) => {
  //   navigate(
  //     createLinkToModal(location)(`/notifications/${notificationId}`, {
  //       campaign: selectedCampaignId
  //     })
  //   );
  // };

  return (
    <div>
      <div className="current-campaigns__main-header">
        <div className="current-campaigns__main-headline">
          <IconRunning width="24px" className="mr8" />
          <Headline>Currently running campaigns</Headline>
        </div>
      </div>
      {campaigns?.items?.map(campaign => (
        <div className="current-campaign" key={campaign.id}>
          <div className="current-campaign__header">
            <div className="current-campaign__header-left">
              <Text block large className="mr16">
                {campaign.name}
              </Text>
              <Text nowrap block>
                {format.onlyDate(campaign.from)}
                {" - "}
                {format.onlyDate(campaign.to)}
              </Text>
            </div>
            <div className="current-campaign__header-right">
              {campaign?.campaignIdeas?.length > 0 && (
                <Link
                  className="current-campaigns__link"
                  to={`../ideas/?campaign=${campaign.id}`}
                >
                  Show all ideas in grid
                </Link>
              )}
              <Button
                small
                onClick={() => {
                  navigate(`/campaigns/${campaign.id}`);
                }}
              >
                Open detail
              </Button>
            </div>
          </div>
          {(campaign?.users?.length > 0 ||
            campaign?.campaignIdeas?.length > 0) && (
            <div className="current-campaign__content">
              <div className="current-campaigns__users mr16">
                <div className="ingrid-leaderboard__users">
                  {campaign?.users.length > 0 ? (
                    campaign?.users.map(user => (
                      <div key={user.id}>
                        <div className={cs("ingrid-leaderboard__user-row")}>
                          <div className="ingrid-leaderboard__user-email">
                            <Text truncate>{user.email}</Text>
                          </div>
                          <div
                            className={cs(
                              "ingrid-leaderboard__user-order",
                              `ingrid-leaderboard__user-order--${user.rank}`
                            )}
                          >
                            <Text tiny nowrap>
                              {user.rank}.
                            </Text>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="current-campaigns__no-leaderboard">
                      Nobody is in leaderboards yet.
                    </div>
                  )}
                </div>
              </div>
              <div className="current-campaigns__ideas">
                <div className="current-campaigns__ideas-grid">
                  {campaign.campaignIdeas?.map(idea => (
                    <div key={idea.idea.id} className="current-campaigns__idea">
                      <Idea
                        idea={idea.idea}
                        currency={organization.currency}
                        onClick={() =>
                          navigate(
                            createLinkToModal(location)(
                              `/ideas/${idea.idea.id}`
                            )
                          )
                        }
                      />
                      {idea.order && (
                        <div
                          className={cs(
                            "current-campaigns__idea-order",
                            `current-campaigns__idea-order--${idea.order}`
                          )}
                        >
                          {idea.order + "."}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          {/* I got rid of this for now. Don't see a value. */}
          {/* <div className="current-campaigns__notifications">
          <div className="current-campaigns__header">
            <Headline className="current-campaigns__headline" small>
              Notifications schedule
            </Headline>
          </div>
          <div className="current-campaigns__notifications-container">
            {selectedCampaign?.notifications.length > 0 ? (
              selectedCampaign?.notifications?.map(notification => (
                <Card
                  key={notification.id}
                  className="current-campaigns__notification-card"
                  onClick={() =>
                    showNotificationDetail(notification.id, location)
                  }
                >
                  <Text truncate>{notification.title}</Text>
                  <Text bold>{format.date(notification.sendAt)}</Text>
                </Card>
              ))
            ) : (
              <Text>Nothing scheduled.</Text>
            )}
          </div>
        </div> */}
        </div>
      ))}
    </div>
  );
}
