import React, { useCallback } from "react";
import "./TemplateForm.css";
import wizard from "./Wizard";
import Basic from "./Wizard/Basic";
import Categories from "./Wizard/Categories";
import Notifications from "./Wizard/Notifications";
import Button from "../../components/ui/Button";
import { navigate } from "@reach/router";
import { useMutation, useQuery, useApolloClient } from "@apollo/react-hooks";
import gql from "graphql-tag";
import debounce from "../../library/debounce";

export const TEMPLATE_QUERY = gql`
  query Template($planId: ID!, $id: ID!) {
    planTemplate(planId: $planId, id: $id) {
      id
      name
      description
      planId
      planTemplateCategories {
        id
        name
        description
        type
        main
        enabled
        planTemplateId
        ideaIds
      }
      planTemplateNotifications {
        id
        title
        content
        type
        planTemplateId
      }
    }
  }
`;

const UPSERT_TEMPLATE_MUTT = gql`
  mutation($planId: ID!, $id: ID, $name: String, $description: String) {
    upsertPlanTemplate(
      id: $id
      planId: $planId
      name: $name
      description: $description
    ) {
      id
      name
      description
      planId
      planTemplateCategories {
        id
        name
        description
        type
        main
        enabled
        planTemplateId
        ideaIds
      }
      planTemplateNotifications {
        id
        title
        content
        type
        planTemplateId
      }
    }
  }
`;

export default function CreatePollFormScreen({
  planId,
  templateId,
  organization
}) {
  const { data: { planTemplate: template } = {} } = useQuery(TEMPLATE_QUERY, {
    variables: { id: templateId, planId },
    skip: false
  });

  const [mutateUpsertTemplate] = useMutation(UPSERT_TEMPLATE_MUTT, {
    refetchQueries: ["TemplatePlans", "PlanTemplates"]
  });
  const apollo = useApolloClient();

  const update = useCallback(
    updates => {
      const updatedTemplate = {
        id: Number(templateId),
        ...template,
        ...updates
      };

      // Just store this locally, no mutations. We'll grap the current `template` object when we'd like to save updates
      apollo.writeQuery({
        query: TEMPLATE_QUERY,
        variables: { id: templateId, planId },
        data: {
          planTemplate: updatedTemplate
        }
      });
    },
    [templateId, template, apollo, planId]
  );
  // const isTemplateReady = [Basic, Categories,  Notifications].reduce(
  //   (isReady, WizardComponent) =>
  //     isReady && WizardComponent.validate(template),
  //   true
  // );

  const handleSave = async () => {
    const { errors } = await mutateUpsertTemplate({
      variables: {
        id: Number(templateId),
        planId,
        ...template
      },
      optimisticResponse: {
        upsertPlanTemplate: {
          id: Number(templateId),
          planId,
          ...template,
          __typename: "plan_template"
        }
      }
    });
    if (!errors) navigate("/templates", { replace: true });
  };

  const debouncedUpdate = useCallback(debounce(update, 300), [update]);
  const passProps = {
    templateId,
    template,
    organization,
    updateTemplate: debouncedUpdate
  };

  return (
    <div className="template-form-screen">
      {wizard(Basic, { order: 1, title: "Basic setup", ...passProps })}
      {wizard(Categories, { order: 2, title: "Categories", ...passProps })}
      {wizard(Notifications, {
        order: 3,
        title: "Notifications",
        ...passProps
      })}
      <div className="template-form-screen__controls">
        <Button onClick={handleSave}>SAVE TEMPLATE</Button>
      </div>
    </div>
  );
}
