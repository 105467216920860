import React, { useEffect, useCallback } from "react";
// import cs from "classnames";
import "./PollIdeas.css";
import Idea from "../../../components/ingrid/Idea";
import { createLinkToModal } from "../../../components/Modal";
import useUrlParams from "../../../hooks/useUrlParams";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useAlert } from "react-alert";
import useUpdateCampaignIdeas from "../../../hooks/useUpdateCampaignIdeas";
import IngridAddCard from "../../../components/ingrid/AddCard";

const CAMPAIGN_IDEAS_QUERY = gql`
  query CampaignExampleIdeas($campaignId: ID!) {
    campaign(id: $campaignId) {
      id # this is important due to cache
      campaignIdeas {
        id
        description
        idea {
          id
          image
          description
          createdBy {
            id
            email
          }
        }
      }
    }
  }
`;

const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
};

export default function PollIdeas({
  location,
  category,
  onIdeasUpdate,
  campaignId
}: {
  onIdeasUpdate: () => Promise
}) {
  const [urlParams, { clearAllUrlParams }] = useUrlParams(location);

  const {
    createdIdea: createdIdeaParam,
    pickedIdeas: pickedIdeasParam
  } = urlParams;

  useEffect(() => {
    if (!pickedIdeasParam) return;

    const urlIds =
      (Array.isArray(pickedIdeasParam)
        ? pickedIdeasParam // weird I have to do this, something is changing type [1,2] to "1,2"
        : pickedIdeasParam?.split(",")) || [];

    const updatedIdeaIDs = (category?.ideaIds || [])
      .concat(urlIds)
      .map(Number)
      .filter(onlyUnique);

    onIdeasUpdate(updatedIdeaIDs).then(refetchIdeas);
    clearAllUrlParams();
  }, [pickedIdeasParam]); // eslint-disable-line

  useEffect(() => {
    if (!createdIdeaParam) return;

    const updatedIdeaIDs = (category?.ideaIds || []).concat([
      Number(createdIdeaParam)
    ]);

    onIdeasUpdate(updatedIdeaIDs).then(refetchIdeas);
    clearAllUrlParams();
  }, [createdIdeaParam]); // eslint-disable-line

  const {
    data: { campaign: { campaignIdeas: ideas = [] } = {} } = {},
    refetch: refetchIdeas = () => {}
  } = useQuery(CAMPAIGN_IDEAS_QUERY, {
    skip: !campaignId,
    variables: {
      campaignId
    }
  });

  const addIdeaLink = createLinkToModal(location)("/ideas", {
    ccategory: category?.id,
    ccampaign: campaignId,
    context: "pick"
  });

  const update = useCallback(onIdeasUpdate, [campaignId, onIdeasUpdate]);

  const excludeIdea = ideaId => {
    clearAllUrlParams();
    // updateUrlParam({ ...urlParams, [key]: pickedIdeas });
    // setPickedIdeaIds(pickedIdeas);
    update(ideas.map(({ idea }) => idea.id).filter(id => id !== ideaId)).then(
      refetchIdeas
    );
  };

  const alert = useAlert(null);
  const updateCampaignIdeas = useUpdateCampaignIdeas({ campaignId }, result => {
    if (result) alert.success("Description for this example updated");
  });

  return (
    <div className="wizard-poll-ideas">
      {ideas.map(example => (
        <Idea
          key={example.id}
          example={example}
          onDescriptionEdit={description => {
            updateCampaignIdeas([{ id: example.idea.id, description }]);
          }}
          className="wizard-poll-ideas__idea"
          onImageClick={() => excludeIdea(example.idea.id)}
          idea={example.idea}
        />
      ))}
      <IngridAddCard link={addIdeaLink} />
    </div>
  );
}
