import React from "react";
import { navigate } from "@reach/router";
import { createLinkToModal } from "./Modal";
import "./AppCopyright.css";

export default function AppCopyright({ location }) {
  const handleClick = () => {
    const link = createLinkToModal(location)("/app/graph");
    navigate(link);
  };
  return (
    <div className="app-copyright">
      &copy; <span onClick={handleClick}>2019</span> TopMonks
    </div>
  );
}
