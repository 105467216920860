import classNames from "classnames";
import React from "react";
import "./Headline.css";

export default function Headline({
  small,
  large,
  inline,
  className,
  center,
  children: text,
  ...pass
}) {
  return (
    <div
      className={classNames(
        "headline",
        small && "headline--small",
        large && "headline--large",
        inline && "headline--inline",
        center && "headline--center",
        className
      )}
      {...pass}
    >
      {text}
    </div>
  );
}
