import React from "react";
import { navigate } from "@reach/router";
import "./PollCreate.css";
import IngridPoll from "../../components/ingrid/Poll";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import Button from "../../components/ui/Button";
import useCreateCampaign from "../../hooks/useCreateCampaign";
import LoadingSquares from "../../components/ui/LoadingSquares";

const DRAFTS_QUERY = gql`
  query PollsDrafts($organizationId: ID) {
    campaigns(
      organizationId: $organizationId
      order: "reverse:created_at"
      state: DRAFT
      where: { type: "PASSIVE" }
    ) {
      items {
        id
        name
        from
        to
        target
        state
        campaignStats {
          activeUsersCount
          ideaCount
        }
        categories {
          id
          name
          color
          type
          ideaCount
        }
        teams {
          id
          name
        }
      }
    }
  }
`;

export default function PollCreateScreen({ location, organization }) {
  const {
    loading,
    /*, error,*/ data: { campaigns: { items: drafts = [] } = {} } = {}
  } = useQuery(DRAFTS_QUERY, {
    variables: { organizationId: organization?.id },
    skip: !organization,
    fetchPolicy: "cache-and-network"
  });

  const createCampaign = useCreateCampaign(
    { organization, type: "PASSIVE" },
    pollId => {
      if (pollId) navigate(`/polls/create/${pollId}`);
    }
  );

  return (
    <div className="poll-create-screen">
      <h2>Create new</h2>
      <Button onClick={createCampaign}>Create new poll</Button>
      <h2>Drafts ({drafts.length})</h2>

      <div className="poll-create-screen__grid">
        {loading && <LoadingSquares count={5} height="184px" />}
        {drafts.map(draft => (
          <IngridPoll key={draft.id} campaign={draft} location={location} />
        ))}
      </div>
    </div>
  );
}
