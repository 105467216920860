import React, { useState, useEffect } from "react";
import "./Basic.css";
import Headline from "../../../components/ui/Headline";
import Checkbox from "../../../components/ui/Checkbox";
import DateTimeInput from "../../../components/ui/DateTimeInput";
import { useMemo } from "react";
import Text from "../../../components/ui/Text";
import useCampaignAvailableTargets from "../../../hooks/useCampaignAvailableTargets";

Basic.validate = ({ name, from, to } = {}) => {
  if (!name || !name.length) return false;

  if (!from || !to) return false;
  if (new Date(from) > new Date(to)) return false;

  return true;
};

export default function Basic({ campaign, updateCampaign, organization }) {
  const [campaignValues, setCampaignValues] = useState({
    name: "",
    description: "",
    from: "",
    to: "",
    isApprovable: false
  });

  useEffect(() => {
    if (!campaign) return;
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    setCampaignValues({
      name: campaign.name,
      description: campaign.description,
      from: campaign.from || today.toISOString(),
      to: campaign.to || tomorrow.toISOString(),
      isApprovable: campaign.isApprovable
    });
  }, [Boolean(campaign)]); // eslint-disable-line

  // Hotfix form propagate default values.
  // It enables (validation) to one click publish campaign if created from template or older campaign
  useEffect(() => {
    if (
      (campaignValues.from && !campaign.from) ||
      (campaignValues.to && !campaign.to)
    ) {
      updateCampaign(campaignValues);
    }
  }, [campaignValues.from, campaignValues.to]); // eslint-disable-line

  const handleFormValueChange = typeName => ({ target: { value } = {} }) => {
    const newCampaignValues = {
      ...campaignValues,
      [typeName]: value
    };

    setCampaignValues(newCampaignValues);
    updateCampaign(newCampaignValues);
  };

  const crossedDurationDates = useMemo(
    () => new Date(campaignValues.from) > new Date(campaignValues.to),
    [campaignValues.from, campaignValues.to]
  );

  const availableTargetIds = useCampaignAvailableTargets({
    campaign,
    organization
  });

  const showAvailabilityError =
    !crossedDurationDates && !availableTargetIds.length;

  return (
    <div className="campaign-wizard-basic">
      <label>
        <div className="campaign-wizard__section">
          <Headline small className="campaign-wizard__headline">
            Campaign name
            <Text color="danger">*</Text>
          </Headline>
          <div>
            <input
              className="ingrid-input ingrid-input--small"
              type="text"
              value={campaignValues.name || ""}
              onChange={handleFormValueChange("name")}
            />
          </div>
        </div>
      </label>
      <label>
        <div className="campaign-wizard__section">
          <Headline small className="campaign-wizard__headline">
            Campaign description
          </Headline>

          <textarea
            className="ingrid-textarea ingrid-input--small"
            rows="4"
            onChange={handleFormValueChange("description")}
            value={campaignValues.description || ""}
          >
            {campaignValues.description}
          </textarea>
        </div>
      </label>
      <div className="campaign-wizard__section--dates">
        <label className="campaign-wizard-basic__label mr8">
          <Headline small className="campaign-wizard__headline">
            Date from:
            <Text color="danger">*</Text>
          </Headline>

          <DateTimeInput
            noTime
            value={campaignValues.from}
            onChange={handleFormValueChange("from")}
          />
        </label>
        <label className="campaign-wizard-basic__label">
          <Headline small className="campaign-wizard__headline">
            Date to:
            <Text color="danger">*</Text>
          </Headline>
          <DateTimeInput
            noTime
            value={campaignValues.to}
            onChange={handleFormValueChange("to")}
          />
        </label>
      </div>

      {crossedDurationDates && (
        <div className="campaign-wizard-basic__error">
          <Text color="error">End date can't be before starting date. </Text>
        </div>
      )}

      {showAvailabilityError && (
        <div className="campaign-wizard-basic__error">
          <Text color="error">
            There are no available targets for selected campaign duration.
          </Text>
        </div>
      )}

      <div className="campaign-wizard__section--inline campaign-wizard__checkbox">
        <Checkbox
          name="automatic"
          value={campaignValues.isApprovable}
          onChange={({ value }) => {
            handleFormValueChange("isApprovable")({ target: { value } });
          }}
        >
          Manually evaluate this campagn.
        </Checkbox>
      </div>
    </div>
  );
}
