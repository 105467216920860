import React from "react";
import "./DateTimeInput.css";
import cn from "classnames";
// import PropTypes from "prop-types$";
import DatePicker from "react-datepicker";

const TIME_INTERVALS = 5; // minutes

export default function DateTimeInput({
  onChange,

  value,
  dropup = false,
  noTime,
  ...pass
}) {
  const handleChange = date => {
    let value = date.toISOString();
    if (noTime) value = value.split("T")[0];
    onChange({
      target: { value }
    });
  };

  const today = (date => {
    date.setMinutes((date.getMinutes() / TIME_INTERVALS).toFixed(0) * 5);
    return date;
  })(new Date());

  if (value) {
    value = new Date(value);
    if (!noTime && value.getHours() < 8) {
      value.setHours(today.getHours());

      // Hotfix. This component uses own default time based on client's time,
      // but if user does not change such datetime value, parent component wont know it, so:
      setTimeout(() => {
        onChange({ target: { value: value.toISOString() } });
      }, 20);
    }
  }

  return (
    <div className={cn("date-time-input", dropup && "date-time-input--dropup")}>
      <DatePicker
        minDate={new Date()}
        selected={value || undefined}
        onChange={handleChange}
        showTimeSelect={!noTime}
        timeFormat="HH:mm"
        timeIntervals={TIME_INTERVALS}
        timeCaption="time"
        dateFormat={noTime ? "MMMM d, yyyy" : "MMMM d, yyyy HH:mm"}
        {...pass}
      />
    </div>
  );
}
