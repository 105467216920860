// import Button from "../../components/ui/Button";
// import Text from "../../components/ui/Text";
import { useQuery } from "@apollo/react-hooks";
import { navigate } from "@reach/router";
import gql from "graphql-tag";
import React from "react";
import IconPlus from "../../assets/icons/icon-plus.svg";
import TeamRow from "../../components/ingrid/TeamRow";
import { createLinkToModal } from "../../components/Modal";
import Button from "../../components/ui/Button";
import Card from "../../components/ui/Card";
import SortIcon from "../../components/ui/SortIcon";
import Text from "../../components/ui/Text";
// import gql from "graphql-tag";
// import UserRow from "../../components/ingrid/UserRow";
// import FilterRow from "../../components/ingrid/FilterRow";
import useUrlParams from "../../hooks/useUrlParams";
import "./Teams.css";
const ORDER_ALLOWED = ["userCount", "ideaCount"];
const ORDER_PREFIX = "reverse";

const TEAMS_QUERY = gql`
  query orgTeams(
    $orgId: ID!
    $order: String
    $limit: Int
    $where: SequelizeJSON
    $offset: Int
  ) {
    teams(
      organizationId: $orgId
      limit: $limit
      where: $where
      offset: $offset
      order: $order
    ) {
      id
      name
      timezone
      ideaCount
      userCount
    }
  }
`;

const getOrderParams = urlParams => {
  if (!urlParams.order) {
    return;
  }
  const orderArray = urlParams.order.split(":");
  const field = orderArray.pop();
  const isReversed = orderArray.length === 1 && orderArray[0] === ORDER_PREFIX;

  var full = "name";
  if (ORDER_ALLOWED.includes(field)) {
    full = urlParams.order;
  }
  return { field, isReversed, full };
};

export default function TeamsScreen({ location, organization }) {
  const [urlParams, { updateUrlParam }] = useUrlParams(location);

  const orderParams = getOrderParams(urlParams);
  const { data } = useQuery(TEAMS_QUERY, {
    variables: {
      orgId: organization?.id,
      order: orderParams?.full
    },
    fetchPolicy: "cache-and-network"
  });

  const sortBy = field => {
    var order = field;
    if (orderParams?.field === field) {
      if (orderParams?.isReversed) {
        order = false;
      } else {
        order = ORDER_PREFIX + ":" + order;
      }
    }
    urlParams.order = order;
    updateUrlParam(urlParams);
  };

  const sortByIndicator = field => {
    if (orderParams?.field === field) {
      return orderParams?.isReversed ? "down" : "up";
    }
  };

  return (
    <div className="teams-screen">
      <div className="teams-screen__tools-row">
        <div>
          <Button
            icon
            small
            onClick={() => {
              const modalLink = createLinkToModal(location)(
                "/users/teams/create"
              );
              navigate(modalLink);
            }}
          >
            <IconPlus width="12px" fill="var(--color-white)" className="mr4" />
            Create team
          </Button>
        </div>
      </div>

      <div className="teams-screen__content">
        <Card fullwidth className="teams-screen__card">
          <div className="teams-screen__header">
            <div className="teams-screen__header-column--first">
              <Text small bold>
                Team name
              </Text>
            </div>
            <div className="teams-screen__header-column">
              <Text small bold>
                Timezone
              </Text>
            </div>
            <div
              className="teams-screen__header-column"
              onClick={() => sortBy("userCount")}
            >
              <Text small bold>
                User count
              </Text>
              <SortIcon state={sortByIndicator("userCount")} />
            </div>
            <div
              className="teams-screen__header-column"
              onClick={() => sortBy("ideaCount")}
            >
              <Text small bold>
                Ideas count
              </Text>
              <SortIcon state={sortByIndicator("ideaCount")} />
            </div>
            <div className="teams-screen__header-column teams-screen__header-column--last">
              <Text small bold></Text>
            </div>
          </div>
          <div className="teams-screen__list-container">
            {data?.teams.map(team => (
              <TeamRow
                team={team}
                key={team.id}
                organizationId={organization?.id}
                loaded={Boolean(organization)}
                location={location}
              />
            ))}
          </div>
        </Card>
      </div>
    </div>
  );
}
