import cs from "classnames";
import React from "react";
import IconCheck from "../../assets/icons/icon-checkmark.svg";
import IconDot from "../../assets/icons/icon-dot.svg";
import IconIdeas from "../../assets/icons/icon-idea.svg";
import IconInnoImplementation from "../../assets/icons/icon-inno-implementing.svg";
import IconInvite from "../../assets/icons/icon-invite.svg";
import IconAdmin from "../../assets/icons/icon-star-full.svg";
import Badge from "../ingrid/Badge";
import "./User.css";

export default function IngridUser({
  user: { email, superadmin, role, state } = {},
  small = false,
  invert = false,
  badge = true
}) {
  const admin = role === "admin";

  return (
    <div
      className={cs("user", invert && "user--invert", small && "user--small")}
    >
      {badge && (
        <Badge
          small={small}
          invert={invert}
          className="user__badge"
          string={email}
        />
      )}
      {state === "INVITED" && (
        <div data-titleright="User was invited. Invitation not accepted yet.">
          <IconInvite
            width="12px"
            fill="var(--color-gray-light)"
            className="mr8"
          />
        </div>
      )}
      {state === "REGISTERED" && (
        <div data-titleright="User accepted his invitation, but is still inactive.">
          <IconCheck
            width="12px"
            fill="var(--color-gray-light)"
            className="mr8"
          />
        </div>
      )}
      {state === "ACTIVE" && (
        <div data-title-right="User is active. He voted in campaign/poll.">
          <IconDot width="12px" fill="var(--color-alert)" className="mr8" />
        </div>
      )}
      {state === "ENGAGED" && (
        <div data-title-right="User is engaged. He uploaded an idea.">
          <IconDot width="12px" fill="var(--color-green)" className="mr8" />
        </div>
      )}
      {state === "INNOVATOR" && (
        <div data-title-right="This user shares the most liked ideas in your company.">
          <IconIdeas width="12px" className="mr8" />
        </div>
      )}
      {state === "REPLICATOR" && (
        <div data-title-right="This user replicates ideas in your company.">
          <IconInnoImplementation width="12px" className="mr8" />
        </div>
      )}
      <div className={cs("user__email", invert && "user__email--invert")}>
        {email}
      </div>
      {superadmin && (
        <div data-title-right="This user is Ingrid superadmin. If you are unsure of anything, contact him.">
          <IconAdmin
            width="12px"
            fill="var(--color-secondary)"
            className="ml4"
          />
        </div>
      )}
      {!superadmin && admin && (
        <div data-title-right="This user is admin in your organization.">
          <IconAdmin fill="var(--color-black)" width="12px" className="ml4" />
        </div>
      )}
    </div>
  );
}
