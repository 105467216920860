import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useState } from "react";
import Button from "../../components/ui/Button";
import Select from "../../components/ui/Select";
import Text from "../../components/ui/Text";
import "./TemplatesAssign.css";
import { TEMPLATE_PLANS_QUERY } from "../Templates";
import useUrlParams from "../../hooks/useUrlParams";
import { useAlert } from "react-alert";

const ORGANIZATIONS_QUERY = gql`
  query {
    organizations {
      id
      name
    }
  }
`;

const ASSIGN_PLAN_MUTATION = gql`
  mutation($planId: ID!, $organizationId: ID!) {
    assignPlanToOrganization(planId: $planId, organizationId: $organizationId)
  }
`;

export default function TemplatesAssignScreen({ location, modal }) {
  const [urlParams] = useUrlParams(modal.location);
  const [selectedOrganization, selectOrganization] = useState(
    urlParams.organization
  );
  const [selectedPlan, selectPlan] = useState(urlParams.plan);
  const { data: { organizations = [] } = {} } = useQuery(ORGANIZATIONS_QUERY);
  const { data: { plans = [] } = {} } = useQuery(TEMPLATE_PLANS_QUERY);
  const [assignPlan] = useMutation(ASSIGN_PLAN_MUTATION, {
    refetchQueries: ["CampaignTemplates"]
  });

  const alert = useAlert();

  const handleSelectOrganiztion = option => {
    if (!option) return;
    if (option.value) selectOrganization(String(option.value));
  };

  const handleSelectPlan = option => {
    if (!option) return;
    if (option.value) selectPlan(String(option.value));
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (!selectedOrganization) return;

    const { errors } = await assignPlan({
      variables: {
        planId: selectedPlan,
        organizationId: selectedOrganization
      }
    });
    if (!errors) {
      alert.success("Plan succesfully assigned");
      modal.close();
    }
  };

  return (
    <div className="templates-assign-screen">
      <h2>
        <Text large>Assign templates plan</Text>
      </h2>
      <form onSubmit={handleSubmit} className="templates-assign-screen__form">
        <div>
          <label>
            <h3>Select plan:</h3>
            <Select
              maxOptionsHeight="180px"
              searchable
              value={Number(selectedPlan)}
              onChange={handleSelectPlan}
              options={plans?.map(({ id, name }) => ({
                value: id,
                label: name
              }))}
            />
          </label>
          <label>
            <h3>Select organization:</h3>
            <Select
              dropup
              maxOptionsHeight="180px"
              searchable
              value={Number(selectedOrganization)}
              onChange={handleSelectOrganiztion}
              options={organizations?.map(({ id, name }) => ({
                value: id,
                label: name
              }))}
            />
          </label>
        </div>
        <div>
          <Button
            danger
            fullwidth
            type="submit"
            disabled={!selectedOrganization}
          >
            Assign
          </Button>
        </div>
      </form>
    </div>
  );
}
