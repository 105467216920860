import React from "react";
import classNames from "classnames";

import Text from "./Text";
import IconLoading from "../../assets/icons/icon-loading.svg";
import "./Loading.css";

export default function Loading({ className, small, children }) {
  return (
    <div
      className={classNames(
        !small && "ingrid-loading",
        small && "ingrid-loading--small"
      )}
    >
      <IconLoading
        className={classNames(
          "mb8",
          "ingrid-loading__icon",
          small && "ingrid-loading__icon--small",
          "ingrid-loading__rotate"
        )}
      />
      <Text>{children}</Text>
    </div>
  );
}
