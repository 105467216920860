import React from "react";
import ChartCampaignStats from "../../components/ingrid/ChartCampaignStats";
import "./PollDashboard.css";

export default function PollDashboard({ campaignStats }) {
  return (
    <div className="poll-dashboard">
      <ChartCampaignStats campaignStats={campaignStats} />
    </div>
  );
}
