import React from "react";
import { Link, navigate } from "@reach/router";
import classNames from "classnames";
import "./AppHeader.css";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import IngridLogo from "../assets/ingrid-logo.svg";
import Select, { SelectPlaceholder } from "./ui/Select";
import Headline from "./ui/Headline";
import useUrlParams from "../hooks/useUrlParams";
import Button from "./ui/Button";
import IconPlus from "../assets/icons/icon-plus.svg";
import { createLinkToModal } from "./Modal";

const ORGANIZATIONS_QUERY = gql`
  query {
    organizations(order: "name") {
      id
      name
    }
    superOrganization @client
  }
`;

const SELECT_ORQ_MUT = gql`
  mutation($id: ID!) {
    selectOrganization(id: $id) @client
  }
`;

const SUPER_ORQ_MUT = gql`
  mutation($active: Bool!) {
    superOrganization(active: $active) @client
  }
`;

export default function AppHeader({
  location,
  organization,
  user,
  title = "",
  createPath
}) {
  const { data: { organizations, superOrganization } = {} } = useQuery(
    ORGANIZATIONS_QUERY,
    {
      skip: !user
    }
  );
  const [selectOrganization] = useMutation(SELECT_ORQ_MUT);
  const [activateSuperOrganization] = useMutation(SUPER_ORQ_MUT);

  const [
    ,
    // ^ we do not need urlParams (the 1th value)
    { clearAllUrlParams, urlInfo }
  ] = useUrlParams(location);

  const handleSelectOrganization = async option => {
    if (!option) return;

    if (option.value) {
      await selectOrganization({ variables: { id: String(option.value) } });
      if (urlInfo.doUrlParamsIncludeAnyIdentificator) clearAllUrlParams();
      if (urlInfo.doesPathnameIncludeAnyIdentificator) navigate("/");
    }
  };

  const clickCreateButton = link => {
    if (link.modal) {
      const modalLink = createLinkToModal(location)(link?.path);
      navigate(modalLink);
    } else navigate(link?.path);
  };

  return (
    <div className="app-header">
      <div className="app-header__logo">
        <Link to="/">
          <IngridLogo fill="#ffffff" height="40px" />
        </Link>
      </div>
      <div className="app-header__content">
        <div className="app-header__left">
          <Headline className="app-header__title">{title}</Headline>
          {createPath && (
            <Button icon small onClick={() => clickCreateButton(createPath)}>
              <IconPlus
                width="12px"
                fill="var(--color-white)"
                className="mr8"
              />
              Create New
            </Button>
          )}
        </div>
        <div className="app-header__right">
          <div className="app-header__user">{user && user.email}</div>

          <div className={classNames("app-header__select")}>
            {organizations ? (
              <Select
                searchable={true}
                value={organization?.id || null}
                onChange={handleSelectOrganization}
                options={organizations.map(({ id, name }) => ({
                  label: name,
                  value: id
                }))}
                disabled={!organization}
              />
            ) : (
              <SelectPlaceholder />
            )}

            {user.superadmin && (
              <Button
                small
                secondary={!superOrganization}
                onClick={async () => {
                  await activateSuperOrganization({
                    variables: { active: !superOrganization }
                  });
                }}
              >
                Go {superOrganization ? "in" : "out"}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
