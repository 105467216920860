// import React, { useState } from "react";

import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

const UPDATE_INNO_MUT = gql`
  mutation($ideas: [idea_inno_process_admin_upsert]) {
    upsertIdeaInnovationProcess(ideas: $ideas) {
      id
      state
    }
  }
`;

export default function useSendIdeasToInno(callback: ideasInno => void) {
  const [mutateInno] = useMutation(UPDATE_INNO_MUT);

  return function sendIdeasToInno(ideas) {
    const ideasInnoUpdate = ideas.map(idea => ({
      id: idea?.id || idea,
      state: "SELECTED"
    }));

    return async () => {
      if (!ideasInnoUpdate.length) return;

      const {
        data: { upsertIdeaInnovationProcess: ideasInno }
      } = await mutateInno({
        variables: { ideas: ideasInnoUpdate },
        refetchQueries: ["IdeaDetail", "IdeasInno"]
      });

      callback(ideasInno);
    };
  };
}
