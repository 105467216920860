import React, { useState, useEffect } from "react";
import "./Basic.css";
import Headline from "../../../components/ui/Headline";

Basic.validate = ({ name, description } = {}) => {
  if (!name || !name.length) return false;
  return true;
};

export default function Basic({ template, updateTemplate }) {
  const [templateValues, setTemplateValues] = useState({
    name: "",
    description: ""
  });

  useEffect(() => {
    if (!template) return;
    setTemplateValues({
      name: template.name,
      description: template.description
    });
  }, [Boolean(template)]); // eslint-disable-line

  const handleFormValueChange = typeName => ({ target: { value } = {} }) => {
    const newTemplateValues = {
      ...templateValues,
      [typeName]: value
    };

    setTemplateValues(newTemplateValues);
    updateTemplate(newTemplateValues);
  };

  return (
    <div className="template-wizard-basic">
      <label>
        <div className="template-wizard-basic__section">
          <Headline small className="template-wizard-basic__headline">
            Template name
          </Headline>
          <div>
            <input
              className="ingrid-input ingrid-input--small"
              type="text"
              value={templateValues.name || ""}
              onChange={handleFormValueChange("name")}
            />
          </div>
        </div>
      </label>
      <label>
        <div className="template-wizard-basic__section">
          <Headline small className="template-wizard-basic__headline">
            Template description
          </Headline>

          <textarea
            className="ingrid-textarea ingrid-input--small"
            rows="4"
            onChange={handleFormValueChange("description")}
            value={templateValues.description || ""}
          >
            {templateValues.description}
          </textarea>
        </div>
      </label>
    </div>
  );
}
