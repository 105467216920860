import { useApolloClient, useMutation, useQuery } from "@apollo/react-hooks";
import { navigate } from "@reach/router";
import gql from "graphql-tag";
import React, { useCallback } from "react";
import Button from "../../components/ui/Button";
import debounce from "../../library/debounce";
import "./CampaignForm.css";
import wizard from "./Wizard";
import Basic from "./Wizard/Basic";
import Categories from "./Wizard/Categories";
import Notifications from "./Wizard/Notifications";
import Target from "./Wizard/Target";

export const CAMPAIGN_QUERY = gql`
  query CampaignDraft($id: ID!) {
    campaign(id: $id) {
      id
      name
      isApprovable
      description
      from
      to
      type
      target
      targetIds
      draft
      notifications {
        id
        title
        content
        sendAt
        target
        type
        draft
      }
      categories {
        id
        name
        main
        enabled
        type
        description
        ideaIds
        campaignId
      }
    }
  }
`;

const UPSERT_CAMPAIGN_MUTT = gql`
  mutation(
    $organizationId: ID!
    $id: ID
    $name: String
    $description: String
    $from: Date
    $to: Date
    $isApprovable: Boolean
    $target: campaign_target_types
    $targetIds: [ID]
  ) {
    upsertCampaign(
      id: $id
      organizationId: $organizationId
      type: ACTIVE
      name: $name
      description: $description
      from: $from
      to: $to
      isApprovable: $isApprovable
      target: $target
      targetIds: $targetIds
    ) {
      id
      name
      isApprovable
      description
      from
      to
      type
      target
      targetIds
      draft
      categories {
        id
        main
        name
        type
        description
        campaignId
        enabled
        ideaIds
      }
      notifications {
        id
        title
        content
        sendAt
        target
        type
        draft
      }
    }
  }
`;

const PUBLISH_CAMPAIGN_MUTT = gql`
  mutation($id: ID!) {
    publishCampaign(id: $id)
  }
`;

const SAVE_AS_TEMPLATE_MUTT = gql`
  mutation($id: ID!) {
    saveAsTemplate(id: $id)
  }
`;

export default function CampaignFormScreen({
  campaignId,
  organization,
  user,
  location
}) {
  const { data: { campaign } = {} } = useQuery(CAMPAIGN_QUERY, {
    variables: { id: campaignId },
    skip: !campaignId
  });

  const [mutateUpsertCampaign] = useMutation(UPSERT_CAMPAIGN_MUTT);
  const apollo = useApolloClient();

  const update = useCallback(
    updates => {
      const updatedCampaign = {
        id: Number(campaignId),
        ...campaign,
        ...updates
      };

      if (campaign.draft) {
        mutateUpsertCampaign({
          variables: {
            organizationId: organization?.id,
            id: campaignId,
            ...updates // upsert only changes, not entire campaign
          },
          optimisticResponse: {
            upsertCampaign: {
              ...updatedCampaign,
              __typename: "campaign"
            }
          }
        });
      } else {
        // Directly write data to local state,
        //  cuz we actually do not want to update non draft (published) campaign till admin clicks to save button
        apollo.writeQuery({
          query: CAMPAIGN_QUERY,
          variables: { id: campaignId },
          data: {
            campaign: updatedCampaign
          }
        });
      }
    },
    [mutateUpsertCampaign, organization, campaignId, campaign, apollo]
  );

  const debouncedUpdate = useCallback(debounce(update, 300), [update]);
  const passProps = {
    campaignId,
    campaign,
    organization,
    location,
    updateCampaign: debouncedUpdate
  };

  const [mutatePublishCampaign] = useMutation(PUBLISH_CAMPAIGN_MUTT);
  // eslint-disable-next-line
  const [mutateSaveAsTemplate] = useMutation(SAVE_AS_TEMPLATE_MUTT);

  const isCampaignReady = [Basic, Categories, Target, Notifications].reduce(
    (isReady, WizardComponent) => isReady && WizardComponent.validate(campaign),
    true
  );

  const handlePublish = async () => {
    const { errors } = await mutatePublishCampaign({
      variables: { id: campaignId },
      refetchQueries: ["CampaignsGrid", "RunningCampaigns", "ReadyCampaigns"]
    });
    if (!errors) navigate("/campaigns", { replace: true });
  };

  const handleSave = async () => {
    const { errors } = await mutateUpsertCampaign({
      variables: {
        organizationId: organization?.id,
        ...campaign
      },
      optimisticResponse: {
        upsertCampaign: {
          id: Number(campaignId),
          ...campaign,
          __typename: "campaign"
        }
      }
    });
    if (!errors) navigate("/campaigns", { replace: true });
  };

  return (
    <div className="campaign-form-screen">
      {wizard(Basic, { order: 1, title: "Basic setup", ...passProps })}
      {wizard(Target, { order: 2, title: "User targets", ...passProps })}
      {wizard(Categories, { order: 3, title: "Categories", ...passProps })}
      {wizard(Notifications, {
        order: 4,
        title: "Notifications",
        ...passProps
      })}
      <div className="campaign-form-screen__controls">
        {campaign?.draft ? (
          <Button onClick={handlePublish} disabled={!isCampaignReady}>
            PUBLISH CAMPAIGN
          </Button>
        ) : (
          <Button onClick={handleSave} disabled={!isCampaignReady}>
            SAVE CAMPAIGN
          </Button>
        )}
      </div>
    </div>
  );
}
