import React from "react";
import classNames from "classnames";
import "./AppLayout.css";

export const scrollElementId = "screen-scroll";

export default function AppLayout({ children: { header, sidebar, main } }) {
  return (
    <div className={classNames("app-layout", !sidebar && "app-layout--simple")}>
      <div
        className={classNames(
          "app-layout__header",
          !sidebar && "app-layout__header--simple"
        )}
      >
        {header}
      </div>

      {sidebar && <div className="app-layout__sidebar">{sidebar}</div>}

      <div
        id={scrollElementId}
        className={classNames(
          "app-layout__main",
          !sidebar && "app-layout__main--simple"
        )}
      >
        {main || "no main content"}
      </div>
    </div>
  );
}
