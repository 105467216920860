import React from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import * as format from "../../library/format";
import Headline from "../ui/Headline";

const getEngagementStats = (
  userEngagement,
  campaignFrom = false,
  campaignTo = false
) => {
  if (!userEngagement) return;
  let ret = [];
  let notifs = {};

  let today = new Date().toISOString().slice(0, 10);
  if (
    userEngagement.engagement.length &&
    userEngagement.engagement[userEngagement.engagement.length - 1].date !==
      today
  ) {
    let lastDataPoint = {
      date: today,
      count: 0
    };
    userEngagement.notifications.push(lastDataPoint);
    userEngagement.engagement.push(lastDataPoint);
  }

  for (let i in userEngagement.notifications) {
    notifs[userEngagement.notifications[i].date] = {
      count: 0,
      titles: userEngagement.notifications[i].titles
    };
  }

  for (let i in userEngagement.engagement) {
    let item = {
      date: userEngagement.engagement[i].date,
      usersActive: userEngagement.engagement[i].count,
      notificationSent:
        notifs[userEngagement.engagement[i].date]?.count || undefined,
      notificationTitle: notifs[
        userEngagement.engagement[i].date
      ]?.titles?.join(",")
    };
    ret.push(item);
  }
  if (ret.length === 0 && campaignFrom && campaignTo) {
    ret = [
      { date: format.onlyDate(campaignFrom), count: 0 },
      { date: today, count: 0 }
    ];
  }

  return ret;
};
export default function ChartUsersEngagement({
  userEngagement,
  height = 220,
  className = "organization-dashboard",
  campaignFrom,
  campaignTo
}) {
  const usersActivityData = getEngagementStats(
    userEngagement,
    campaignFrom,
    campaignTo
  );

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload) {
      return (
        <div
          className="recharts-default-tooltip"
          style={{
            padding: "10px",
            backgroundColor: "white",
            border: "1px #eee solid"
          }}
        >
          {" "}
          {label}
          <div>{`Active users: ${payload[0]?.value}`}</div>
          {payload[2]?.value ? (
            <div>{`Notification: ${payload[2]?.value}`}</div>
          ) : (
            ""
          )}
        </div>
      );
    }

    return null;
  };

  return (
    <div className={`${className}__graph-container`}>
      <Headline className="mb4" small>
        Users engagement
      </Headline>
      <div className={`${className}__graph`}>
        <ResponsiveContainer width="100%" height={height}>
          <AreaChart
            data={usersActivityData}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Area
              isAnimationActive={false}
              type="monotone"
              dataKey="usersActive"
              fill="var(--color-chart-1)"
            />
            <Area
              isAnimationActive={false}
              dataKey="notificationSent"
              stroke="var(--color-primary)"
              dot={{ strokeWidth: 5 }}
              fill="var(--color-primary)"
            />
            <Area
              isAnimationActive={false}
              dataKey="notificationTitle"
              stroke="var(--color-primary)"
              dot={{ strokeWidth: 3 }}
              fill="var(--color-primary)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
