import React from "react";
import classNames from "classnames";
import "./Text.css";

export default function Text({
  className,
  tiny,
  small,
  large,
  bold,
  italic,
  block,
  nowrap,
  color = "black",
  children: text,
  truncate = false,
  center = false
}) {
  return (
    <span
      className={classNames(
        tiny && "text--tiny",
        small && "text--small",
        large && "text--large",
        bold && "text--bold",
        block && "text--block",
        italic && "text--italic",
        truncate && "text--truncate",
        center && "text--center",
        nowrap && "text--nowrap",
        className
      )}
      style={{
        color: `var(--color-${color})`
      }}
    >
      {text}
    </span>
  );
}
