import { useQuery } from "@apollo/react-hooks";
import {
  createHistory,
  createMemorySource,
  navigate,
  Redirect,
  Router
} from "@reach/router";
import gql from "graphql-tag";
import * as queryString from "query-string";
import React, { useEffect, useMemo, useState } from "react";
import { useAlert } from "react-alert";
import { EnhancedApolloClientInstance } from "../library/apollo";
import CampaignDetailScreen from "../screens/CampaignDetail";
import CampaignsScreen from "../screens/Campaigns";
import CampaignCreateScreen from "../screens/CampaignCreate";
import CampaignFormScreen from "../screens/CampaignForm";
import TemplatesScreen from "../screens/Templates";
import TemplateFormScreen from "../screens/TemplateForm";
import TemplatesAssignScreen from "../screens/TemplatesAssign";
import CampaignReuseScreen from "../screens/CampaignReuse";
import IdeaCreateScreen from "../screens/IdeaCreate";
import OrganizationCreateScreen from "../screens/OrganizationCreate";
import PollCreate from "../screens/PollCreate";
import PollFormScreen from "../screens/PollForm";
import EmptyScreen from "../screens/Empty";
import IdeaDetailScreen from "../screens/IdeaDetail";
import PollDetailScreen from "../screens/PollDetail";
import IdeasScreen from "../screens/Ideas";
import IdeasExport from "../screens/IdeasExport";
import InnovationProcessScreen from "../screens/InnovationProcess";
import LoginScreen from "../screens/Login";
import NotFoundScreen from "../screens/NotFound";
import NotificationDetail from "../screens/NotificationDetail";
import Notifications from "../screens/Notifications";
import NotificationCreateScreen from "../screens/NotificationCreate";
import NotificationEditScreen from "../screens/NotificationEdit";
import OrganizationScreen from "../screens/Organization";
import OrganizationSettingsScreen from "../screens/OrganizationSettings";
import PasswordScreen from "../screens/Password";
import PasswordConfirmScreen from "../screens/PasswordConfirm";
import PollsScreen from "../screens/Polls";
import OrganizationRemoveScreen from "../screens/OrganizationRemove";
import SuperAdminScreen from "../screens/SuperAdmin";
import TeamForm from "../screens/TeamForm";
import TeamsScreen from "../screens/Teams";
import UsersScreen from "../screens/Users";
import UsersInviteScreen from "../screens/UsersInvite";
import UsersRemoveScreen from "../screens/UsersRemove";
import UserTeamFormScreen from "../screens/UserTeamForm";
// import "./Application.css";
import AppHeader from "./AppHeader";
import AppLayout from "./AppLayout";
import AppSidebar from "./AppSidebar";
import { Modal } from "./Modal";

export const SELECTED_ORG_QUERY = gql`
  query {
    selectedOrganization @client {
      id
      name
      currency
      pollsEnabled
      teamsEnabled
      innovationProcessEnabled
      activeCampaignsEnabled
      teams {
        id
        name
      }
    }
    superOrganization @client
  }
`;

export default function Application({
  apollo,
  location,
  auth
}: {
  apollo: EnhancedApolloClientInstance
}) {
  const [isLoggedIn, setIsLoggedIn] = useState(Boolean(auth.user));

  // User authorization

  useEffect(() => {
    if (location.pathname.startsWith("/logout")) {
      auth.logout();
    }
  }, [auth, location.pathname]);

  useEffect(() => {
    const unsubscribe = auth.subscribe(({ user }) => {
      setIsLoggedIn(Boolean(user));
    });

    return () => {
      unsubscribe();
    };
  }, [auth]);

  // Application's local state using apollo @client query

  const {
    data: { selectedOrganization, superOrganization } = {}
  } = useQuery(SELECTED_ORG_QUERY, { skip: !isLoggedIn });

  // This is superadmin feature when she needs to not be in any organization
  const organization = superOrganization ? null : selectedOrganization;

  // Modal screens navigator

  const modal = useMemo(() => {
    const { modal: modalPath, ...queryParams } = queryString.parse(
      location.search
    );
    const source = createMemorySource(modalPath);

    return {
      history: createHistory(source),
      path: modalPath,
      isModal: contextLocation => contextLocation.pathname === modalPath,
      location,
      close: () => {
        delete queryParams.context; // this is modal specific param
        const search = queryString.stringify({
          ...queryParams
        });

        navigate(search ? location.pathname + "?" + search : location.pathname);
      },
      closeWithParams: (returnParams = {}) => {
        const search = queryString.stringify({
          ...queryParams,
          ...returnParams
        });

        navigate(search ? location.pathname + "?" + search : location.pathname);
      }
    };
  }, [location]);

  // Alert notifications (error, ...)
  const alert = useAlert(null);
  useEffect(() => {
    const callback = messages => {
      const opts = { timeout: 0 };
      messages.forEach(msg => {
        alert.error(msg?.message || msg.toString(), opts);
      });
    };

    apollo.subscribeErrors(callback);
  }, []); // eslint-disable-line

  // Render routes to layout and pass common props to them

  const routeProps = {
    location,
    organization,
    auth,
    user: auth.user,
    modal
  };
  const route = (Route, { path, ...pass } = {}) =>
    (Array.isArray(path) ? path : [path]).map(_path => (
      <Route key={_path || "default"} path={_path} {...routeProps} {...pass} />
    ));

  return (
    <>
      <AppLayout>
        {{
          header: isLoggedIn && (
            <Router>
              {route(AppHeader, {
                title: "Organization",
                path: "/"
              })}
              {route(AppHeader, { title: "Superadmin", path: "/superadmin" })}
              {route(AppHeader, { title: "Users", path: "/users" })}
              {organization?.teamsEnabled &&
                route(AppHeader, { title: "Teams", path: "/users/teams" })}
              {route(AppHeader, {
                title: "Ideas",
                path: "/ideas",
                createPath: { modal: true, path: "/ideas/create" }
              })}
              {organization?.activeCampaignsEnabled &&
                route(AppHeader, {
                  title: "Campaigns",
                  path: "/campaigns",
                  createPath: { modal: false, path: "/campaigns/create" }
                })}
              {organization?.pollsEnabled &&
                route(AppHeader, {
                  title: "Polls",
                  path: "/polls",
                  createPath: { modal: false, path: "/polls/create" }
                })}
              {organization?.innovationProcessEnabled &&
                route(AppHeader, {
                  title: "Innovation Process",
                  path: "/inno"
                })}
              {route(AppHeader, {
                title: "Notifications",
                path: "/notifications"
              })}
              {organization?.activeCampaignsEnabled &&
                route(AppHeader, {
                  title: "Create campaign",
                  path: ["/campaigns/create", "/campaigns/create/:campaignId"]
                })}
              {organization?.pollsEnabled &&
                route(AppHeader, {
                  title: "Create poll",
                  path: ["/polls/create", "/polls/create/:campaignId"]
                })}
              {organization?.activeCampaignsEnabled &&
                route(AppHeader, {
                  title: "Campaign detail",
                  path: "/campaigns/:campaignId"
                })}
              {auth.user.superadmin &&
                superOrganization &&
                route(AppHeader, {
                  title: "Templates",
                  path: "/templates"
                })}
              {auth.user.superadmin &&
                superOrganization &&
                route(AppHeader, {
                  title: "Create template",
                  path: "/templates/:planId/:templateId"
                })}

              {route(AppHeader, { title: "...", default: true })}
            </Router>
          ),
          sidebar: isLoggedIn && route(AppSidebar, { superOrganization }),
          main: isLoggedIn ? (
            <Router primary>
              {route(IdeasScreen, { path: "/ideas" })}
              {route(OrganizationScreen, { path: "/" })}
              {organization?.activeCampaignsEnabled &&
                route(CampaignsScreen, { path: "/campaigns" })}
              {organization?.activeCampaignsEnabled &&
                route(CampaignDetailScreen, { path: "/campaigns/:campaignId" })}
              {organization?.activeCampaignsEnabled &&
                route(CampaignCreateScreen, {
                  path: "/campaigns/create"
                })}
              {organization?.activeCampaignsEnabled &&
                route(CampaignFormScreen, {
                  path: "/campaigns/create/:campaignId"
                })}
              {organization?.pollsEnabled &&
                route(PollsScreen, { path: "/polls" })}
              {organization?.pollsEnabled &&
                route(PollCreate, {
                  path: "/polls/create"
                })}
              {organization?.pollsEnabled &&
                route(PollFormScreen, {
                  path: "/polls/create/:campaignId"
                })}
              {organization?.innovationProcessEnabled &&
                route(InnovationProcessScreen, {
                  path: "/inno"
                })}
              {route(Notifications, {
                path: "/notifications"
              })}

              {route(IdeasExport, {
                path: "/export/pdf"
              })}
              {route(UsersScreen, { path: "/users" })}
              {organization?.teamsEnabled &&
                route(TeamsScreen, { path: "/users/teams" })}

              {auth.user.superadmin &&
                route(SuperAdminScreen, { path: "/superadmin" })}

              {auth.user.superadmin &&
                superOrganization &&
                route(TemplatesScreen, {
                  path: "/templates"
                })}
              {auth.user.superadmin &&
                superOrganization &&
                route(TemplateFormScreen, {
                  path: "/templates/:planId/:templateId"
                })}
              {route(NotFoundScreen, { default: true })}
              <Redirect from="/login" to="/" noThrow />
            </Router>
          ) : (
            <Router primary>
              {route(LoginScreen, { path: "/login" })}
              {route(PasswordScreen, { path: "/password" })}
              {route(PasswordConfirmScreen, {
                path: "/password-confirm/:code"
              })}
              {route(NotFoundScreen, { default: true })}

              <Redirect from="*" to="/login" noThrow />
            </Router>
          )
        }}
      </AppLayout>
      <Modal open={Boolean(modal.path)} onClose={modal.close}>
        {isLoggedIn && (
          <Router location={modal.history.location}>
            {auth.user.superadmin &&
              route(OrganizationCreateScreen, {
                path: "/organization/create"
              })}
            {auth.user.superadmin &&
              route(OrganizationRemoveScreen, {
                path: "/organization/remove"
              })}

            {route(OrganizationSettingsScreen, {
              path: "/organization/settings"
            })}

            {route(UsersInviteScreen, { path: "/users/invite" })}
            {route(UsersRemoveScreen, { path: "/users/remove" })}
            {route(UserTeamFormScreen, { path: "/userteam/:userId/:teamId" })}
            {route(IdeasScreen, { path: "/ideas" })}
            {route(IdeaDetailScreen, { path: "/ideas/:ideaId" })}
            {organization?.activeCampaignsEnabled &&
              route(IdeaDetailScreen, {
                path: "/campaigns/:campaignId/ideas/:ideaId"
              })}
            {route(PollDetailScreen, { path: "/polls/:campaignId" })}
            {route(EmptyScreen, { path: "/app/graph" })}
            {route(IdeaCreateScreen, { path: "/ideas/create" })}
            {organization?.teamsEnabled &&
              route(TeamForm, { path: "/users/teams/create" })}
            {route(NotificationCreateScreen, { path: "/notifications/create" })}
            {route(NotificationEditScreen, {
              path: "/notifications/edit/:notificationId"
            })}
            {route(NotificationDetail, {
              path: "/notifications/:notificationId"
            })}
            {organization?.teamsEnabled &&
              route(TeamForm, { path: "/users/teams/:teamId/edit" })}

            {auth.user.superadmin &&
              route(TemplatesAssignScreen, {
                path: "/templates/assign"
              })}

            {organization?.activeCampaignsEnabled &&
              route(CampaignReuseScreen, {
                path: "/campaigns/reuse"
              })}

            {route(NotFoundScreen, { default: true })}
          </Router>
        )}
      </Modal>
    </>
  );
}
