import { useQuery } from "@apollo/react-hooks";
import { navigate } from "@reach/router";
import gql from "graphql-tag";
import React from "react";
import IngridCampaign from "../../components/ingrid/Campaign";
import { createLinkToModal } from "../../components/Modal";
import Button from "../../components/ui/Button";
import LoadingSquares from "../../components/ui/LoadingSquares";
import useCreateCampaign from "../../hooks/useCreateCampaign";
import "./CampaignCreate.css";

const DRAFTS_QUERY = gql`
  query CampaignDrafts($organizationId: ID) {
    campaigns(
      organizationId: $organizationId
      order: "reverse:created_at"
      state: DRAFT
      where: { type: "ACTIVE" }
    ) {
      items {
        id
        name
        from
        to
        target
        state
        categories {
          id
          name
          type
          ideaCount
        }
        teams {
          id
          name
        }
      }
    }
  }
`;

const TEMPLATES_QUERY = gql`
  query CampaignTemplates($organizationId: ID) {
    campaigns(
      organizationId: $organizationId
      # order: "position"
      where: { type: "ACTIVE", isTemplate: true }
    ) {
      items {
        id
        name
        from
        to
        target
        state
        isTemplate
        categories {
          id
          name
          type
          ideaCount
        }
        teams {
          id
          name
        }
      }
    }
  }
`;

export default function CampaignCreateScreen({ location, organization, user }) {
  const {
    loading: draftsLoading,
    data: { campaigns: { items: drafts = [] } = {} } = {}
  } = useQuery(DRAFTS_QUERY, {
    variables: { organizationId: organization?.id },
    skip: !organization,
    fetchPolicy: "cache-and-network"
  });

  const {
    loading: templatesLoading,
    data: { campaigns: { items: templates = [] } = {} } = {}
  } = useQuery(TEMPLATES_QUERY, {
    variables: { organizationId: organization?.id },
    skip: !organization,
    fetchPolicy: "cache-and-network"
  });

  const createCampaign = useCreateCampaign(
    { organization, type: "ACTIVE" },
    campaignId => {
      if (campaignId) navigate(`/campaigns/create/${campaignId}`);
    }
  );

  return (
    <div className="campaign-create-screen">
      <h2>Create new</h2>
      <Button onClick={createCampaign}>Create new campaign</Button>

      <Button
        secondary
        className="ml8"
        onClick={() => {
          navigate(createLinkToModal(location)(`/campaigns/reuse`));
        }}
      >
        Reuse campaign
      </Button>
      {user.superadmin && (
        <Button
          secondary
          className="ml8"
          onClick={() => {
            navigate(
              createLinkToModal(location)(`/templates/assign`, {
                organization: organization.id
              })
            );
          }}
        >
          Assign plan
        </Button>
      )}
      <h2>Templates</h2>
      <div className="campaign-create-screen__grid">
        {templates.slice(0, 6).map(template => (
          <IngridCampaign key={template.id} campaign={template} />
        ))}
        {templatesLoading && <LoadingSquares count={5} height="184px" />}
        {!templatesLoading && !templates.length && <p>No templates yet.</p>}
      </div>
      <h2>Drafts ({drafts.length})</h2>
      <div className="campaign-create-screen__grid">
        {drafts.map(draft => (
          <IngridCampaign key={draft.id} campaign={draft} />
        ))}
        {draftsLoading && <LoadingSquares count={7} height="218px" />}
        {!draftsLoading && !drafts.length && <p>No drafts yet.</p>}
      </div>
    </div>
  );
}
