import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useState } from "react";
import Button from "../../components/ui/Button";
import Text from "../../components/ui/Text";
import "./OrganizationCreate.css";

const ORGANIZATIONS_QUERY = gql`
  query {
    organizations {
      id
      name
      currency
      timezone
      teamsEnabled
      innovationProcessEnabled
      activeCampaignsEnabled
      pollsEnabled
      voteOutsideTeamEnabled
      sendInvitationEmailsEnabled
    }
  }
`;

const CREATE_ORG_MUTATION = gql`
  mutation($name: String!) {
    createOrganization(name: $name) {
      id
      name
    }
  }
`;

const SELECT_ORQ_MUT = gql`
  mutation($id: ID) {
    selectOrganization(id: $id) @client
  }
`;

export default function OrganizationCreateScreen() {
  const [formValues, setFormValues] = useState({ name: "" });
  const [selectOrganization] = useMutation(SELECT_ORQ_MUT);
  const [mutate, { error }] = useMutation(CREATE_ORG_MUTATION, {
    // mby move to library/resolvers
    update(cache, { data }) {
      const { organizations } = cache.readQuery({
        query: ORGANIZATIONS_QUERY
      });

      cache.writeQuery({
        query: ORGANIZATIONS_QUERY,
        data: {
          organizations: organizations.concat(data.createOrganization)
        }
      });
    },
    onCompleted: async data => {
      await selectOrganization({
        variables: { id: String(data.createOrganization.id) }
      });

      window.location.href = `/?modal=/organization/settings&orgId=${data.createOrganization.id}`;
    },
    refetchQueries: ["SAOrganizationList"]
  });
  const handleFormFiledChange = fieldName => ({ target: { value } }) => {
    setFormValues({ ...formValues, [fieldName]: value });
  };
  const handleSubmit = async event => {
    event.preventDefault();
    try {
      await mutate({ variables: formValues });
    } catch (error) {}
  };

  return (
    <div className="org-create-screen">
      <h2>
        <Text large>Create organization</Text>
      </h2>

      <form onSubmit={handleSubmit} className="org-create-screen__form">
        <input
          type="text"
          className="ingrid-input"
          value={formValues.name}
          onChange={handleFormFiledChange("name")}
          placeholder="Organization name"
        />
        <div>
          {error && (
            <div className="org-remove-screen__form">
              <Text color="error" center>
                {error.toString()}
              </Text>
            </div>
          )}

          <Button fullwidth type="submit" disabled={!formValues.name}>
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
}
