import { Link, navigate } from "@reach/router";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import Logo from "../../assets/ingrid-logo.svg";
import Button from "../../components/ui/Button";
import Headline from "../../components/ui/Headline";
import Text from "../../components/ui/Text";
import "./PasswordConfirm.css";
export default function PasswordConfirmScreen({ auth, code }) {
  const [formValues, setFormValues] = useState({ password: "", code: code });
  const [error, setError] = useState(auth.error);
  const alert = useAlert();
  const handleFormValueChange = valueType => ({ target: { value } }) => {
    setFormValues({
      ...formValues,
      [valueType]: value
    });
  };

  useEffect(
    () =>
      auth.subscribe(({ error: _error }) => {
        setError(_error);
      }),
    [auth]
  );

  const handleSubmit = event => {
    if (event) event.preventDefault();
    const { password, password2, code } = formValues;
    if (password.length < 6) {
      alert.show("Password must have at least 6 chars", { type: "error" });
      return;
    }

    if (password !== password2) {
      alert.show("Passwords must match", { type: "error" });
      return;
    }

    if (auth.confirmPasswordReset({ password, code }, alert)) {
      navigate("/login");
    }
  };

  return (
    <div className="login-screen">
      <Logo className="login-screen__logo" width="64px" />
      <div className="login-screen__form">
        <Headline className="login-screen__headline">
          Confirm your new password
        </Headline>
        <form onSubmit={handleSubmit}>
          <label>
            <Text color="gray-dark">New password</Text>
            <input
              type="password"
              name="password"
              value={formValues.password}
              onChange={handleFormValueChange("password")}
              className="ingrid-input"
            />
          </label>
          <label>
            <Text color="gray-dark">Repeat password</Text>
            <input
              type="password"
              name="password2"
              value={formValues.password2}
              onChange={handleFormValueChange("password2")}
              className="ingrid-input"
            />
          </label>
          <div className="login-screen__link">
            <Link to="/login">Login</Link>
          </div>
          <Button className="login-screen__submit" fullwidth type="submit">
            Confirm new password
          </Button>
          {error && <div className="login-screen__error">{error}</div>}
        </form>
      </div>
    </div>
  );
}
