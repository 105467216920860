import cs from "classnames";
import React, { useState } from "react";
import IconTeam from "../../assets/icons/icon-team-menu.svg";
import IconUser from "../../assets/icons/icon-user.svg";
import Text from "../ui/Text";
import "./Leaderboard.css";

export default function Leaderboard({ campaign, organization }) {
  const [leaderboardType, setLeaderboardType] = useState("users");
  return (
    <div className="ingrid-leaderboard__container full-height">
      <div className="ingrid-leaderboard__header">
        <div className="current-campaigns__buttons">
          <div className="ingrid-leaderboard__tab">
            <input
              className="ingrid-leaderboard__tab__input"
              type="radio"
              name="selectLeaderboard"
              id="users"
              value="users"
              checked={leaderboardType === "users"}
              onChange={() => setLeaderboardType("users")}
            />
            <label className="ingrid-leaderboard__tab__label" htmlFor={"users"}>
              <div className="ingrid-leaderboard__tab__button">
                <IconUser height="16px" className="mr8" />
                <Text>Top users</Text>
              </div>
            </label>
          </div>
          {organization?.teamsEnabled && (
            <div className="ingrid-leaderboard__tab">
              <input
                className="ingrid-leaderboard__tab__input"
                type="radio"
                name="selectLeaderboard"
                id="teams"
                value="teams"
                checked={leaderboardType === "teams"}
                onChange={() => setLeaderboardType("teams")}
              />
              <label
                className="ingrid-leaderboard__tab__label"
                htmlFor={"teams"}
              >
                <div className="ingrid-leaderboard__tab__button">
                  <IconTeam height="24px" className="mr8" />
                  Top teams
                </div>
              </label>
            </div>
          )}
        </div>
      </div>
      {leaderboardType === "users" && (
        <ul className="ingrid-leaderboard__users">
          {campaign?.users.map(user => (
            <li key={user.id}>
              <div className={cs("ingrid-leaderboard__user-row")}>
                <div className="ingrid-leaderboard__user-email">
                  <Text truncate>{user.email}</Text>
                </div>
                <div
                  className={cs(
                    "ingrid-leaderboard__user-order",
                    `ingrid-leaderboard__user-order--${user.rank}`
                  )}
                >
                  <Text tiny nowrap>
                    {user.rank}.
                  </Text>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
      {leaderboardType === "users" && !campaign?.users.length && (
        <div className="center-flex full-height">
          <p>No leaders yet.</p>
        </div>
      )}
      {leaderboardType === "teams" && (
        <ul className="ingrid-leaderboard__users">
          {campaign?.teams.map(team => (
            <li key={team.id}>
              <div className={cs("ingrid-leaderboard__user-row")}>
                <div className="ingrid-leaderboard__user-email">
                  {team.name}
                </div>
                <div
                  className={cs(
                    "ingrid-leaderboard__user-order",
                    `ingrid-leaderboard__user-order--${team.rank}`
                  )}
                >
                  {team.rank}.
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
      {leaderboardType === "teams" && !campaign?.teams.length && (
        <div className="center-flex full-height">
          <p>No leaders yet.</p>
        </div>
      )}
    </div>
  );
}
