import classNames from "classnames";
import * as queryString from "query-string";
import React, { useEffect } from "react";
import IconClose from "../assets/icons/icon-cross.svg";
// import PropTypes from "prop-types";
import "./Modal.css";

export const createLinkToModal = location => (fullPath, params) => {
  const [path, pathSearch] = fullPath.split("?");
  const queryParams = queryString.parse(location.search);
  const pathParams = params || queryString.parse(pathSearch);
  // TODO: somehow clean modal URL params on close
  const search = queryString.stringify(
    {
      ...queryParams,
      ...pathParams,
      modal: path
    },
    { encode: false }
  );

  return (location.pathname || "") + "?" + search;
};

export function Modal({ children, open, onClose, overflowHidden = true }) {
  // Handle esc keypress
  useEffect(() => {
    const handler = ({ key }) => {
      if (key === "Escape") onClose();
    };

    if (open) {
      window.addEventListener("keyup", handler);
    } else {
      window.removeEventListener("keyup", handler);
    }
  }, [onClose, open]);

  return (
    <div className={classNames("modal", open && "modal--open")}>
      <div className="modal__overlay" onClick={onClose} />
      <div className="modal__content-outside">
        <div className="modal__close" onClick={onClose}>
          <IconClose width="16px" />
        </div>
        <div className="modal__content">{children}</div>
      </div>
    </div>
  );
}
