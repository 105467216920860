/*
 *
 * source: https://github.com/MrCheater/parse-jwt-light/blob/master/index.js
 */

export default function parseToken(jwt) {
  try {
    return JSON.parse(atob(decodeURIComponent(jwt).split(".")[1]));
  } catch (e) {
    return {};
  }
}
