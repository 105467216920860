import React, { useState } from "react";
import useUpdateCampaignIdeas from "../../hooks/useUpdateCampaignIdeas";
import "./IdeaCampaigns.css";
import Text from "../../components/ui/Text";
import Headline from "../../components/ui/Headline";
import Dropdown from "../../components/ui/Dropdown";
import Button from "../../components/ui/Button";
import IconUnranked from "../../assets/icons/icon-unranked.svg";
import IconInvisible from "../../assets/icons/icon-invisible.svg";
import IconRanked from "../../assets/icons/icon-ranked.svg";
import IconLike from "../../assets/icons/icon-like.svg";
import IconDislike from "../../assets/icons/icon-cross.svg";
import IconVisible from "../../assets/icons/icon-visible.svg";
import IconPromo from "../../assets/icons/icon-idea-example.svg";
import IconDesc from "../../assets/icons/icon-description.svg";
import IconMenu from "../../assets/icons/icon-kebab-big.svg";
import CampaignBadgeInno from "../../assets/icons/campaign-badges/badge-category-inno.svg";
import CampaignBadgeFun from "../../assets/icons/campaign-badges/badge-category-fun.svg";
import CampaignState from "../../components/ingrid/CampaignState";
import BadgePoll from "../../assets/icons/campaign-badges/badge-poll.svg";

export default function IdeaCampaigns({
  campaignIdeas,
  ideaId,
  ideaDescription
}) {
  const updateCampaignIdeas = useUpdateCampaignIdeas({}, result => {});

  const [editableDesc, setEditableDesc] = useState(false);
  const handleEditableDesc = value => {
    setEditableDesc(value);
  };
  const [newDesc, setNewDesc] = useState("");

  const handleSubmitValue = campaignId => event => {
    if (event) event.preventDefault();
    updateCampaignIdeas([{ id: ideaId, description: newDesc }], { campaignId });
    setEditableDesc(false);
  };

  return (
    <div>
      <Headline small className="mb8">
        Campaigns
      </Headline>
      {campaignIdeas?.map(campaignIdea => (
        <div className="idea-campaign mb8" key={campaignIdea.id}>
          <div className="idea-campaign__name">
            <div className="idea-campaign__left">
              {campaignIdea.category.type === "INNO" ? (
                campaignIdea.campaign.type === "ACTIVE" ? (
                  <CampaignBadgeInno className="mr8" width="20px" />
                ) : (
                  <BadgePoll className="idea__badge mr8" width="20px" />
                )
              ) : (
                <CampaignBadgeFun className="mr8" width="20px" />
              )}

              <Text small bold>
                {campaignIdea.campaign?.name}
              </Text>
              <div className="ml8">
                <CampaignState
                  state={campaignIdea.campaign?.state
                    .toLowerCase()
                    .replace(/_/g, "-")}
                />
              </div>
              <Text small bold block className="idea-campaign__order">
                {!campaignIdea.order ? "---" : campaignIdea.order + "."}
              </Text>
              <IconLike
                width="12px"
                fill="var(--color-black)"
                className="mr8"
              />
              <Text small bold block className="mr16">
                {campaignIdea.reactions?.likes}
              </Text>
              <IconDislike
                width="12px"
                fill="var(--color-black)"
                className="mr8"
              />
              <Text small bold block className="mr16">
                {campaignIdea.reactions?.dislikes}
              </Text>
            </div>

            <div className="idea-campaign__buttons-container">
              {campaignIdea.isPromo && (
                <div
                  className="idea-campaign__settings-icon"
                  data-title="Idea is set as an example in this campaign."
                >
                  <IconPromo width="16px" fill="var(--color-secondary)" />
                </div>
              )}

              {campaignIdea.isHidden && (
                <div
                  className="idea-campaign__settings-icon"
                  data-title="Idea hidden from users in this campaign."
                >
                  <IconInvisible width="16px" fill="var(--color-secondary)" />
                </div>
              )}

              {campaignIdea.isExcludedFromStatistics && (
                <div
                  className="idea-campaign__settings-icon"
                  data-title="Idea is excluded from rankings and is not able to win in this campaign."
                >
                  <IconUnranked width="16px" fill="var(--color-secondary)" />
                </div>
              )}

              <div className="idea-campaign__dropdown">
                <div className="idea-campaign__dropdown__text">
                  <IconMenu width="24px" />
                </div>
                <Dropdown
                  style={{ backgroundColor: "white" }}
                  left
                  actions={[
                    {
                      Icon: !campaignIdea.isPromo ? IconPromo : IconPromo,
                      label: !campaignIdea.isPromo
                        ? "Set to example"
                        : "Remove example tag",
                      handler: () => {
                        updateCampaignIdeas(
                          [
                            {
                              id: ideaId,
                              isPromo: !campaignIdea.isPromo
                            }
                          ],
                          { campaignId: campaignIdea.campaign.id }
                        );
                      }
                    },
                    {
                      Icon: !campaignIdea.isHidden
                        ? IconInvisible
                        : IconVisible,
                      label: !campaignIdea.isHidden
                        ? "Hide idea to users"
                        : "Make visible to users",
                      handler: () => {
                        updateCampaignIdeas(
                          [
                            {
                              id: ideaId,
                              isHidden: !campaignIdea.isHidden
                            }
                          ],
                          { campaignId: campaignIdea.campaign.id }
                        );
                      }
                    },
                    {
                      Icon: !campaignIdea.isExcludedFromStatistics
                        ? IconUnranked
                        : IconRanked,
                      label: !campaignIdea.isExcludedFromStatistics
                        ? "Set to unranked"
                        : "Set to ranked",
                      handler: () => {
                        updateCampaignIdeas(
                          [
                            {
                              id: ideaId,
                              isExcludedFromStatistics: !campaignIdea.isExcludedFromStatistics
                            }
                          ],
                          { campaignId: campaignIdea.campaign.id }
                        );
                      }
                    },
                    {
                      Icon: IconDesc,
                      label: "Change description",
                      handler: () =>
                        handleEditableDesc(campaignIdea?.campaign?.id)
                    }
                  ]}
                />
              </div>
            </div>
          </div>
          {!editableDesc && campaignIdea.description !== ideaDescription && (
            <div
              className="idea-campaign__description"
              data-title-right="Custom idea description only visible in this campaign."
            >
              {campaignIdea?.description?.length > 0 && (
                <div className="idea-campaign__description-text">
                  {campaignIdea.description}
                </div>
              )}
            </div>
          )}
          <div>
            {editableDesc === campaignIdea?.campaign?.id && (
              <form onSubmit={handleSubmitValue}>
                <div className="edit-description__input-container">
                  <textarea
                    autoFocus
                    defaultValue={campaignIdea.description}
                    onChange={({ target: { value } }) => setNewDesc(value)}
                    className="ingrid-textarea"
                  />
                </div>
                <div className="edit-description__buttons">
                  <Button
                    small
                    className="mr4"
                    onClick={handleSubmitValue(campaignIdea.campaign.id)}
                    type="submit"
                  >
                    Save
                  </Button>
                  <Button
                    small
                    secondary
                    onClick={() => setEditableDesc(false)}
                    type="button"
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            )}
          </div>
          {campaignIdea.team && (
            <div>
              <strong>Team:</strong> {campaignIdea.team.name}
            </div>
          )}
        </div>
      ))}
      {campaignIdeas.length === 0 && (
        <div>This idea is not in any campaign.</div>
      )}
    </div>
  );
}
