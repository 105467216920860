/*
 * @flow
 */

type EmailToNameOptions = {
  uppercase: boolean
};

export function emailToName(
  email: string,
  options: EmailToNameOptions = {}
): [string, string] {
  let name = (email || "").split("@")[0].replace(/\.|_|\+/gi, " ");

  if (options.uppercase) {
    name = name.toUpperCase();
  }

  return name
    .split(" ")
    .map(capitalizeFirstLetter)
    .join(" ");
}

type EmailToInitialsOptions = {
  max: number
};

export function emailToInitials(
  email: string,
  options: EmailToInitialsOptions = {}
): [string, string] {
  const name = emailToName(email);
  const initials = name.split(" ").map(str => str.charAt(0).toUpperCase());

  if (initials.length === 1) {
    initials.push(name.charAt(1));
  }

  if (options.max > 0) {
    return initials.slice(0, options.max);
  }

  return initials;
}

export const capitalizeFirstLetter = (string = "") =>
  string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
