import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import timezonesList from "tz-ids";
import Button from "../../components/ui/Button";
import Checkbox from "../../components/ui/Checkbox";
import Headline from "../../components/ui/Headline";
import Select from "../../components/ui/Select";
import Text from "../../components/ui/Text";
import useUrlParams from "../../hooks/useUrlParams";
import "./OrganizationSettings.css";

const currencyTypesList = ["CZK", "EUR", "USD"];

const ORGANIZATION_QUERY = gql`
  query OrgSettings($orgId: ID!) {
    organization(id: $orgId) {
      id
      name
      currency
      timezone
      created_by {
        id
        email
      }
      pollsEnabled
      teamsEnabled
      innovationProcessEnabled
      activeCampaignsEnabled
      voteOutsideTeamEnabled
      sendInvitationEmailsEnabled
    }
  }
`;

const UPDATE_ORGANIZATION_MUT = gql`
  mutation(
    $orgId: ID!
    $name: String
    $currency: organization_currency
    $timezone: String
    $pollsEnabled: Boolean
    $teamsEnabled: Boolean
    $innovationProcessEnabled: Boolean
    $activeCampaignsEnabled: Boolean
    $voteOutsideTeamEnabled: Boolean
    $sendInvitationEmailsEnabled: Boolean
  ) {
    updateOrganization(
      id: $orgId
      name: $name
      currency: $currency
      timezone: $timezone
      pollsEnabled: $pollsEnabled
      teamsEnabled: $teamsEnabled
      innovationProcessEnabled: $innovationProcessEnabled
      activeCampaignsEnabled: $activeCampaignsEnabled
      voteOutsideTeamEnabled: $voteOutsideTeamEnabled
      sendInvitationEmailsEnabled: $sendInvitationEmailsEnabled
    ) {
      id
      name
      currency
      timezone
      created_by {
        id
        email
      }

      pollsEnabled
      teamsEnabled
      innovationProcessEnabled
      activeCampaignsEnabled
      voteOutsideTeamEnabled
      sendInvitationEmailsEnabled
    }
  }
`;

export default function OrganizationSettingsScreen({
  organization,
  user,
  modal
}) {
  const [formFields, setFormFields] = useState({
    name: "",
    timezone: "",
    currency: "",
    pollsEnabled: false,
    teamsEnabled: false,
    innovationProcessEnabled: false,
    activeCampaignsEnabled: false,
    voteOutsideTeamEnabled: false,
    sendInvitationEmailsEnabled: false
  });
  const alert = useAlert();
  const [urlParams] = useUrlParams(modal.location);

  const id = urlParams?.orgId ? parseInt(urlParams?.orgId) : organization?.id;
  const { loading, error, data } = useQuery(ORGANIZATION_QUERY, {
    variables: { orgId: id },
    onCompleted: ({ organization }) => {
      const fullifiedFields = Object.keys(formFields).reduce(
        (fields, typeName) => ({
          [typeName]: organization[typeName],
          ...fields
        }),
        {}
      );
      setFormFields(fullifiedFields);
    }
  });

  const [updateOrganization, { error: err }] = useMutation(
    UPDATE_ORGANIZATION_MUT
  );
  // TODO: Handle error on organization update
  if (err) console.warn("todo error handler", err);

  const enableInputs = !loading && !error;
  const superadmin = user?.superadmin;

  const handleFieldChange = typeName => arg => {
    const value = arg?.target?.value || arg?.value || arg;
    setFormFields({ ...formFields, [typeName]: value });
  };

  const handleSubmit = event => {
    event.preventDefault();
    const confirmed = window.confirm("You sure?");

    if (confirmed) {
      updateOrganization({
        variables: {
          orgId: organization?.id,
          ...formFields
        }
      });
      modal.close();
      alert.show("Organization settings updated successfully", {
        type: "success"
      });
    }
  };

  const input = typeName => (
    <input
      disabled={!enableInputs}
      type="text"
      name={typeName}
      value={formFields[typeName]}
      onChange={handleFieldChange(typeName)}
      placeholder={data?.organization?.[typeName]}
      className="ingrid-input ingrid-input--small"
    />
  );

  const checkbox = (typeName, label) => (
    <Checkbox
      name={typeName}
      value={formFields[typeName]}
      onChange={({ value }) => {
        setFormFields({
          ...formFields,
          [typeName]: value
        });
      }}
    >
      {label}
    </Checkbox>
  );
  return (
    <div className="org-settings">
      <Headline>{organization?.name} Settings</Headline>
      <form onSubmit={handleSubmit}>
        {superadmin && (
          <div className="org-settings__section">
            <label>
              <Text block className="mb4">
                Name
              </Text>
              {input("name")}
            </label>
          </div>
        )}
        <div className="org-settings__section">
          <label>
            <Text block className="mb4">
              Timezone
            </Text>
            <Select
              clearable={false}
              searchable={true}
              value={formFields["timezone"]}
              onChange={handleFieldChange("timezone")}
              options={timezonesList.map(value => ({
                label: value,
                value
              }))}
              placeholder={data?.organization?.timezone}
            />
          </label>
        </div>
        <div className="org-settings__section">
          <label>
            <Text block className="mb4">
              Currency
            </Text>
            <Select
              clearable={false}
              searchable={false}
              value={formFields["currency"]}
              onChange={handleFieldChange("currency")}
              options={currencyTypesList.map(value => ({
                label: value,
                value
              }))}
              placeholder={data?.organization?.currency}
            />
          </label>
        </div>

        <Headline className="mt16">Settings</Headline>
        <div className="org-settings__section">
          {superadmin && checkbox("teamsEnabled", "Teams enabled")}
          {superadmin &&
            checkbox("innovationProcessEnabled", "Innovation process enabled")}
          {superadmin && checkbox("pollsEnabled", "Polls enabled")}

          {superadmin &&
            checkbox("activeCampaignsEnabled", "Active campaings enabled")}
          {checkbox("voteOutsideTeamEnabled", "Vote outside team enabled")}
          {checkbox(
            "sendInvitationEmailsEnabled",
            "Send invitation emails enabled"
          )}
        </div>
        <Button className="mt16" fullwidth type="submit">
          Save
        </Button>
      </form>
    </div>
  );
}
