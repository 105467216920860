import { useMutation } from "@apollo/react-hooks";
import { navigate } from "@reach/router";
import cs from "classnames";
import gql from "graphql-tag";
import React from "react";
import * as format from "../../library/format";
import Card from "../ui/Card";
import "./Organization.css";

const SELECT_ORQ_MUT = gql`
  mutation($id: ID) {
    selectOrganization(id: $id) @client
  }
`;

export default function Organization({ organization, className, highlight }) {
  const [selectOrganization] = useMutation(SELECT_ORQ_MUT);

  const selectOrganizationScreen = async () => {
    await selectOrganization({
      variables: { id: String(organization?.id) }
    });
    navigate("/");
  };

  const selectUsers = async () => {
    await selectOrganization({
      variables: { id: String(organization?.id) }
    });
    navigate("/users");
  };

  return (
    <div className="organization">
      <Card
        className={cs(
          "organization__content",
          className,
          highlight && "organization--highlight"
        )}
        fullwidth
      >
        <div className="organization__row" onClick={selectOrganizationScreen}>
          <strong>{organization.name}</strong>
        </div>
        <div className="organization__row" onClick={selectUsers}>
          Users: {organization.usersOverview.count | 0}
        </div>
        <div className="organization__row">
          Created at: {format.date(organization.created_at)}
        </div>
      </Card>
    </div>
  );
}
