import { EventEmitter } from "fbemitter";
import parseToken from "./token";

const __change_event = "change";
const __error_event = "error";
const __token_storage_key = "ingrid-auth-token";

type UserType = {
  email: String,
  superadmin: Boolean
};

export type AuthInstance = {
  token: String,
  user: UserType,
  subscribe: Function,
  login: Function,
  register: Function,
  logout: Function,
  requestPasswordReset: Function,
  confirmPasswordReset: Function,
  error: String
};

export default function createAuthInstance({ axios }): AuthInstance {
  const emitter = new EventEmitter();

  let user = null;
  let token = localStorage.getItem(__token_storage_key);
  let error = null;

  if (token) {
    user = parseToken(token);
  }

  emitter.addListener(__change_event, (_user, _token) => {
    user = _user;

    if (user) {
      error = null;
    }

    if (_token || _token === null) {
      token = _token;
      token
        ? localStorage.setItem(__token_storage_key, token)
        : localStorage.removeItem(__token_storage_key);
    }
  });

  emitter.addListener(__error_event, _error => {
    error = _error;
    emitter.emit(__change_event, null, null);
  });

  return {
    subscribe(callback: Function): Function {
      const handler = () => {
        callback({ user, error });
      };

      const subscriptionChange = emitter.addListener(__change_event, handler);
      const subscriptionError = emitter.addListener(__error_event, handler);

      return () => {
        subscriptionChange.remove();
        subscriptionError.remove();
      };
    },

    set token(_token) {
      localStorage.setItem(__token_storage_key, _token);
      token = _token;
    },

    get token() {
      return token;
    },

    get user() {
      return user;
    },

    get error() {
      return error;
    },

    async login({ email, password }) {
      try {
        const {
          data: { user: _user, error: _error, access_token }
        } = await axios.post("/api/authentication/login", {
          email,
          password
        });

        if (_error) {
          emitter.emit(__error_event, _error);
        } else {
          emitter.emit(__change_event, _user, access_token);
          return _user;
        }
      } catch (o_O) {
        console.log("auth.login error", o_O);
        return null;
      }
    },

    async register({ email, password }) {
      throw new Error("Not yet implemented");
    },

    async logout() {
      emitter.emit(__change_event, null, null);
    },

    async requestPasswordReset({ email }, alert) {
      try {
        const response = await axios.post(
          "/api/authentication/password-reset",
          {
            email
          }
        );

        if (response.error) {
          alert.show("Error " + response.error, { type: "error" });
        } else if (response.status !== 204) {
          alert.show(`Response not created ${response.status}`, {
            type: "error"
          });
        } else {
          alert.show(
            `Email with instructions has been sent to ${email}, check your email`,
            {
              type: "success"
            }
          );
          return null;
        }
      } catch (exception) {
        alert.show("Exception " + exception, { type: "error" });
        return null;
      }
    },

    async confirmPasswordReset({ code, password }, alert) {
      try {
        const response = await axios.post(
          `/api/authentication/password-reset/${code}`,
          {
            password
          }
        );

        if (response.status === 404) {
          alert.show("This request link expired", { type: "error" });
        } else if (response.status === 400) {
          alert.show("Wrong confirmation request code", { type: "error" });
        } else if (response.error) {
          alert.show("Error " + response.error, { type: "error" });
        } else if (response.status !== 204) {
          alert.show(`Response not created ${response.status}`, {
            type: "error"
          });
        } else {
          alert.show(`Password changed sucessfully, please login`, {
            type: "success"
          });
          return true;
        }
      } catch (exception) {
        alert.show("Exception " + exception, { type: "error" });
        return null;
      }
    }
  };
}
