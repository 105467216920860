import { Link } from "@reach/router";
import React from "react";
import IconIdeas from "../../assets/icons/icon-idea.svg";
import IconInnoApproved from "../../assets/icons/icon-inno-approved.svg";
import IconInnoDone from "../../assets/icons/icon-inno-done.svg";
import IconInnoImplementation from "../../assets/icons/icon-inno-implementing.svg";
import IconInnoSelected from "../../assets/icons/icon-inno-selected.svg";
import IconReplication from "../../assets/icons/icon-replication.svg";
import Headline from "../../components/ui/Headline";
import Text from "../../components/ui/Text";

const getIdeaData = innovationProcess => {
  let defaults = {
    count: {
      uploaded: 0,
      selected: 0,
      approved: 0,
      implementing: 0,
      done: 0,
      replicated: 0
    }
  };

  for (let i in innovationProcess) {
    let key = innovationProcess[i].state.toLowerCase();
    if (key in defaults.count) {
      defaults.count[key] = innovationProcess[i].count;
    }
  }
  return defaults;
};

export default function ChartIdeasInnovation({
  innovationProcess,
  campaignId,
  className = "campaign-detail-dashboard"
}) {
  const ideasData = getIdeaData(innovationProcess);

  return (
    <div className={`${className}__graph-container`}>
      <Headline className="mb4" small>
        Ideas in innovation process
      </Headline>
      <div className={`${className}__inno`}>
        <div className={`${className}__card mr8`}>
          <IconIdeas width="32px" className="mb8" />
          <Text large bold>
            {ideasData.count.uploaded}
          </Text>
          <Link
            className="chart-innovations__link"
            to={campaignId ? `/ideas/?campaign=${campaignId}` : "/ideas"}
          >
            Uploaded
          </Link>
        </div>
        <div className={`${className}__inno__card mr8`}>
          <IconInnoSelected width="32px" className="mb8" />
          <Text large bold>
            {ideasData.count.selected}
          </Text>
          <Link className="chart-innovations__link" to={"/inno"}>
            Selected
          </Link>
        </div>
        <div className={`${className}__inno__card mr8`}>
          <IconInnoApproved width="32px" className="mb8" />
          <Text large bold>
            {ideasData.count.approved}
          </Text>
          <Link className="chart-innovations__link" to={"/inno"}>
            Approved
          </Link>
        </div>
        <div className={`${className}__inno__card mr8`}>
          <IconInnoImplementation width="32px" className="mb8" />
          <Text large bold>
            {ideasData.count.implementing}
          </Text>
          <Link className="chart-innovations__link" to={"/inno"}>
            Implementing
          </Link>
        </div>
        <div className={`${className}__inno__card`}>
          <IconInnoDone width="32px" className="mb8" />
          <Text large bold>
            {ideasData.count.done}
          </Text>
          <Link className="chart-innovations__link" to={"/inno"}>
            Done
          </Link>
        </div>
        <div className={`${className}__inno__card mr8`}>
          <IconReplication width="32px" className="mb8" />
          <Text large bold>
            {ideasData.count.replicated}
          </Text>
          <Link
            className="chart-innovations__link"
            to={"/ideas?filterReplicated=true"}
          >
            Replicted
          </Link>
        </div>
      </div>
    </div>
  );
}
