import React, { useEffect, useCallback } from "react";
import cs from "classnames";
import "./CampaignCategory.css";
import Select from "../../../components/ui/Select";
import Idea from "../../../components/ingrid/Idea";
import Text from "../../../components/ui/Text";
import Headline from "../../../components/ui/Headline";
import { createLinkToModal } from "../../../components/Modal";
import useUrlParams from "../../../hooks/useUrlParams";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import debounce from "../../../library/debounce";
import useUpdateCampaignIdeas from "../../../hooks/useUpdateCampaignIdeas";
import { useAlert } from "react-alert";
import IngridAddCard from "../../../components/ingrid/AddCard";

const CATEGORY_TYPES = {
  FUN: "Fun",
  INNO: "Innovation"
};

const CAMPAIGN_IDEAS_QUERY = gql`
  query CampaignExampleIdeas($campaignId: ID!, $where: SequelizeJSON) {
    campaign(id: $campaignId) {
      id # this is important due to cache
      campaignIdeas(where: $where) {
        id
        description
        idea {
          id
          image
          description
          createdBy {
            id
            email
          }
        }
        category {
          id
        }
      }
    }
  }
`;

const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
};

export default function CampaignCategory({
  secondary = false,
  location,
  category,
  onCategoryUpdate,
  campaignId,
  campaignName
}: {
  onCategoryUpdate: () => Promise
}) {
  const [urlParams, { clearAllUrlParams }] = useUrlParams(location);

  const createdIdeaParam =
    urlParams[secondary ? "createdIdeaSecondary" : "createdIdeaMain"];
  const pickedIdeasParam =
    urlParams[secondary ? "pickedIdeasSecondary" : "pickedIdeasMain"];

  useEffect(() => {
    if (!pickedIdeasParam) return;

    const urlIds =
      (Array.isArray(pickedIdeasParam)
        ? pickedIdeasParam // weird I have to do this, something is changing type [1,2] to "1,2"
        : pickedIdeasParam?.split(",")) || [];

    const updatedIdeaIDs = (category?.ideaIds || [])
      .concat(urlIds)
      .map(Number)
      .filter(onlyUnique);

    onCategoryUpdate({ ideaIds: updatedIdeaIDs }).then(refetchExampleIdeas);
    clearAllUrlParams();
  }, [pickedIdeasParam]); // eslint-disable-line

  useEffect(() => {
    if (!createdIdeaParam) return;

    const updatedIdeaIDs = (category?.ideaIds || []).concat([
      Number(createdIdeaParam)
    ]);

    onCategoryUpdate({ ideaIds: updatedIdeaIDs }).then(refetchExampleIdeas);
    clearAllUrlParams();
  }, [createdIdeaParam]); // eslint-disable-line

  const {
    data: { campaign: { campaignIdeas: exampleIdeas = [] } = {} } = {},
    refetch: refetchExampleIdeas = () => {}
  } = useQuery(CAMPAIGN_IDEAS_QUERY, {
    skip: !campaignId || !category,
    variables: {
      campaignId,
      where: { isPromo: true, categoryId: category?.id }
    }
  });

  const addExampleIdeaLink = createLinkToModal(location)("/ideas", {
    suffix: secondary ? "Secondary" : "Main",
    // Prefixed by 'c' cuz modal can have different context and use campaign url param in his own way
    ccategory: category?.id,
    ccampaign: campaignId,
    context: "pick"
  });

  const update = useCallback(onCategoryUpdate, [campaignId, onCategoryUpdate]);
  const debouncedUpdate = useCallback(debounce(update, 300), [
    debounce,
    campaignId,
    category // this is IMPORTANT to update callback with up-to-date category data
  ]);

  const excludeExampleIdea = ideaId => {
    update({
      ideaIds: exampleIdeas
        .map(({ idea }) => idea.id)
        .filter(id => id !== ideaId)
    }).then(refetchExampleIdeas);
  };

  const alert = useAlert(null);
  const updateCampaignIdeas = useUpdateCampaignIdeas({ campaignId }, result => {
    if (result) alert.success("Description for this example updated");
  });

  return (
    <div
      className={cs(
        "wizard-campaign-category",
        secondary && "wizard-campaign-category--secondary",
        !category?.enabled && "wizard-campaign-category--disabled"
      )}
    >
      <div className="campaign-wizard-category__section--inline">
        <Headline>{secondary ? "Secondary" : "Main"}</Headline>
      </div>
      <div className="campaign-wizard-category__section--inline">
        <label className="wizard-campaign-category__name mr8">
          <Headline small className="campaign-wizard__headline">
            Category name<Text color="danger">*</Text>
          </Headline>
          <input
            className="ingrid-input ingrid-input--small"
            type="text"
            disabled={!category?.enabled}
            defaultValue={category?.name || campaignName}
            placeholder={campaignName}
            onChange={({ target: { value: name } }) => {
              debouncedUpdate({ name });
            }}
          />
        </label>

        <label className="wizard-campaign-category__type">
          <Headline small className="campaign-wizard__headline">
            Type<Text color="danger">*</Text>
          </Headline>
          <Select
            disabled={!category?.enabled}
            options={Object.keys(CATEGORY_TYPES).map(type => ({
              value: type,
              label: CATEGORY_TYPES[type]
            }))}
            value={category?.type}
            onChange={({ value: type }) => {
              update({ type });
            }}
          />
        </label>
      </div>
      <div className="campaign-wizard-category__section">
        <label>
          <Headline small className="campaign-wizard__headline">
            Description
          </Headline>
          <textarea
            className="ingrid-textarea"
            rows="3"
            disabled={!category?.enabled}
            defaultValue={category?.description}
            onChange={({ target: { value: description } }) => {
              debouncedUpdate({ description });
            }}
          />
        </label>
      </div>
      <Headline small className="campaign-wizard__headline">
        Example ideas
      </Headline>
      <div className="wizard-campaign-category-examples">
        {exampleIdeas.map(example => (
          <Idea
            key={example.id}
            example={example}
            onDescriptionEdit={description => {
              updateCampaignIdeas([{ id: example.idea.id, description }]);
            }}
            className="wizard-campaign-category-examples__idea"
            onImageClick={() => {
              const confirmed = window.confirm(
                "Are you sure you want to remove this example?"
              );
              if (!confirmed) return;
              excludeExampleIdea(example.idea.id);
            }}
            idea={example.idea}
          />
        ))}
        <IngridAddCard
          link={addExampleIdeaLink}
          onClick={event => {
            if (category?.enabled === false) event.preventDefault();
          }}
        />
      </div>
    </div>
  );
}
