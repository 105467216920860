import Rollbar from 'rollbar';

const {
  REACT_APP_ENVIRONMENT,
  REACT_APP_ROLLBAR_TOKEN,
  REACT_APP_ROLLBAR_ENABLED,
} = process.env;

export default function createRollbarInstance() {
  return new Rollbar({
    enabled: REACT_APP_ROLLBAR_ENABLED === 'true',
    accessToken: REACT_APP_ROLLBAR_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: REACT_APP_ENVIRONMENT || 'production',
    },
  });
}
