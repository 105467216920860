import { ApolloClient } from "apollo-client";

import { onError } from "apollo-link-error";
import { InMemoryCache } from "apollo-cache-inmemory";
import { typeDefs, resolvers, restoreCache } from "./resolvers";
const { createUploadLink } = require("apollo-upload-client");

export type EnhancedApolloClientInstance = {
  // (...(new ApolloClient))
  subscribeErrors: (handler: (message: String) => void) => void
};

const noop = () => {};

// Thous are handled in different than throw error subscriber
const SILENT_GQL_ERRORS = ["err_from_is_greater_then_to"];

export default function createApolloClient({
  serverAddress,
  auth
}): EnhancedApolloClientInstance {
  let errorCallback = noop;

  const uploadLink = createUploadLink({
    uri: `${serverAddress}admin/graphql`,
    headers: {
      get authorization() {
        return `Bearer ${auth.token}`;
      }
    }
  });

  const errorLink = onError(
    ({ networkError, response, graphQLErrors = [], operation }) => {
      if (networkError?.statusCode === 401) auth.logout();

      // Stop propagate error
      if (response) {
        response.errors = null;
        // tricky way how to pass error to mutation responses
        response.errors_ = graphQLErrors;
      }

      errorCallback(
        [
          ...graphQLErrors.map(msg => msg || "Unknown GQL error"),
          networkError?.message
        ]
          .filter(x => SILENT_GQL_ERRORS.includes(x) === false)
          .filter(x => Boolean(x))
      );
    }
  );

  const cache = new InMemoryCache({
    dataIdFromObject: ({ __typename, id, idea_id }) =>
      id || idea_id ? __typename + (id || idea_id) : null
  });

  const client = new ApolloClient({
    link: errorLink.concat(uploadLink),
    cache,
    typeDefs,
    resolvers
  });

  restoreCache(client, Boolean(auth.token));

  auth.subscribe(({ user }) => {
    if (!user) {
      client.clearStore();
    } else {
      restoreCache(client, Boolean(auth.token));
    }
  });

  return Object.assign(client, {
    subscribeErrors: handler => {
      if (errorCallback !== noop && handler !== errorCallback) {
        console.warn(
          "Not yet implemented. Only one subscribtion handler is allowed now."
        );
      }
      errorCallback = handler;
    }
  });
}
