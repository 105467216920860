import { useMutation } from "@apollo/react-hooks";
import cs from "classnames";
import gql from "graphql-tag";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import IconFile from "../../assets/icons/icon-file.svg";
import IconManual from "../../assets/icons/icon-keyboard.svg";
import IconUpload from "../../assets/icons/icon-upload.svg";
import Button from "../../components/ui/Button";
import Headline from "../../components/ui/Headline";
import { absoluteLink } from "../../library/url";
import "./UsersInvite.css";

const INVITE_BY_EMAILS_MUTT = gql`
  mutation InviteUsersByEmails($organizationId: ID!, $emails: [String]!) {
    inviteUser(organizationId: $organizationId, emails: $emails) {
      # totalItems
      invited
      errorCount
      alreadyInvited
      errorList
      logLink
    }
  }
`;
const INVITE_BY_FILE_MUTT = gql`
  mutation InviteUsersByCsvFile($organizationId: ID!, $file: Upload!) {
    inviteUsers(organizationId: $organizationId, file: $file) {
      # totalItems
      invited
      errorCount
      alreadyInvited
      errorList
      logLink
    }
  }
`;

const isValidCsvFile = file => file && file.name.endsWith(".csv");

export default function UsersInviteScreen({ location, organization, modal }) {
  const [inviteType, setInviteType] = useState("manual");
  const [csvFile, setCsvFile] = useState(null);
  const [emailList, setEmailList] = useState([]);
  const [csvFileError, setCsvFileError] = useState(false);
  const [submiting, setSubmiting] = useState(false);
  const alert = useAlert();

  const inviteMutationOptions = {
    refetchQueries: ["UsersList"]
  };

  const [inviteByEmails] = useMutation(
    INVITE_BY_EMAILS_MUTT,
    inviteMutationOptions
  );
  const [inviteByFile] = useMutation(
    INVITE_BY_FILE_MUTT,
    inviteMutationOptions
  );

  const handleSubmit = async event => {
    event.preventDefault();
    const handleSubmitingDone = ({
      invited,
      alreadyInvited,
      errorCount,
      // errorList,
      logLink
    }) => {
      modal.close();

      if (invited === 1) {
        alert.show(`Invited one new user`, { type: "success" });
      } else if (invited > 1) {
        alert.show(`Invited ${invited} new users`, { type: "success" });
      }

      if (alreadyInvited) {
        alert.show(`Skipped ${alreadyInvited} already invited users`, {
          type: "info"
        });
      }

      if (errorCount) {
        alert.show(
          <span>
            Ignored {errorCount} invalid emails. Check out{" "}
            <a
              href={absoluteLink(logLink)}
              target="_blank"
              rel="noopener noreferrer"
            >
              import log
            </a>{" "}
            file.
          </span>,
          {
            type: "error",
            timeout: 0
          }
        );
      }
    };

    if (inviteType === "manual") {
      setSubmiting(true);
      const { data: { inviteUser } = {} } = await inviteByEmails({
        variables: { organizationId: organization?.id, emails: emailList }
      });

      handleSubmitingDone(inviteUser || {});
    }

    if (inviteType === "file") {
      setSubmiting(true);
      const { data: { inviteUsers } = {} } = await inviteByFile({
        variables: { organizationId: organization?.id, file: csvFile }
      });

      handleSubmitingDone(inviteUsers || {});
    }
  };

  return (
    <form className="users-invite-screen" onSubmit={handleSubmit}>
      <Headline className="users-invite__headline">Invite Users</Headline>
      <div className="users-invite__buttons">
        <div className="users-invite-radio  mr16">
          <input
            className="users-invite-radio__input"
            type="radio"
            name="selectType"
            id="manual"
            value="manual"
            checked={inviteType === "manual"}
            onChange={() => {
              setInviteType("manual");
              setCsvFileError(null);
            }}
          />
          <label className="users-invite-radio__label" htmlFor={"manual"}>
            <div className="users-invite-radio__button">
              <IconManual width="24px" className="mb4" />
              Manual
            </div>
          </label>
        </div>
        <div className="users-invite-radio">
          <input
            className="users-invite-radio__input"
            type="radio"
            name="selectType"
            id="file"
            value="file"
            checked={inviteType === "file"}
            onChange={() => setInviteType("file")}
          />
          <label className="users-invite-radio__label" htmlFor={"file"}>
            <div className="users-invite-radio__button">
              <IconFile width="24px" className="mb4" />
              From file
            </div>
          </label>
        </div>
      </div>
      <div className="users-invite__container">
        {inviteType === "manual" && (
          <div className="users-invite__manual">
            <p>Insert user e-mails you want to invite to your organization.</p>
            <textarea
              className="ingrid-textarea"
              rows="5"
              placeholder={`Insert e-mails ...\n...`}
              value={emailList.join("\n")}
              onChange={({ target: { value } }) => {
                setEmailList(value.split("\n"));
              }}
            />
          </div>
        )}
        {inviteType === "file" && (
          <div className="users-invite__file-upload">
            <p>Upload a file with user e-mails from your computer.</p>
            <input
              className="users-invite__file-upload__input"
              type="file"
              name="usersInviteFile"
              id="users-invite-file"
              onChange={({
                target: {
                  files: [file]
                }
              }) => {
                if (!isValidCsvFile(file)) {
                  setCsvFileError(true);
                } else {
                  setCsvFile(file);
                }
              }}
            />
            <label
              className="users-invite__file-upload__label"
              htmlFor="users-invite-file"
            >
              <div
                className={cs(
                  "users-invite__file-upload__button",
                  csvFileError && "users-invite__file-upload__button--error"
                )}
              >
                <IconUpload width="20px" className="mr4" />

                {csvFile ? (
                  <p>{`File ready to use ${csvFile.name}`}</p>
                ) : (
                  <p className={csvFileError ? "color-error" : ""}>
                    Upload <b>.csv</b> from your computer
                  </p>
                )}
              </div>
            </label>
          </div>
        )}
      </div>

      <div className="users-invite__footer">
        <div className="users-invite__confirm">
          {/* TODO @dan tady by měl bejt confirm "are you sure" */}
          <Button
            small
            type="submit"
            disabled={
              (inviteType === "file" && !isValidCsvFile(csvFile)) ||
              (inviteType === "manual" && !emailList.length) ||
              submiting
            }
          >
            Invite selected users
          </Button>
        </div>
      </div>
    </form>
  );
}
