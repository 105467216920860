import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import TargetSelect from "../../components/ingrid/TargetSelect";
import Button from "../../components/ui/Button";
import DateTimeInput from "../../components/ui/DateTimeInput";
import Headline from "../../components/ui/Headline";
import Text from "../../components/ui/Text";
import useUrlParams from "../../hooks/useUrlParams";
import "./NotificationEdit.css";

const NOTIFICATION_QUERY = gql`
  query NotificationsList($organizationId: ID!, $where: SequelizeJSON) {
    notifications(organizationId: $organizationId, where: $where) {
      id
      title
      content
      sendAt
      target
      targetIds
      type
      campaign {
        id
        name
      }
    }
  }
`;

const UPSERT_NOTIFICATION_MUTT = gql`
  mutation(
    $organizationId: ID!
    $campaignId: ID
    $id: ID
    $title: String!
    $content: String!
    $sendAt: Date
    $type: notification_types
    $target: notification_target_types
    $targetIds: [ID]
  ) {
    upsertNotification(
      organizationId: $organizationId
      id: $id
      campaignId: $campaignId
      title: $title
      content: $content
      sendAt: $sendAt
      type: $type
      target: $target
      targetIds: $targetIds
    ) {
      id
      campaignId
      title
      content
      sendAt
      type
      target
      targetIds
    }
  }
`;

export default function NotificationEditScreen({
  organization,
  notificationId,
  modal
}) {
  const [urlParams] = useUrlParams(modal.location);
  const [formValues, setFormValues] = useState({});

  const [mutate, { error: serverError }] = useMutation(
    UPSERT_NOTIFICATION_MUTT,
    {
      refetchQueries: [
        "NotificationList",
        "CampaignQuery",
        "CampaignDraft",
        "PollDraft"
      ]
    }
  );

  const {
    loading,
    error,
    data: { notifications: [notification] = [] } = {}
  } = useQuery(NOTIFICATION_QUERY, {
    variables: {
      organizationId: organization?.id,
      where: { id: notificationId }
    },
    skip: !organization,
    onCompleted: ({ notifications: [notification] = [] }) => {
      setFormValues({
        title: notification.title,
        content: notification.content,
        sendAt: notification.sendAt,
        target: notification.target,
        targetIds: notification.targetIds
      });
    }
  });

  const alert = useAlert();
  const handleFormValueChange = fieldName => ({ value, target }) => {
    setFormValues({
      ...formValues,
      [fieldName]: value === undefined ? target?.value : value
    });
  };

  const handleSubmit = async event => {
    if (event) event.preventDefault();
    const { title, content, sendAt, target, targetIds } = formValues;
    await mutate({
      variables: {
        id: notificationId,
        organizationId: organization?.id,
        campaignId: urlParams.campaign,
        title,
        content,
        sendAt,
        target,
        targetIds
      }
    });
    alert.show("Notification was edited", { type: "success" });
    modal.close();
  };

  const handleTargetUpdate = target => {
    setFormValues({
      ...formValues,
      ...target
    });
  };

  const isInCampaignCreation = modal.location?.pathname.startsWith(
    "/campaigns/create"
  );
  return (
    <form className="notification-edit-screen" onSubmit={handleSubmit}>
      <Headline className="mb16">
        Edit notification {urlParams.campaign && <span>in campaign.</span>}
      </Headline>
      <div className="notification-edit-screen__section">
        <Text className="notification-edit-screen__label">Title:</Text>
        <input
          type="text"
          className="ingrid-input ingrid-input--small"
          onChange={handleFormValueChange("title")}
          value={formValues.title}
        />
      </div>
      <div className="notification-edit-screen__section">
        <Text className="notification-edit-screen__label">
          Time to send notification
          {notification?.type !== "CUSTOM"
            ? " (based on campaign settings)"
            : ":"}
        </Text>
        <DateTimeInput
          value={formValues.sendAt}
          onChange={handleFormValueChange("sendAt")}
          dropup
        />
      </div>
      <div className="notification-edit-screen__section">
        <Text className="notification-edit-screen__label">
          Notification content:
        </Text>
        <textarea
          className="ingrid-textarea"
          rows="5"
          onChange={handleFormValueChange("content")}
          value={formValues.content}
        />
      </div>
      {!isInCampaignCreation && (
        <div className="notification-edit-screen__section">
          <Text className="notification-edit-screen__label">
            Send notification to:
          </Text>
          <TargetSelect
            enableUsers
            organization={organization}
            value={formValues}
            onChange={handleTargetUpdate}
          />
        </div>
      )}
      {serverError ||
        (error && (
          <Text center color="error">
            {(serverError || error).toString()}
          </Text>
        ))}

      {loading && <Text center>Loading...</Text>}

      <Button
        fullwidth
        disabled={!formValues.title || !formValues.sendAt || !organization}
        type="submit"
      >
        Edit notification
      </Button>
    </form>
  );
}
