import { useQuery } from "@apollo/react-hooks";
import cs from "classnames";
import gql from "graphql-tag";
import React, { useEffect, useState } from "react";
import IconCross from "../../assets/icons/icon-cross.svg";
import IconOrganization from "../../assets/icons/icon-organization.svg";
import IconTeams from "../../assets/icons/icon-team.svg";
import IconUsers from "../../assets/icons/icon-user-solid.svg";
import Select from "../ui/Select";
import Text from "../ui/Text";
import Badge from "./Badge";
import "./TargetSelect.css";

const ORGANIZAITON_QUERY = gql`
  query($id: ID!, $usersWhere: SequelizeJSON, $usersLimit: Int) {
    organization(id: $id) {
      id
      users(limit: $usersLimit, where: $usersWhere) {
        id
        email
      }
      teams {
        id
        name
      }
    }
  }
`;

const targetTypes = {
  ORGANIZATION: {
    label: "Organization",
    description: "This will target the whole organization.",
    type: "ORGANIZATION",
    Icon: IconOrganization,
    targetIds: []
  },
  TEAM: {
    label: "Teams",
    description: "Select teams:",
    type: "TEAM",
    Icon: IconTeams,
    targetIds: []
  },
  USER: {
    label: "Users",
    description: "Select users:",
    type: "USER",
    Icon: IconUsers,
    targetIds: []
  }
};

export default function TargetSelect({
  organization,
  onChange = () => {},
  value,
  enableUsers = false,
  availableTargetIds = [],
  campaign,
  children = { error: null }
}) {
  const [targetsValues, setTargetValues] = useState(targetTypes);
  const [searchString, setSearchString] = useState("");

  const setTargetIds = (targetType, targetIds) =>
    setTargetValues({
      ...targetsValues,
      [targetType]: {
        ...targetsValues[targetType],
        targetIds
      }
    });

  useEffect(() => {
    const { target: targetType, targetIds } = value;
    setTargetIds(targetType || "ORGANIZATION", targetIds || []);
  }, [value.target, value.targetIds]); // eslint-disable-line

  const target = targetsValues[value.target];
  const showSelect = target?.type && target.type !== "ORGANIZATION";

  const ifTypeUser = value => (target?.type === "USER" ? value : undefined);

  const {
    data: { organization: { users = [], teams = [] } = {} } = {}
  } = useQuery(ORGANIZAITON_QUERY, {
    variables: {
      id: organization?.id,
      usersWhere: ifTypeUser({
        email: {
          iLike: "%" + searchString + "%"
        }
      }),
      usersLimit: ifTypeUser(10)
    },
    skip: !organization
  });

  const onSelect = organizationId => {
    const targetIds = target.targetIds.concat([organizationId]);

    setTargetIds(target.type, targetIds);
    onChange({
      target: target.type,
      targetIds
    });
  };

  const onRemove = organizationId => {
    const targetIds = target.targetIds.filter(
      targetId => targetId !== organizationId
    );

    setTargetIds(target.type, targetIds);
    onChange({
      target: target.type,
      targetIds
    });
  };

  const {
    data: { organization: { users: selectedUsers = [] } = {} } = {}
  } = useQuery(ORGANIZAITON_QUERY, {
    variables: {
      id: organization?.id,
      usersWhere: ifTypeUser({ id: target?.targetIds }),
      usersLimit: ifTypeUser(target?.targetIds.length)
    },
    skip: !organization
  });

  let collection = [];
  if (target?.type === "USER") collection = users;
  if (target?.type === "TEAM") collection = teams;

  let selectedCollection = [];
  if (target?.type === "USER") selectedCollection = selectedUsers;
  if (target?.type === "TEAM")
    selectedCollection = teams.filter(({ id }) =>
      target?.targetIds.includes(id)
    );

  selectedCollection = selectedCollection.sort((a, b) =>
    (a.email || a.name) > (b.email || b.name) ? 1 : -1
  );

  const generatedOptions = collection
    .filter(({ id }) => !target?.targetIds.includes(id))
    .map(({ id, email, name }) => ({
      label: email || name || id,
      value: id,
      isDisabled: availableTargetIds.length && !availableTargetIds.includes(id)
    }));

  const targetTypeRadio = targetValue => (
    <div key={targetValue.type} className="target-type-radio  mr16">
      <input
        className="target-type-radio__input"
        type="radio"
        name="category"
        id={targetValue.type}
        value={targetValue.type}
        checked={targetValue.type === value.target}
        // Damn tady se to jmenuje stejně
        onChange={({ target: { value: targetType } }) => {
          onChange({
            target: targetType,
            targetIds: targetsValues[targetType].targetIds
          });
        }}
      />
      <label className="target-type-radio__label" htmlFor={targetValue.type}>
        <div className="target-type-radio__button">
          <targetValue.Icon width="24px" className="mb16" />
          {targetValue.label}
        </div>
      </label>
    </div>
  );

  const handleRemove = id => () => onRemove(id);

  return (
    <div className="ingrid-target-select">
      <div className="wizard-campaign__section wizard-campaign__section--flex">
        {/* TODO: uselat poradek v CSS selectorech ^^^, co tu kua dela wizard-campaign? */}
        {targetsValues?.ORGANIZATION &&
          targetTypeRadio(targetsValues.ORGANIZATION)}
        {organization?.teamsEnabled &&
          targetsValues?.TEAM &&
          targetTypeRadio(targetsValues.TEAM)}
        {enableUsers &&
          targetsValues?.USER &&
          targetTypeRadio(targetsValues.USER)}
      </div>
      <div className="wizard-campaign-target__container">
        <div
          className={`wizard-campaign-target__${target?.type.toLowerCase()}`}
        >
          <Text block className="mb8">
            {target?.description}
          </Text>
          {showSelect && (
            <Select
              searchable
              fullwidth
              onSearch={setSearchString}
              onChange={option => {
                if (option) onSelect(option.value);
              }}
              options={generatedOptions}
              clearOnSelect
            />
          )}
          {showSelect && (
            <div
              className={cs(
                "ingrid-target-select__card",
                selectedCollection.length === 0 &&
                  "ingrid-target-select__card--noselection"
              )}
            >
              <ul className="ingrid-target-select__list">
                {selectedCollection.map(({ email, name, id }) => (
                  <li key={id} className="ingrid-target-select__list-item">
                    <div className="ingrid-target-select__user">
                      <div>
                        <Badge
                          className="ingrid-target-select__badge"
                          small
                          string={email || name || id.toString()}
                        />
                      </div>
                      <Text
                        bold
                        block
                        small
                        truncate
                        color={
                          campaign &&
                          !availableTargetIds.includes(id) &&
                          "error"
                        }
                        className="ingrid-target-select__text"
                      >
                        {email || name || id.toString()}
                        {campaign &&
                          !availableTargetIds.includes(id) &&
                          " (not available)"}
                      </Text>
                      <div
                        className="ingrid-target-select__delete"
                        onClick={handleRemove(id)}
                      >
                        <IconCross width="12px" />
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
      {campaign && !availableTargetIds.length && children.error}
    </div>
  );
}
