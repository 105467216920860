import React, { useRef } from "react";
import PropTypes from "prop-types";
import cs from "classnames";
import "./Select.css";

import ReactSelect from "react-select";

const OptionType = PropTypes.shape({
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string,
  label: PropTypes.string // for virtualized
});

Select.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
  maxOptionsHeight: PropTypes.string,
  options: PropTypes.arrayOf(OptionType),
  multi: PropTypes.bool,
  disabled: PropTypes.bool,
  dropup: PropTypes.bool,
  clearOnSelect: PropTypes.bool
};

Select.defaultProps = {
  options: []
};

const customStyles = ({ maxHeight, maxOptionsHeight }) => ({
  menuList: base => ({
    ...base,
    height: maxOptionsHeight
  }),
  control: base => ({
    ...base,
    height: maxHeight
  })
});

export const SelectPlaceholder = () => <div className="select-placeholder" />;

export default function Select({
  onChange,
  onSearch,
  options,
  className,
  value,
  clearable = false,
  searchable = false,
  maxOptionsHeight,
  maxHeight,
  multi,
  disabled,
  dropup,
  clearOnSelect,
  ...pass
}) {
  const ref = useRef(null);
  const handleChange = option => {
    onChange(option);
    if (option && clearOnSelect) setTimeout(ref.current.select.clearValue, 10);
  };
  const handleSearchChange = onSearch;
  return (
    <ReactSelect
      ref={ref}
      className={cs("react-select", dropup && "react-select--dropup")}
      classNamePrefix="react-select"
      value={
        Array.isArray(value)
          ? value
          : options.find(option => option.value === value)
      }
      options={options}
      onChange={handleChange}
      onInputChange={handleSearchChange}
      styles={customStyles({ maxOptionsHeight, maxHeight })}
      isSearchable={searchable}
      isClearable={clearable}
      isMulti={multi}
      isDisabled={disabled}
      {...pass}
    />
  );
}
