import React from "react";
import PollIdeas from "./PollIdeas";
import { LocationProvider } from "@reach/router";

import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

const UPSERT_CATEGORY_MUTT = gql`
  mutation(
    $id: ID
    $campaignId: ID!
    $ideaIds: [ID] # $type: category_type
  ) {
    upsertCategory(
      id: $id
      main: true
      campaignId: $campaignId
      ideaIds: $ideaIds
      type: INNO
      name: "Poll"
    ) {
      id
      ideaIds
      campaignId
      main
    }
  }
`;

Ideas.validate = ({ categories: [primaryCategory] = [] } = {}) => {
  return Boolean(primaryCategory?.id) && primaryCategory.ideaIds.length >= 2;
};

export default function Ideas({
  campaign = {},
  campaignId // it's from url, so its available immediately
} = {}) {
  const { categories = [] } = campaign;
  const primaryCategory = categories.find(({ main }) => main);

  const [mutate] = useMutation(UPSERT_CATEGORY_MUTT, {
    refetchQueries: ["PollDraft"]
  });
  const updateIdeas = (ideaIds = []) => {
    if (!primaryCategory) return;
    return mutate({
      variables: {
        id: primaryCategory.id,
        campaignId: Number(campaignId),
        ideaIds
      },
      optimisticResponse: {
        __typename: "Mutation",
        upsertCategory: {
          id: primaryCategory.id,
          campaignId: Number(campaignId),
          ideaIds,
          main: true,
          __typename: "category"
        }
      }
    });
  };
  return (
    <div className="poll-wizard-categories">
      <p>Place or just check all ideas wanted to be voted in this Poll.</p>

      <LocationProvider>
        {({ location }) => (
          <div className="poll-wizard-categories__container">
            <PollIdeas
              location={location}
              category={primaryCategory}
              campaignId={campaignId}
              onIdeasUpdate={updateIdeas}
            />
          </div>
        )}
      </LocationProvider>
    </div>
  );
}
