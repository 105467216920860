import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "react-datepicker/dist/react-datepicker.css"; // meh
import Application from "./components/Application";
import createRollbarInstance from "./library/rollbar";
import createAuthInstance from "./library/auth";
import * as serviceWorker from "./serviceWorker";
import { Location } from "@reach/router";
import { ApolloProvider } from "@apollo/react-hooks";
import createAxiosInstance from "./library/axios";
import createApolloClient from "./library/apollo";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

const { REACT_APP_API_HOST: serverAddress } = process.env;

const rollbar = createRollbarInstance();
const axios = createAxiosInstance({ serverAddress });
const auth = createAuthInstance({ axios });
const apollo = createApolloClient({ serverAddress, auth });

const options = {
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: "16px",
  containerStyle: { marginBottom: "16px", zIndex: 10000 },
  transition: transitions.FADE
};

const ExtraStyledTemplate = pass => (
  <AlertTemplate {...pass} style={{ ...pass.style, width: "400px" }} />
);

const ProvidedApplication = () => (
  <AlertProvider template={ExtraStyledTemplate} {...options}>
    <ApolloProvider client={apollo}>
      <Location>
        {({ location }) => (
          <Application {...{ apollo, location, rollbar, axios, auth }} />
        )}
      </Location>
    </ApolloProvider>
  </AlertProvider>
);

ReactDOM.render(<ProvidedApplication />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
