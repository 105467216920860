import React, { useState } from "react";
// import { navigate } from "@reach/router";
import "./Templates.css";
import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import Button from "../../components/ui/Button";
import TemplatePlan from "./TemplatePlan";
import { useAlert } from "react-alert";

export const TEMPLATE_PLANS_QUERY = gql`
  query TemplatePlans {
    plans(order: "reverse:created_at") {
      id
      name
      created_by {
        id
        email
        superadmin
        deleted_at # TODO: use this and stroke user email in <User />
      }
    }
  }
`;

const UPSERT_PLAN_MUTT = gql`
  mutation($name: String!, $id: ID) {
    upsertPlan(name: $name, id: $id) {
      id
      name
    }
  }
`;

const DELETE_PLAN_MUTT = gql`
  mutation($id: ID!) {
    deletePlan(id: $id)
  }
`;

export default function TemplatesScreen({ location, organization, user }) {
  const {
    data: { plans: templatePlans = [] } = {}
  } = useQuery(TEMPLATE_PLANS_QUERY, { skip: !user.superadmin });
  const [newPlanName, changeNewPlaneName] = useState("");
  const [upsertPlan] = useMutation(UPSERT_PLAN_MUTT, {
    refetchQueries: ["TemplatePlans", "PlanTemplates"]
  });
  const [deletePlan] = useMutation(DELETE_PLAN_MUTT, {
    refetchQueries: ["TemplatePlans"]
  });
  const alert = useAlert(null);

  const handleUpsertPlan = async planId => {
    const { errors } = await upsertPlan({
      variables: { name: newPlanName, id: planId }
    });
    if (errors) {
      errors.forEach(alert.fail);
    } else {
      alert.success(planId ? "Plan updated" : "Plan created");
    }
  };

  const handleSubmitPlan = async event => {
    if (event) event.preventDefault();
    if (newPlanName) {
      handleUpsertPlan();
      changeNewPlaneName("");
    }
  };

  return (
    <div className="templates-screen">
      <h2>Create new plan</h2>
      <form onSubmit={handleSubmitPlan} className="templates-screen__plan-form">
        <input
          className="ingrid-input ingrid-input--"
          type="text"
          value={newPlanName}
          placeholder="Plan name"
          onChange={({ target: { value } }) => {
            changeNewPlaneName(value || "");
          }}
        />
        <Button
          onClick={handleSubmitPlan}
          type="submit"
          disabled={!newPlanName}
        >
          Create plan
        </Button>
      </form>

      <h2>Template plans</h2>
      <div className="templates-screen__plans-grid">
        {templatePlans.map(plan => (
          <TemplatePlan
            key={plan.id}
            {...{ plan, organization, location }}
            onNameChange={_plan => {
              upsertPlan({ variables: _plan });
            }}
            onRemove={async () => {
              const { errors } = await deletePlan({
                variables: { id: plan.id }
              });
              if (!errors) alert.success("Your plan was deleted");
            }}
          />
        ))}
      </div>
      {!templatePlans.length && <p>No plans yet.</p>}
    </div>
  );
}
