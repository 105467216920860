import React from "react";
import { Link, navigate } from "@reach/router";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

import Card from "../ui/Card";
import Text from "../ui/Text";
import classNames from "classnames";
import * as format from "../../library/format";
import "./Campaign.css";

import CampaignBadgeInno from "../../assets/icons/campaign-badges/badge-category-inno.svg";
import CampaignBadgeFun from "../../assets/icons/campaign-badges/badge-category-fun.svg";
import IconUsers from "../../assets/icons/icon-user-solid.svg";
import IconCampaigns from "../../assets/icons/icon-campaign.svg";
import IconApprovable from "../../assets/icons/icon-inno-approved.svg";

import IconMenu from "../../assets/icons/icon-kebab-big.svg";
import IconEdit from "../../assets/icons/icon-edit.svg";
import IconNotification from "../../assets/icons/icon-notification.svg";
import IconDelete from "../../assets/icons/icon-cross.svg";
import Dropdown from "../../components/ui/Dropdown";
import Badge from "./Badge";
import CampaignState from "./CampaignState";
import { createLinkToModal } from "../../components/Modal";

import { useAlert } from "react-alert";
import useLoadFromTemplate from "../../hooks/useLoadFromTemplate";
import Button from "../ui/Button";

const DELETE_CAMPAIGN_MUTT = gql`
  mutation($id: ID!) {
    deleteCampaign(id: $id)
  }
`;

// const FINISH_CAMPAIGN_MUTT = gql`
//   mutation($id: ID!) {
//     finishCampaign(id: $id)
//   }
// `;

const getTeamNameById = (array, id) => array.find(item => item.id === id)?.name;

export default function IngridCampaign({
  campaign: {
    id,
    name,
    from,
    to,
    ideas = [],
    target,
    state,
    categories,
    isApprovable,
    isTemplate,
    teams = [],
    campaignStats,
    targetIds = []
  } = {},
  teamList,
  noMenu,
  afterCampaignRemove,
  organization,
  ...pass
}) {
  const alert = useAlert();
  const campaignState = state?.toLowerCase().replace(/_/g, "-");
  const [deleteCampaign] = useMutation(DELETE_CAMPAIGN_MUTT, {
    refetchQueries: [
      "CampaignDrafts",
      "CampaignsGrid",
      "CampaignTemplates",
      "RunningCampaigns"
    ]
  });

  // DODĚLAME POZDEJI
  // const [finishCampaign] = useMutation(FINISH_CAMPAIGN_MUTT, {
  //   refetchQueries: ["RunningCampaigns", "CampaignsGrid"]
  // });

  // const handleFinishCampaign = () => {
  //   const confirmed = window.confirm("You sure?");
  //   if (!confirmed) return;
  //   finishCampaign({ variables: { id } });
  //   alert.show("Campaign finished", { type: "success" });
  // };

  const handleCreateNotification = location => {
    navigate(
      createLinkToModal(location)(`/notifications/create`, {
        campaign: id
      })
    );
  };

  const handleRemoveCampaign = async () => {
    if (window.confirm("Are you sure to delete this campaign?")) {
      await deleteCampaign({ variables: { id } });
      alert.show("Campaign was deleted", { type: "success" });
    }
  };

  const loadFromTemplate = useLoadFromTemplate(({ id: newCampaignId }) => {
    if (newCampaignId) {
      alert.success("Template transformed to campaign");
      navigate(`/campaigns/create/${newCampaignId}`);
    }
  });
  const handleUseTemplate = () => loadFromTemplate(id);
  const handleEditCampaign = () => navigate(`/campaigns/create/${id}`);

  return (
    <div className="ingrid-campaign">
      <Card fullwidth {...pass}>
        <div
          className={classNames(
            "ingrid-campaign__header-container",
            !isTemplate
              ? `ingrid-campaign__header-container--${campaignState}`
              : "ingrid-campaign__header-container--template"
          )}
        >
          <div className="ingrid-campaign__header">
            <Link
              className={classNames(
                "ingrid-campaign__header-link",
                !isTemplate
                  ? `ingrid-campaign__header-link--${campaignState}`
                  : "ingrid-campaign__header-link--template"
              )}
              to={
                campaignState === "draft"
                  ? `/campaigns/create/${id}`
                  : `/campaigns/${id}`
              }
            >
              <div className="ingrid-campaign__header-name">
                {name || <i>Not named yet</i>}
                {isApprovable && (
                  <div data-title="This campaign will require manual evaluation when it's finished.">
                    <IconApprovable width="16px" className="ml8" />
                  </div>
                )}
              </div>
            </Link>
            <div className="ingrid-campaign__header-date">
              {format.onlyDate(from)} - {format.onlyDate(to)}
            </div>
          </div>
          {campaignState !== "finished" && !noMenu && (
            <div className="ingrid-campaign__header-menu">
              <IconMenu width="24" />

              <div className="ingrid-campaign__header-dropdown">
                <Dropdown
                  actions={[
                    !["draft", "finished"].includes(campaignState) && {
                      Icon: IconNotification,
                      label: "Schedule notification",
                      handler: handleCreateNotification
                    },
                    {
                      Icon: !isTemplate ? IconEdit : IconCampaigns,
                      label: !isTemplate ? "Edit campaign" : "Use template",
                      handler: isTemplate
                        ? handleUseTemplate
                        : handleEditCampaign
                    },
                    // ["running"].includes(campaignState) && {
                    //   Icon: IconDelete,
                    //   color: "danger",
                    //   label: "Finish campaign",
                    //   handler: handleFinishCampaign
                    // },
                    {
                      Icon: IconDelete,
                      color: "danger",
                      label: !isTemplate
                        ? "Delete campaign"
                        : "Delete template",
                      handler: handleRemoveCampaign
                    }
                  ].filter(x => Boolean(x))}
                />
              </div>
            </div>
          )}
        </div>

        <div className="ingrid-campaign__content">
          {categories?.slice(0, 2).map(category => (
            <div className="ingrid-campaign__row" key={category.id}>
              {category.type === "INNO" ? (
                <div className="ingrid-campaign__icon-box">
                  <CampaignBadgeInno width="16px" height="16px" />
                </div>
              ) : (
                <div className="ingrid-campaign__icon-box">
                  <CampaignBadgeFun width="16px" height="16px" />
                </div>
              )}
              <div className="ingrid-campaign__category-type">
                <Text small bold color="gray-dark">
                  {category.type}
                </Text>
              </div>
              <div className="ingrid-campaign__category-name">
                <Text small bold truncate>
                  {category.name}
                </Text>
              </div>
              <div className="ingrid-campaign__category-count">
                <Link to={`/ideas/?campaign=${id}&categories=${category.id}`}>
                  {" "}
                  <Text small>{category.ideaCount}</Text>
                </Link>
              </div>
            </div>
          ))}
          {!isTemplate && (
            <div className="ingrid-campaign__row">
              <div className="ingrid-campaign__icon-box ingrid-campaign__icon-box--nocircle">
                <IconCampaigns width="12px" />
              </div>
              <div className="ingrid-campaign__category-name ingrid-campaign__category-users">
                <Text small>Ideas total</Text>
              </div>
              <div className="ingrid-campaign__category-count">
                <Link to={`/ideas/?campaign=${id}`}>
                  <Text small>{campaignStats?.ideaCount}</Text>
                </Link>
              </div>
            </div>
          )}
          {!isTemplate && (
            <div className="ingrid-campaign__row">
              <div className="ingrid-campaign__icon-box ingrid-campaign__icon-box--nocircle">
                <IconUsers width="12px" />
              </div>
              <div className="ingrid-campaign__category-name ingrid-campaign__category-users">
                <Text small>Users active</Text>
              </div>
              <div className="ingrid-campaign__category-count">
                <Text small>{campaignStats?.activeUsersCount}</Text>
              </div>
            </div>
          )}
          {!isTemplate && (
            <div className="ingrid-campaign__row ingrid-campaign__row--bottom">
              <div className="ingrid-campaign__category-state">
                <Text italic tiny>
                  <CampaignState state={campaignState} />
                </Text>
              </div>
              <div className="ingrid-campaign__category-teams">
                {target === "TEAM" ? (
                  targetIds.map(teamId => (
                    <div
                      key={teamId}
                      className="ml4"
                      data-title={String(teamId)}
                    >
                      <Badge
                        string={getTeamNameById(teamList, teamId)}
                        small
                        team
                      />
                    </div>
                  ))
                ) : (
                  <div key="id">
                    <Badge string="Organization" small team={target} />
                  </div>
                )}
              </div>
            </div>
          )}
          {isTemplate && (
            <div className="ingrid-campaign__template-button">
              <Button secondary small fullwidth onClick={handleUseTemplate}>
                Use template
              </Button>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
}
