import React, { useRef } from "react";
import classNames from "classnames";
import "./Button.css";

const noop = () => {};

export default function Button({
  onClick,
  title,
  children,
  fullwidth,
  secondary,
  className,
  danger,
  small,
  clear,
  tiny,
  icon,
  inverted,
  invertedSecondary,
  disabled = false,
  ...passProps
}) {
  const ref = useRef(null);
  const handleClick = () => {
    const handler = !disabled ? onClick : noop;
    ref.current.blur();
    if (typeof handler === "function") handler();
  };

  return (
    <button
      {...passProps}
      className={classNames(
        "button",
        fullwidth && "button--fullwidth",
        danger && "button--danger",
        disabled && "button--disabled",
        small && "button--small",
        tiny && "button--tiny",
        secondary && "button--secondary",
        icon && "button--icon",
        inverted && "button--inverted",
        invertedSecondary && "button--inverted-secondary",
        className
      )}
      onClick={handleClick}
      ref={ref}
      disabled={disabled}
    >
      {title || children}
    </button>
  );
}
