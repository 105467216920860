// import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import cn from "classnames";
import gql from "graphql-tag";
import React, { useState } from "react";
import IconCampaign from "../../assets/icons/icon-campaign.svg";
import IconSearch from "../../assets/icons/icon-search.svg";
import IconTeams from "../../assets/icons/icon-team.svg";
import IconUserState from "../../assets/icons/icon-user-state.svg";
import Select, { SelectPlaceholder } from "../ui/Select";
import Text from "../ui/Text";
import "./FilterRow.css";

const QUERY = gql`
  query($organizationId: ID!) {
    campaigns(organizationId: $organizationId, order: "reverse:to") {
      items {
        id
        name
        state
        categories {
          id
          name
        }
      }
    }
    teams(organizationId: $organizationId) {
      id
      name
    }
  }
`;

export default function FilterRow({
  organizationId,
  values,
  onChange,
  className,
  itemsFilteredCount,
  campaign: showCampaign = true,
  teams: showTeams = true,
  showUserState,
  showSort,
  children
}) {
  const { data: { campaigns, teams = [] } = {} } = useQuery(QUERY, {
    variables: { organizationId },
    skip: !organizationId
  });

  const [searchValue, setSearchValue] = useState(values.search || "");
  const handleTextInputChange = kind => ({ target: { value } }) => {
    setSearchValue(value);
    onChange({ [kind]: value });
  };
  const handleSelectChange = kind => option => {
    let val = option?.value;
    if (Array.isArray(option)) {
      val = option.map(({ value }) => value);
    }

    if (showUserState && kind === "userState") {
      if (val === "admin") {
        onChange({ admin: true, userState: undefined });
      } else {
        onChange({ admin: undefined, userState: val });
      }
      return;
    }

    onChange({ [kind]: val });
  };

  const campaignCategories = !showCampaign
    ? undefined
    : campaigns?.items
        .find(({ id }) => id === Number(values.campaign))
        // get options for <Select>
        ?.categories.map(category => ({
          label: category.name,
          value: category.id
        }));

  return (
    <div>
      <div className={cn("filter-row", className)}>
        <div className="filter-row__item filter-row__item--search">
          <label className="filter-row__label">
            <IconSearch className="filter-row__item-icon" width="16" />

            <input
              className="ingrid-input ingrid-input--small filter-row__search-input"
              type="search"
              placeholder={
                values.filterTagged ? "Search tags ..." : "Search ..."
              }
              value={searchValue}
              onChange={handleTextInputChange("search")}
            />
          </label>
        </div>
        {showUserState && (
          <div className="filter-row__item">
            <label className="filter-row__label">
              <IconUserState className="filter-row__item-icon" width="16" />
              <div className="filter-row__select">
                <Select
                  placeholder="Status"
                  value={values.userState}
                  searchable
                  clearable
                  options={[
                    {
                      label: "Invited",
                      value: "invited"
                    },
                    {
                      label: "Registered",
                      value: "registered"
                    },
                    {
                      label: "Active",
                      value: "active"
                    },
                    {
                      label: "Engaged",
                      value: "engaged"
                    },
                    {
                      label: "Innovator",
                      value: "innovator"
                    },
                    {
                      label: "Replicator",
                      value: "replicator"
                    },
                    {
                      label: "Admin",
                      value: "admin"
                    }
                  ]}
                  onChange={handleSelectChange("userState")}
                />
              </div>
            </label>
          </div>
        )}
        {showTeams && (
          <div className="filter-row__item">
            <label className="filter-row__label">
              <IconTeams className="filter-row__item-icon" width="16" />

              {teams.length > 0 ? (
                <div className="filter-row__select">
                  <Select
                    value={values.team}
                    placeholder="Teams"
                    searchable
                    clearable
                    options={teams.map(team => ({
                      label: team.name,
                      value: team.id.toString()
                    }))}
                    onChange={handleSelectChange("team")}
                  />
                </div>
              ) : (
                <SelectPlaceholder />
              )}
            </label>
          </div>
        )}
        {showCampaign && (
          <div className="filter-row__item filter-row__item--campaign">
            <label className="filter-row__label">
              <IconCampaign className="filter-row__item-icon" width="16" />

              {campaigns?.items.length > 0 ? (
                <div className="filter-row__select">
                  <Select
                    value={values.campaign}
                    placeholder="Campaign"
                    searchable
                    clearable
                    options={campaigns?.items
                      .filter(({ state }) => state !== "DRAFT")
                      .map(campaign => ({
                        label: campaign.name,
                        value: campaign.id.toString()
                      }))}
                    onChange={option => {
                      onChange({
                        campaign: option?.value,
                        category: option ? values.category : null
                      });
                    }}
                  />
                </div>
              ) : (
                <SelectPlaceholder />
              )}
            </label>
          </div>
        )}
        {campaignCategories && (
          <div className="filter-row__item filter-row__item--category">
            <Select
              value={Number(values.category)}
              placeholder="Categories"
              options={[{ label: "All categories" }, ...campaignCategories]}
              onChange={handleSelectChange("category")}
            />
          </div>
        )}
        {showUserState && (
          <Text>
            Filtered <b>{itemsFilteredCount?.data}</b> users.
          </Text>
        )}
        <div className="filter-row__toolbox">{children}</div>
      </div>
    </div>
  );
}
