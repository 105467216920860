import React, { useEffect } from "react";
import {
  Funnel,
  FunnelChart,
  LabelList,
  ResponsiveContainer,
  Tooltip
} from "recharts";
import Headline from "../ui/Headline";

const renderFunnelLabel = ({ x, y, width, height, value: { name, value } }) => {
  return (
    <text
      x={x}
      y={y}
      width={width}
      height={height}
      stroke="none"
      fill="var(--color-black)"
      className="recharts-text recharts-label"
      textAnchor="middle"
    >
      <tspan x={x + width / 2} y={y + 18}>
        {name}
      </tspan>
      <tspan
        className="campaign-detail-dashboard__funnel__label--big"
        x={x + width / 2}
        dy="1em"
      >
        {value}
      </tspan>
    </text>
  );
};

// @radek: tahle funce z obycejne value (kde je cislo) udela { value, name }
const funnelValueAccessor = ({ name, value }) => {
  return { name, value };
};

const transformUserStatisticsForFunnelGraph = (userStatistics, showInvited) => {
  if (!userStatistics) return [false, []];

  let activeUsers = userStatistics.active;
  if (activeUsers < userStatistics.engaged) {
    activeUsers += userStatistics.engaged;
  }

  let ret = [];

  if (showInvited) {
    ret.push({
      value: userStatistics.invited,
      name: "Invited users",
      fill: "var(--color-chart-1)"
    });
  }

  ret.push({
    value: userStatistics.registered,
    name: "Registered users",
    fill: `var(--color-chart-${ret.length + 1})`
  });
  ret.push({
    value: activeUsers,
    name: "Active users",
    fill: `var(--color-chart-${ret.length + 1})`
  });
  ret.push({
    value: userStatistics.engaged,
    name: "Engaged users",
    fill: `var(--color-chart-${ret.length + 1})`
  });

  return [ret.reduce((a, b) => a + (b.value || 0), 0) === 0, ret];
};

export default function ChartUsersFunnel({
  userStatistics,
  className = "organization-dashboard",
  showInvited = true
}) {
  const [isEmpty, usersFunnelData] = transformUserStatisticsForFunnelGraph(
    userStatistics,
    showInvited
  );

  useEffect(() => {
    let tspans = document.querySelectorAll(".recharts-label > tspan");
    if (tspans.length) {
      // make all tspan x position equal
      tspans.forEach(tspan => {
        tspan.attributes.x.value = tspans[0].attributes.x.value;
      });
    }
  });

  return (
    <div className={`${className}__graph-container`}>
      <Headline small className="mb4">
        User activity funnel
      </Headline>

      <div className={`${className}__graph`}>
        <ResponsiveContainer width="100%" height={220}>
          <FunnelChart margin={{ top: 16, right: 16, bottom: 0, left: 16 }}>
            <Tooltip />
            {!isEmpty ? (
              <Funnel
                dataKey="value"
                data={usersFunnelData}
                isAnimationActive={false}
              >
                <LabelList
                  position="insideTop"
                  fill="#000"
                  stroke="none"
                  valueAccessor={funnelValueAccessor}
                  content={renderFunnelLabel}
                />
              </Funnel>
            ) : (
              ""
            )}
          </FunnelChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
