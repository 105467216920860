import { useQuery } from "@apollo/react-hooks";
import cs from "classnames";
import gql from "graphql-tag";
import React from "react";
import Idea from "../../components/ingrid/Idea";
import Text from "../../components/ui/Text";
import Loading from "../../components/ui/Loading";

import { navigate } from "@reach/router";
import { createLinkToModal } from "../../components/Modal";

import "./PollDetail.css";
import Headline from "../../components/ui/Headline";

export const CAMPAIGN_QUERY = gql`
  query PollCampaign($campaignId: ID!) {
    campaign(id: $campaignId) {
      id
      name
      campaignIdeas(limit: 100, order: "organization_order") {
        order
        idea {
          id
          image
          value
          innovationProcess {
            state
          }
          description
          createdBy {
            id
            email
          }
        }
      }
    }
  }
`;

export default function CampaignDetailScreen({
  location,
  campaignId,
  organization,
  modal
}) {
  const { error, loading, data: { campaign } = {} } = useQuery(CAMPAIGN_QUERY, {
    variables: {
      campaignId
    },
    skip: !campaignId
  });

  if (error) {
    return (
      <div className="current-campaigns current-campaigns--error">
        <Text small center color="error">
          {error.toString()}
        </Text>
      </div>
    );
  }

  const handleIdeaClick = idea => () => {
    const ideaId = idea.id.toString(); // meh
    const path = `/ideas/${ideaId}`;
    navigate(createLinkToModal(modal.location)(path));
  };

  return (
    <div className="poll-detail-screen">
      {" "}
      <Headline>{campaign?.name}</Headline>
      {loading && <Loading>Loading campaigns ...</Loading>}
      <div className="poll-detail__grid">
        {campaign?.campaignIdeas?.map(idea => (
          <div className="poll-detail__idea" key={idea.idea.id}>
            <Idea
              highlight={false}
              idea={idea.idea}
              currency={organization?.currency}
              showInno
              onClick={handleIdeaClick(idea.idea)}
            />
            {idea.order && (
              <div
                className={cs(
                  "current-campaigns__idea-order",
                  `current-campaigns__idea-order--${idea.order}`
                )}
              >
                {idea.order}.
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
