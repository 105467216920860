import React from "react";
import { Link, navigate } from "@reach/router";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

import Card from "../ui/Card";
import Text from "../ui/Text";
import classNames from "classnames";
import * as format from "../../library/format";
import "./Poll.css";

import IconUsers from "../../assets/icons/icon-user-solid.svg";
import IconIdeas from "../../assets/icons/icon-idea.svg";

import IconMenu from "../../assets/icons/icon-kebab-big.svg";
import IconEdit from "../../assets/icons/icon-edit.svg";
import IconPoll from "../../assets/icons/icon-poll.svg";
import IconNotification from "../../assets/icons/icon-notification.svg";
import IconDelete from "../../assets/icons/icon-cross.svg";
import Dropdown from "../../components/ui/Dropdown";
import Badge from "./Badge";
import CampaignState from "./CampaignState";
import { createLinkToModal } from "../../components/Modal";

const DELETE_CAMPAIGN_MUTT = gql`
  mutation($id: ID!) {
    deleteCampaign(id: $id)
  }
`;

export default function IngridPoll({
  location,
  campaign: {
    id,
    name,
    from,
    to,
    ideas = [],
    target,
    state,
    categories,
    teams = [],
    campaignStats
  } = {},
  afterCampaignRemove,
  ...pass
}) {
  const campaignState = state?.toLowerCase().replace(/_/g, "-");
  const [deleteCampaign] = useMutation(DELETE_CAMPAIGN_MUTT, {
    refetchQueries: ["PollsDrafts", "PollsGrid", "RunningPolls"]
  });

  const handleCreateNotification = location => {
    navigate(
      createLinkToModal(location)(`/notifications/create`, {
        campaign: id
      })
    );
  };

  const handlePollClick = (location, campaignState, campaignId) => {
    const path =
      campaignState.toLowerCase() === "draft"
        ? `/polls/create/${campaignId}`
        : `/polls/${campaignId}`;
    campaignState.toLowerCase() === "draft"
      ? navigate(path)
      : navigate(createLinkToModal(location)(path));
  };

  const handleRemoveCampaign = async () => {
    if (window.confirm("Are you sure to delete this poll?")) {
      await deleteCampaign({ variables: { id } });
      alert.show("Poll was deleted", { type: "success" });
    }
  };
  return (
    <div className="ingrid-poll">
      <Card fullwidth {...pass}>
        <div
          className={classNames(
            "ingrid-poll__header-container",
            `ingrid-poll__header-container--${campaignState}`
          )}
        >
          <IconPoll width="24px" className="mr12" />
          <div className="ingrid-poll__header">
            <div
              className={classNames(
                "ingrid-poll__header-link",
                `ingrid-poll__header-link--${campaignState}`
              )}
              onClick={() => handlePollClick(location, campaignState, id)}
            >
              <div className="ingrid-poll__header-name">
                {name || <i>Not named yet</i>}
              </div>
            </div>
            <div className="ingrid-poll__header-date">
              {format.onlyDate(from)} - {format.onlyDate(to)}
            </div>
          </div>
          {campaignState !== "finished" && (
            <div className="ingrid-poll__header-menu">
              <IconMenu width="24" />

              <div className="ingrid-poll__header-dropdown">
                <Dropdown
                  actions={[
                    !["draft", "finished"].includes(campaignState) && {
                      Icon: IconNotification,
                      label: "Schedule notification",
                      handler: handleCreateNotification
                    },
                    {
                      Icon: IconEdit,
                      label: "Edit poll",
                      link: `/polls/create/${id}`
                    },
                    {
                      Icon: IconDelete,
                      color: "danger",
                      label: "Delete poll",
                      handler: handleRemoveCampaign
                    }
                  ].filter(x => Boolean(x))}
                />
              </div>
            </div>
          )}
        </div>
        <div className="ingrid-poll__content">
          <div className="ingrid-poll__row">
            <div className="ingrid-poll__icon-box ingrid-poll__icon-box--nocircle">
              <IconIdeas width="12px" />
            </div>
            <div className="ingrid-poll__category-name ingrid-poll__category-users">
              <Text small>Ideas count</Text>
            </div>
            <div className="ingrid-poll__category-count">
              <Link to={`/ideas/?campaign=${id}`}>
                <Text small>{campaignStats?.ideaCount}</Text>
              </Link>
            </div>
          </div>
          <div className="ingrid-poll__row">
            <div className="ingrid-poll__icon-box ingrid-poll__icon-box--nocircle">
              <IconUsers width="12px" />
            </div>
            <div className="ingrid-poll__category-name ingrid-poll__category-users">
              <Text small>Users voted</Text>
            </div>
            <div className="ingrid-poll__category-count">
              <Text small>{campaignStats?.activeUsersCount}</Text>
            </div>
          </div>
          <div className="ingrid-poll__row ingrid-poll__row--bottom">
            <div className="ingrid-poll__category-state">
              <Text italic tiny>
                <CampaignState state={campaignState} />
              </Text>
            </div>
            <div className="ingrid-poll__category-teams">
              {target === "TEAM" ? (
                teams.map(team => (
                  <div key={team.id} className="ml4">
                    <Badge string={team.name} small team />
                  </div>
                ))
              ) : (
                <div key="id">
                  <Badge string="Organization" small team={target} />
                </div>
              )}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
