import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import TargetSelect from "../../components/ingrid/TargetSelect";
import Button from "../../components/ui/Button";
import DateTimeInput from "../../components/ui/DateTimeInput";
import Headline from "../../components/ui/Headline";
import Text from "../../components/ui/Text";
import useUrlParams from "../../hooks/useUrlParams";
import "./NotificationCreate.css";

const CAMPAIGN_QUERY = gql`
  query($campaignId: ID!) {
    campaign(id: $campaignId) {
      id
      draft
    }
  }
`;

const TEAMS_QUERY = gql`
  query($organizationId: ID!) {
    teams(organizationId: $organizationId) {
      id
      name
      timezone
    }
  }
`;

const UPSERT_NOTIFICATION_MUTT = gql`
  mutation(
    $organizationId: ID!
    $campaignId: ID
    $title: String!
    $content: String!
    $sendAt: Date
    $type: notification_types
    $target: notification_target_types
    $targetIds: [ID]
    $draft: Boolean
  ) {
    upsertNotification(
      organizationId: $organizationId
      campaignId: $campaignId
      title: $title
      content: $content
      sendAt: $sendAt
      type: $type
      target: $target
      targetIds: $targetIds
      draft: $draft
    ) {
      title
      content
      sendAt
      type
      target
      targetIds
      draft
    }
  }
`;

const validateTeamTimezone = (formValues, alert, teams) => {
  let sendDate = new Date(formValues.sendAt).toLocaleString("en-US", {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
  });
  let sendDt = new Date(sendDate);
  if (
    (formValues.target === "TEAM" || formValues.target === "ORGANIZATION") &&
    teams
  ) {
    for (let j in teams) {
      var team = teams[j];
      if (team) {
        var teamDate = new Date().toLocaleString("en-US", {
          timeZone: team.timezone
        });

        var teamDt = new Date(teamDate);
        if (teamDt > sendDt) {
          alert.removeAll();
          alert.show(
            `Notification time ${sendDt.toLocaleString()}  was already in the past for team ${
              team.name
            } with timezone '${team.timezone}' `,
            {
              timeout: 0,
              type: "error"
            }
          );
          return;
        }
      }
    }
  }
};

export default function NotificationCreateScreen({
  location,
  organization,
  modal
}) {
  const [urlParams] = useUrlParams(modal.location);
  const [mutate, { error: serverError }] = useMutation(
    UPSERT_NOTIFICATION_MUTT,
    {
      refetchQueries: [
        "NotificationsList",
        "CampaignQuery",
        "CampaignDraft",
        "PollDraft"
      ]
    }
  );

  const { data: { teams } = {} } = useQuery(TEAMS_QUERY, {
    variables: { organizationId: organization?.id },
    skip: !organization,
    onCompleted: data => {
      validateTeamTimezone(formValues, alert, data.teams);
    }
  });

  const { error, data: { campaign } = {} } = useQuery(CAMPAIGN_QUERY, {
    variables: { campaignId: urlParams.campaign },
    skip: !urlParams.campaign
  });

  const currentDate = new Date(Date.now()).toISOString().split("T")[0];

  const alert = useAlert();
  const [formValues, setFormValues] = useState({
    title: "",
    content: "",
    sendAt: currentDate,
    target: "ORGANIZATION",
    targetIds: []
  });

  const handleFormValueChange = fieldName => ({ value, target }) => {
    let newValues = {
      ...formValues,
      [fieldName]: value === undefined ? target?.value : value
    };
    setFormValues(newValues);
    validateTeamTimezone(newValues, alert, teams);
  };

  const handleSubmit = async event => {
    if (event) event.preventDefault();
    const { title, content, sendAt, target, targetIds } = formValues;
    await mutate({
      variables: {
        organizationId: organization?.id,
        campaignId: urlParams.campaign,
        title,
        content,
        sendAt,
        target,
        targetIds,
        draft: campaign?.draft
      }
    });
    alert.show("Notification created", { type: "success" });
    modal.close();
  };

  const handleTargetUpdate = target => {
    let newValues = {
      ...formValues,
      ...target
    };
    setFormValues(newValues);
    validateTeamTimezone(newValues, alert, teams);
  };

  if (error) {
    return (
      <div className="current-campaigns current-campaigns--error">
        <Text small center color="error">
          {error.toString()}
        </Text>
      </div>
    );
  }

  return (
    <form className="notification-create-screen" onSubmit={handleSubmit}>
      <div className="notification-create-screen__body">
        <Headline className="mb16">
          Create new notification{" "}
          {urlParams.campaign && <span>in campaign</span>}
        </Headline>
        <div className="notification-create-screen__section">
          <Text className="notification-create-screen__label">Title:</Text>
          <input
            type="text"
            className="ingrid-input ingrid-input--small"
            onChange={handleFormValueChange("title")}
            value={formValues.title}
          />
        </div>
        <div className="notification-create-screen__section">
          <Text className="notification-create-screen__label">
            Time to send notification:
          </Text>
          <DateTimeInput
            value={formValues.sendAt}
            onChange={handleFormValueChange("sendAt")}
            dropup
          />
        </div>
        <div className="notification-create-screen__section">
          <Text className="notification-create-screen__label">
            Notification content:
          </Text>
          <textarea
            className="ingrid-textarea"
            rows="5"
            onChange={handleFormValueChange("content")}
            value={formValues.content}
          />
        </div>
        {!urlParams.campaign && (
          <div className="notification-create-screen__section">
            <Text className="notification-create-screen__label">
              Send notification to:
            </Text>
            <TargetSelect
              enableUsers
              organization={organization}
              value={formValues}
              onChange={handleTargetUpdate}
            />
          </div>
        )}
        {serverError && (
          <Text center color="error">
            {serverError.toString()}
          </Text>
        )}
      </div>

      <Button
        fullwidth
        disabled={!formValues.title || !formValues.sendAt || !organization}
        type="submit"
      >
        Create notification
      </Button>
    </form>
  );
}
