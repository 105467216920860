import React from "react";
import "./SortIcon.css";

import IconSortDown from "../../assets/icons/color/icon-sort-down.svg";
import IconSortUp from "../../assets/icons/color/icon-sort-up.svg";
import IconSortNone from "../../assets/icons/color/icon-sort-none.svg";

export default function SortIcon({ state }) {
  return (
    <div className="sort-icon">
      {state === "down" && <IconSortUp width="16px" className="ml4" />}
      {state === "up" && <IconSortDown width="16px" className="ml4" />}
      {state === undefined && <IconSortNone width="16px" className="ml4" />}
    </div>
  );
}
