import React from "react";
import "./TemplatePlan.css";
// import cn from "classnames";
import IngridTemplate from "../../components/ingrid/Template";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import Card from "../../components/ui/Card";
// import User from "../../components/ingrid/User";
import IconEdit from "../../assets/icons/icon-edit.svg";
import IconDelete from "../../assets/icons/icon-cross.svg";
import Button from "../../components/ui/Button";
import DropdownButton from "../../components/ui/DropdownButton";
import { useState } from "react";
import { navigate } from "@reach/router";
import IconPlus from "../../assets/icons/icon-plus.svg";
import { createLinkToModal } from "../../components/Modal";
import LoadingSquares from "../../components/ui/LoadingSquares";

const TEMPLATES_QUERY = gql`
  query PlanTemplates($planId: ID!) {
    planTemplates(
      planId: $planId # order: "position"
    ) {
      items {
        id
        name
        description
        planId
        planTemplateCategories {
          id
          name
          description
          type
          main
          enabled
          planTemplateId
          ideaIds
        }
        planTemplateNotifications {
          id
          title
          content
          type
          planTemplateId
        }
      }
    }
  }
`;

const UPSERT_PLAN_TEMPLATE_MUTT = gql`
  mutation($planId: ID!) {
    upsertPlanTemplate(planId: $planId) {
      id
    }
  }
`;

export default function TemplatePlan({
  plan,
  organization,
  onNameChange,
  onRemove,
  location
}) {
  const {
    loading: templatesLoading,
    data: { planTemplates: { items: templates = [] } = {} } = {}
  } = useQuery(TEMPLATES_QUERY, {
    variables: { planId: plan?.id },
    skip: !plan
  });

  const [editedPlanName, setEditedPlanName] = useState(plan.name);
  const [editPlanNameId, setEditPlanNameId] = useState(null);

  const [createTemplate] = useMutation(UPSERT_PLAN_TEMPLATE_MUTT);

  return (
    <Card key={plan.id} className="template-plan">
      <div className="template-plan__head">
        {editPlanNameId === plan.id ? (
          <form
            className="template-plan__name"
            onSubmit={event => {
              if (event) event.preventDefault();
              if (editedPlanName) {
                onNameChange({ id: plan.id, name: editedPlanName });
              }

              setEditPlanNameId(null);
            }}
          >
            <input
              value={editedPlanName}
              autoFocus
              placeholder={plan.name}
              className="ingrid-input ingrid-input--small"
              type="text"
              onChange={({ target: { value } }) => {
                setEditedPlanName(value);
              }}
            />
            <Button
              type="submit"
              title="save"
              small
              disabled={!editedPlanName || editedPlanName === plan.name}
            />
            <Button
              type="reset"
              title="cancel"
              small
              secondary
              onClick={() => {
                setEditPlanNameId(null);
              }}
            />
          </form>
        ) : (
          <h3 className="template-plan__name">{plan.name}</h3>
        )}

        <div className="template-plan__toolbox">
          <DropdownButton
            left
            small
            actions={[
              {
                label: "Edit name",
                Icon: IconEdit,
                handler: () => {
                  setEditPlanNameId(plan.id);
                }
              },
              {
                label: "Delete plan",
                Icon: IconDelete,
                color: "danger",
                handler: onRemove
              }
            ]}
            title="actions"
          />
          <Button
            small
            onClick={() => {
              navigate(
                createLinkToModal(location)(`/templates/assign`, {
                  plan: plan.id
                })
              );
            }}
            type="submit"
            disabled={!templates.length}
            // data-title="Pick organization to assign plan"
          >
            Assign plan
          </Button>
        </div>
      </div>
      <div className="template-plan__teplates-grid">
        {templates.map(template => (
          <IngridTemplate key={template.id} template={template} />
        ))}
        {templatesLoading && <LoadingSquares count={3} height="184px" />}

        <Button
          small
          secondary
          className="ingrid-add-card"
          onClick={async () => {
            const {
              data: {
                upsertPlanTemplate: { id }
              }
            } = await createTemplate({
              variables: {
                planId: plan.id
              }
            });
            if (id) navigate(`/templates/${plan.id}/${id}`);
          }}
        >
          <IconPlus width="24px" fill="white" />
        </Button>
      </div>
    </Card>
  );
}
