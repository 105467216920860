import React from "react";
import ChartIdeasCampaigns from "../../components/ingrid/ChartIdeasCampaigns";
import ChartUsersEngagement from "../../components/ingrid/ChartUsersEngagement";
import "./CampaignDashboard.css";

export default function CampaignDashboard({ data }) {
  const sortedCampaigns = {items: [...data?.campaigns?.items || []].reverse()};
  return (
    <div className="campaign-detail__dashboard">
      <ChartUsersEngagement userEngagement={data?.userEngagement} />
      <ChartIdeasCampaigns campaigns={sortedCampaigns} />
    </div>
  );
}
