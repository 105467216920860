/** @flow */
import React from "react";
import classNames from "classnames";
import "react-virtualized/styles.css";
import { AutoSizer, InfiniteLoader, List } from "react-virtualized";

import "./VirtualizedList.css";

export default function VirtualizedList({
  className,
  list,
  loadMore,
  loaded,
  totalItemsCount = 0,
  rowHeight = 50,
  children,
  placeholder = "No results"
}) {
  if (typeof children !== "function") {
    console.warn("VirtualizedList needs function as a children!");
  }

  if (loaded && !totalItemsCount) {
    return (
      <div className="virtualized-list">
        <p className="virtualized-list__placeholder">{placeholder}</p>
      </div>
    );
  }

  return (
    <InfiniteLoader
      isRowLoaded={({ index }) => !!list[index]}
      loadMoreRows={loadMore}
      rowCount={totalItemsCount}
    >
      {({ onRowsRendered, registerChild }) => (
        <AutoSizer>
          {({ width, height }) => (
            <List
              ref={registerChild}
              className={classNames("virtualized-list", className && className)}
              height={height}
              onRowsRendered={onRowsRendered}
              rowCount={totalItemsCount}
              rowHeight={rowHeight}
              rowRenderer={({ index, key, style }) => (
                <div className="virtualized-list__row" key={key} style={style}>
                  {children(list[index])}
                </div>
              )}
              width={width}
            />
          )}
        </AutoSizer>
      )}
    </InfiniteLoader>
  );
}
