import React from "react";
import cn from "classnames";
import Button from "../../components/ui/Button";
import Text from "../../components/ui/Text";
import DropdownButton from "../../components/ui/DropdownButton";
import IconUnranked from "../../assets/icons/icon-unranked.svg";
import IconInvisible from "../../assets/icons/icon-invisible.svg";
import IconRanked from "../../assets/icons/icon-ranked.svg";
import IconVisible from "../../assets/icons/icon-visible.svg";
import IconInno from "../../assets/icons/icon-inno.svg";
import IconPdf from "../../assets/icons/icon-file-pdf.svg";
import IconXlsx from "../../assets/icons/icon-file-excel.svg";
import { useAlert } from "react-alert";
import { navigate } from "@reach/router";
import useSendIdeasToInno from "../../hooks/useSendIdeasToInno";
import useRemoveIdeasFromInno from "../../hooks/useRemoveIdeasFromInno";
import useUpdateCampaignIdeas from "../../hooks/useUpdateCampaignIdeas";
import useSendIdeasToNewPoll from "../../hooks/useSendIdeasToNewPoll";
import { absoluteLink } from "../../library/url";

export default function Footer({
  urlParams,
  pickedIdeaIds,
  modal,
  auth,
  organization
}) {
  const campaignId = urlParams.campaign;
  const isPick = urlParams.context === "pick";
  const isBulk = urlParams.context === "bulk";

  const alert = useAlert();

  const updateCampaignIdeas = useUpdateCampaignIdeas({ campaignId }, result => {
    // no-op
  });

  const makeBulkHandler = pass => () => {
    updateCampaignIdeas(
      pickedIdeaIds.map(id => ({
        id,
        ...pass
      }))
    );
  };

  const sendIdeasToNewPoll = useSendIdeasToNewPoll({ organization }, poll => {
    navigate(`/polls/create/${poll.id}`);
  });

  const sendIdeasToInno = useSendIdeasToInno(ideasInno => {
    alert.show(`Successfully added to innovations`, {
      type: "success"
    });
  });

  const removeIdeasFromInno = useRemoveIdeasFromInno(({ success }) => {
    if (success)
      alert.show(`Successfully removed from innovations`, { type: "success" });
  });

  const exportXlsx = ideaIds => () => {
    const reducer = (accumulator, currentValue) =>
      `${accumulator}&ideaIds[]=${currentValue}`;
    const urlIdeas = ideaIds.reduce(reducer, "");

    const exportLink = absoluteLink(
      `/api/organization/${organization?.id}/idea/export/excel?x-access-token=${auth?.token}${urlIdeas}`
    );
    navigate(exportLink);
  };

  const exportPdf = ideaIds => () => {
    const reducer = (accumulator, currentValue) =>
      `${accumulator}&ideaIds[]=${currentValue}`;
    const urlIdeas = ideaIds.reduce(reducer, "");

    const exportLink = absoluteLink(
      `/api/organization/${organization?.id}/idea/export/pdf?x-access-token=${auth?.token}${urlIdeas}`
    );
    navigate(exportLink);
  };
  return (
    <div
      className={cn(
        "ideas-screen__footer",
        Boolean(modal.path) && "ideas-screen__footer--modal"
      )}
    >
      <div>
        <Text nowrap>
          Picked <b>{pickedIdeaIds.length}</b> ideas.
        </Text>
      </div>
      <div className="ideas-screen__footer__controls">
        {isPick && (
          <Button
            children="Select ideas"
            disabled={!pickedIdeaIds.length}
            onClick={() => {
              const key = urlParams.suffix
                ? `pickedIdeas${urlParams.suffix}`
                : "pickedIdeas";
              modal.closeWithParams({ [key]: pickedIdeaIds });
            }}
          />
        )}
        {isBulk && (
          <DropdownButton
            top
            left
            // small
            title="Actions"
            disabled={!pickedIdeaIds.length}
          >
            {[
              {
                Icon: IconXlsx,
                label: "Export to excel",
                handler: exportXlsx(pickedIdeaIds)
              },
              {
                Icon: IconPdf,
                label: "Export to pdf",
                handler: exportPdf(pickedIdeaIds)
              },
              {
                Icon: IconInno,
                label: "Add ideas to innovations",
                handler: sendIdeasToInno(pickedIdeaIds)
              },
              {
                Icon: IconInno,
                label: "Remove from innovations",
                handler: removeIdeasFromInno(pickedIdeaIds),
                color: "danger"
              },
              campaignId && {
                Icon: IconUnranked,
                label: "Set to unranked",
                handler: makeBulkHandler({ isExcludedFromStatistics: true })
              },
              campaignId && {
                Icon: IconRanked,
                label: "Set back to ranked",
                handler: makeBulkHandler({ isExcludedFromStatistics: false })
              },
              campaignId && {
                Icon: IconInvisible,
                label: "Set invisible to users",
                handler: makeBulkHandler({ isHidden: true })
              },
              campaignId && {
                Icon: IconVisible,
                label: "Set visible to users",
                handler: makeBulkHandler({ isHidden: false })
              }
            ]}
          </DropdownButton>
        )}
        {isBulk && (
          <Button
            className="ideas-screen__footer__make-poll-button"
            disabled={pickedIdeaIds.length < 2}
            onClick={sendIdeasToNewPoll(pickedIdeaIds)}
          >
            Make Poll
          </Button>
        )}
      </div>
    </div>
  );
}
