// import React, { useState } from "react";

import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

const UPSERT_POLL_MUTT = gql`
  mutation($organizationId: ID!) {
    upsertCampaign(organizationId: $organizationId, type: PASSIVE) {
      id
      state
      categories {
        id
        name
        main
      }
    }
  }
`;

const UPSERT_POLL_CATEGORY_MUTT = gql`
  mutation(
    $id: ID
    $campaignId: ID!
    $ideaIds: [ID] # $type: category_type
  ) {
    upsertCategory(
      id: $id
      main: true
      campaignId: $campaignId
      ideaIds: $ideaIds
      type: INNO
      name: "Poll"
    ) {
      id
      ideaIds
      campaignId
      main
    }
  }
`;

export default function useSendIdeasToNewPoll({ organization }, callback) {
  const [mutatePoll] = useMutation(UPSERT_POLL_MUTT);
  const [mutatePollCategory] = useMutation(UPSERT_POLL_CATEGORY_MUTT);

  return function sendIdeasToNewPoll(ideas) {
    const ideaIds = ideas.map(idea => idea?.id || idea); // we can get list of ids or list of objects here

    return async () => {
      if (!ideaIds.length) return;

      const {
        data: { upsertCampaign: poll }
      } = await mutatePoll({
        variables: { organizationId: organization?.id },
        refetchQueries: ["PollsDrafts"]
      });

      const primaryCategory = poll?.categories.find(({ main }) => main);
      if (!poll.id || !primaryCategory) return;

      await mutatePollCategory({
        variables: {
          id: primaryCategory.id,
          campaignId: poll.id,
          ideaIds
        },
        optimisticResponse: {
          __typename: "Mutation",
          upsertCategory: {
            id: primaryCategory.id,
            campaignId: poll.id,
            ideaIds,
            main: true,
            __typename: "category"
          }
        }
      });

      callback(poll);
    };
  };
}
