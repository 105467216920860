import React from "react";
import "./EvaluateCampaigns.css";

import { navigate } from "@reach/router";

import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

import { useAlert } from "react-alert";

import Card from "../../components/ui/Card";
import Headline from "../../components/ui/Headline";
import Text from "../../components/ui/Text";
import Button from "../../components/ui/Button";
import * as format from "../../library/format";

const FINISH_CAMPAIGN_MUTT = gql`
  mutation($id: ID!) {
    finishCampaign(id: $id)
  }
`;

export default function EvaluateCampaigns({ campaigns }) {
  const alert = useAlert();

  const campaignsForApproval = campaigns?.items?.filter(
    f => f.state === "PENDING_APPROVAL"
  );

  const [finishCampaign] = useMutation(FINISH_CAMPAIGN_MUTT, {
    refetchQueries: ["RunningCampaigns", "CampaignsGrid"]
  });

  const handleFinishCampaign = id => {
    finishCampaign({ variables: { id } });
    alert.show("Campaign finished", { type: "success" });
  };

  return (
    <div className="campaigns-evaluate">
      {campaignsForApproval?.length > 0 && (
        <div>
          <Headline>Campaigns for manual finish</Headline>
          <Text block className="mt8">
            These campaigns need your attention. Before your users will see the
            results in the app, please check the winners and make necessary
            changes if you want. After confirming the results, notification
            about campaign finish will be sent and app users can check the
            results.
          </Text>
          {campaignsForApproval?.map(campaign => (
            <Card className="campaigns-evaluate__card">
              <div className="campaigns-evaluate__info">
                <Headline>{campaign.name}</Headline>
                <Text color="white" block className="ml32">
                  {format.onlyDate(campaign.from)} -{" "}
                  {format.onlyDate(campaign.to)}
                </Text>
              </div>
              <div className="campaigns-evaluate__options">
                <Button
                  invertedSecondary
                  className="mr16"
                  onClick={() => {
                    navigate(`/campaigns/${campaign.id}`);
                  }}
                >
                  CAMPAIGN DETAIL
                </Button>
                <Button
                  inverted
                  onClick={() => handleFinishCampaign(campaign.id)}
                >
                  Publish campaign results
                </Button>
              </div>
            </Card>
          ))}
        </div>
      )}
    </div>
  );
}
