import { useQuery } from "@apollo/react-hooks";
import cs from "classnames";
import gql from "graphql-tag";
import React from "react";
import IconPlus from "../../assets/icons/icon-plus.svg";
import Notification from "../../components/ingrid/Notification";
import Button from "../../components/ui/Button";
import Headline from "../../components/ui/Headline";
import { createLinkToModal } from "../../components/Modal";
import { navigate } from "@reach/router";
import "./Notifications.css";
import Loading from "../../components/ui/Loading";

const NOTIFICATION_QUERY = gql`
  query NotificationsList($organizationId: ID!, $where: SequelizeJSON) {
    notifications(
      organizationId: $organizationId
      where: $where
      order: "reverse:sendAt"
    ) {
      id
      title
      content
      sendAt
      target
      targetIds
      type
      draft
      campaign {
        id
        name
        type
      }
    }
  }
`;

const TEAMS_QUERY = gql`
  query campaignTeams($orgId: ID!) {
    teams(organizationId: $orgId) {
      id
      name
    }
  }
`;

export default function Notifications({ location, organization }) {
  const { loading, error, data } = useQuery(NOTIFICATION_QUERY, {
    variables: {
      organizationId: organization?.id,
      where: { draft: false }
    },
    skip: !organization,
    fetchPolicy: "cache-and-network"
  });
  const currentTime = Date.now();

  const handleCreateNotification = () => {
    navigate(createLinkToModal(location)(`/notifications/create`));
  };

  const { data: { teams = [] } = {} } = useQuery(TEAMS_QUERY, {
    variables: {
      orgId: organization?.id
    },
    skip: !organization,
    fetchPolicy: "cache-and-network"
  });

  return (
    <div className="notifications">
      <div className="notifications__headline">
        <Headline>Notification timeline</Headline>
        <Button
          icon
          small
          onClick={() => {
            handleCreateNotification();
          }}
        >
          <IconPlus width="12px" className="mr8" fill="var(--color-white)" />
          CREATE NOTIFICATION
        </Button>
      </div>
      {loading && <Loading>Loading notifications ...</Loading>}
      <div className="notifications__timeline__container">
        {data?.notifications?.map(notification => (
          <div
            key={notification.id}
            className={cs(
              "notifications__timeline",
              new Date(notification.sendAt).getTime() > currentTime &&
                "notifications__timeline--upcoming"
            )}
          >
            <Notification
              notification={notification}
              teamList={teams}
              campaign={notification.campaign}
              editable={new Date(notification.sendAt).getTime() > currentTime}
              deletable={new Date(notification.sendAt).getTime() > currentTime}
            />
          </div>
        ))}
      </div>

      {error && error.toString()}
    </div>
  );
}
