import React from "react";
import "./LoadingSquares.css";
import cn from "classnames";

export default function LoadingSquares({ count = 1, invert = false, height }) {
  return [...Array(count)].map((_, i) => (
    <div
      key={i}
      style={{ height: height }}
      className={cn("loading-square", invert && "loading-square--invert")}
    />
  ));
}
