import React from "react";

// import gql from "graphql-tag";
// import { useQuery } from "@apollo/react-hooks";

export default function NotFoundScreen(props) {
  return (
    <div
      style={{
        padding: "32px 64px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <p style={{ fontSize: "64px" }}>¯\_(ツ)_/¯</p>
    </div>
  );
}
