import React from "react";
import Text from "../../components/ui/Text";

const getTotalValueOfInnovationProcess = innovationProcess => {
  let acc = 0;
  if (!innovationProcess) return acc;

  const uploaded = innovationProcess.find(i => i.state === "UPLOADED");
  return uploaded ? uploaded.value : acc;
};

export default function InnovProcessValue({ innovationProcess, organization }) {
  const innovationProcessValue = getTotalValueOfInnovationProcess(
    innovationProcess
  );
  return (
    <Text center large bold block>
      {innovationProcessValue} {organization?.currency}
    </Text>
  );
}
