import React from "react";
import classNames from "classnames";
import "./CampaignState.css";

export default function CampaignState({ state, children: text }) {
  return (
    <div className={classNames("campaign-state", `campaign-state--${state}`)}>
      {state === "pending-approval" ? "Approval needed" : state}
    </div>
  );
}
