import React, { useState } from "react";
import "./CommentForm.css";
import Button from "../../components/ui/Button";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
// import { IDEAS_QUERY } from "../Ideas";

const ADD_COMMENT_MUT = gql`
  mutation($ideaId: ID!, $campaignId: ID, $comment: String!) {
    createCommentOnIdea(
      id: $ideaId
      campaign_id: $campaignId
      comment: $comment
    ) {
      id
    }
  }
`;

export default function CommentForm({
  ideaId,
  campaignId,
  orgId: organizationId,
  afterSubmit,
  small
}) {
  const [comment, setComment] = useState("");

  const [mutate] = useMutation(ADD_COMMENT_MUT, {
    // refetchQueries: ["IdeasGrid"] // FIXME update grid but do no scrobo
  });

  return (
    <div>
      <form
        className="comment-form"
        onSubmit={async event => {
          event.preventDefault();
          await mutate({
            variables: {
              ideaId,
              campaignId,
              comment
            }
          });
          setComment("");
          if (afterSubmit) afterSubmit();
        }}
      >
        <input
          className="ingrid-input ingrid-input--small"
          placeholder="Write a comment..."
          rows="1"
          value={comment}
          onChange={({ target: { value, scrollHeight } }) => {
            setComment(value);
          }}
          type="text"
        />
        <Button small className="comment-form__button" type="submit">
          Send
        </Button>
      </form>
    </div>
  );
}
