import React, { useRef, useEffect, useState } from "react";
import cn from "classnames";
// import { navigate } from "@reach/router";
import Text from "../../components/ui/Text";
// TODO: use Placeholders
// import Idea, { IdeaPlaceholder } from "../../components/ingrid/Idea";
import Idea from "../../components/ingrid/Idea";
import Checkbox from "../../components/ui/Checkbox";
import "./Ideas.css";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { createLinkToModal } from "../../components/Modal";
import FilterRow from "../../components/ingrid/FilterRow";
import useUrlParams from "../../hooks/useUrlParams";
import useScreenEndReached from "../../hooks/useScreenEndReached";
import Footer from "./Footer";
import LoadingSquares from "../../components/ui/LoadingSquares";
import Select from "../../components/ui/Select";
import Button from "../../components/ui/Button";
import IconPlus from "../../assets/icons/icon-plus.svg";

export const IDEAS_PER_PAGE = 50;

export const IDEAS_QUERY = gql`
  query IdeasGrid($organizationId: ID, $offset: Int, $campaignId: ID, $teamId: ID, $search: String, $order: String, $categoryIds: [String], $reported: Boolean, $replicated: Boolean, $hasTag: Boolean, $innovationState: [innovation_state_input] ) {
    ideas(
      organizationId: $organizationId
      offset: $offset
      limit: ${IDEAS_PER_PAGE}
      order: $order
      campaignId: $campaignId
      search: $search
      teamId: $teamId
      categoryIds: $categoryIds
      reported: $reported
      replicated: $replicated
      hasTag: $hasTag
      innovationState: $innovationState
    ) {
      items {
        innovationProcess {
          id
          state
        }
        id
        image
        value
        description
        replicated
        createdBy {
          id
          email
        }
        tags {
          id
          tag
        }
        created_at
        campaignIdeas {
          id
          isHidden
          isExcludedFromStatistics
          isPromo
          order
          reported
          campaign {
            id
            name
            type
          }
          category {
            id
            type
          }
        }
        commentCount
      }
      meta {
        totalItems
      }
    }
  }
`;

export default function IdeasScreen({
  organization,
  location,
  navigate,
  modal,
  user,
  auth
}) {
  const screenAsModal = modal.isModal(location);
  const [urlParams, { updateUrlParam }] = useUrlParams(
    screenAsModal ? modal.location : location
  );

  const [pickedIdeaIds, setPickedIdeaIds] = useState([]);
  const isBulkEnabled = urlParams.context === "bulk";
  const isPickEnabled = urlParams.context === "pick";

  const [filterInnovations, setFilterInnovations] = useState(
    urlParams.filterInnovations
  );
  const [filterReported, setFilterReported] = useState(
    urlParams.filterReported
  );

  const [filterReplicated, setFilterReplicated] = useState(
    urlParams.filterReplicated
  );

  const [filterTagged, setFilterTagged] = useState(urlParams.filterTagged);

  const { error, data, fetchMore, refetch, networkStatus } = useQuery(
    IDEAS_QUERY,
    {
      variables: {
        organizationId: organization?.id,
        campaignId: urlParams.campaign,
        teamId: urlParams.team,
        search: urlParams.search,
        order: urlParams.order || "rank",
        reported: (urlParams.filterReported && true) || undefined,
        replicated: (urlParams.filterReplicated && true) || undefined,
        innovationState:
          (urlParams.filterInnovations && [
            "SELECTED",
            "IMPLEMENTING",
            "APPROVED",
            "DONE"
          ]) ||
          undefined,
        hasTag: (urlParams.filterTagged && true) || undefined,
        categoryIds: urlParams.category ? [urlParams.category] : null,
        offset: 0
      },
      skip: !organization && !user.superadmin,
      // fetchPolicy: "cache-and-network", // keep data fresh
      notifyOnNetworkStatusChange: true // to get updated networkStatus
    }
  );

  /*
   * Easy but overkill way how to refetch on create idea
   */
  useEffect(() => {
    if (organization && urlParams.createdIdea) {
      refetch();
    }
  }, [organization, refetch, urlParams.createdIdea]);

  // one of status codes for initial (1) or fetch more (3)
  const loading = [1, 3].includes(networkStatus);

  const loadMore = ({ offset }) =>
    fetchMore({
      query: IDEAS_QUERY,
      variables: {
        organizationId: organization?.id,
        offset,
        campaignId: urlParams.campaign,
        teamId: urlParams.team,
        search: urlParams.search,
        order: urlParams.order || "rank",
        reported: (urlParams.filterReported && true) || undefined,
        categoryIds: urlParams.category ? [urlParams.category] : null,
        innovationState:
          (urlParams.filterInnovations && [
            "SELECTED",
            "IMPLEMENTING",
            "APPROVED",
            "DONE"
          ]) ||
          undefined,
        hasTag: (urlParams.filterTagged && true) || undefined
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        return {
          ideas: {
            items: previousResult?.ideas.items.concat(
              fetchMoreResult.ideas.items
            ),
            meta: fetchMoreResult.ideas.meta,
            __typename: "idea_list"
          }
        };
      }
    });

  const scrollContainer = useRef(null);

  useScreenEndReached(
    () => {
      if (!loading && data?.ideas.meta.totalItems > data?.ideas.items.length)
        loadMore({ offset: data.ideas.items.length });
    },
    { container: scrollContainer.current, offset: 150 }
  );

  const handleIdeaClick = idea => () => {
    const ideaId = idea.id.toString(); // meh

    if (isBulkEnabled || screenAsModal) {
      setPickedIdeaIds(
        pickedIdeaIds.includes(ideaId)
          ? pickedIdeaIds.filter(id => id !== ideaId) // remove
          : pickedIdeaIds.concat([ideaId]) // add
      );
    } else {
      const path = `/ideas/${ideaId}`;
      navigate(createLinkToModal(location)(path));
    }
  };

  // Here is the story. We'd like to show rank on Idea card if user (or modal context) filtered certain campaign.
  // But, we cannot access `idea.order` by basic `ideas` query AND it is hard to combine data (and their filters)
  // with results of two queries (in combination with `campaign.campaignIdeas.order` in this case).
  // -- cool story bro - removed parent div for this from idea for now, it screwed tooltips
  // const showRank = false;
  const ideasFilteredCount = {
    data: data?.ideas.meta.totalItems,
    label: " ideas"
  };

  const getIdeaOrder = (idea, campaignId) => {
    const firstFilteredIdea = idea.campaignIdeas
      .filter(ci => ci.campaign.id === parseInt(campaignId, 10))
      .shift();

    return (
      firstFilteredIdea && (
        <div
          className={cn(
            "ideas-screen__idea-order",
            firstFilteredIdea.order <= 3 &&
              `ideas-screen__idea-order--${firstFilteredIdea.order}`
          )}
        >
          {firstFilteredIdea.order || "--"}.
        </div>
      )
    );
  };

  return (
    <div className={cn("ideas-screen", screenAsModal && "ideas-screen--modal")}>
      <FilterRow
        showSort
        campaign={Boolean(organization)}
        teams={organization?.teamsEnabled || false}
        values={urlParams}
        onChange={updateUrlParam}
        className="ideas-screen__filter-row"
        organizationId={organization?.id}
      >
        <div className="ideas-screen__advanced-filter">
          <div className="ml16">
            <Checkbox
              name="innovations"
              value={urlParams.filterInnovations ? true : false}
              onChange={() => {
                updateUrlParam({
                  filterInnovations: !filterInnovations
                });
                setFilterInnovations(!filterInnovations);
              }}
            >
              Innovations
            </Checkbox>
          </div>
          <div className="ml16">
            <Checkbox
              name="tagged"
              value={urlParams.filterTagged ? true : false}
              onChange={() => {
                updateUrlParam({
                  filterTagged: !filterTagged
                });
                setFilterTagged(!filterTagged);
              }}
            >
              Tagged
            </Checkbox>
          </div>
          {/* TODO replikace v appce - <div className="ideas-screen__sort__border">
            <Checkbox name="replications">Replications</Checkbox>
          </div> */}
          <div className="ml16">
            {" "}
            <Checkbox
              name="replicated"
              value={urlParams.filterReplicated ? true : false}
              onChange={() => {
                updateUrlParam({
                  filterReplicated: !filterReplicated
                });
                setFilterReplicated(!filterReplicated);
              }}
            >
              Replicated
            </Checkbox>
          </div>
          <div className="ml16">
            {" "}
            <Checkbox
              name="reported"
              value={urlParams.filterReported ? true : false}
              onChange={() => {
                updateUrlParam({
                  filterReported: !filterReported
                });
                setFilterReported(!filterReported);
              }}
            >
              Reported
            </Checkbox>
          </div>
        </div>
      </FilterRow>
      <div className="ideas-screen__content" ref={scrollContainer}>
        <div className="filter-row__sort">
          <div className="mr8">
            <Text nowrap>
              Showing <b>{ideasFilteredCount.data}</b>
              {ideasFilteredCount.label}.
            </Text>
          </div>
          {(modal.location.pathname.includes("/campaigns/create") ||
            modal.location.pathname.includes("/polls/create")) && (
            <Button
              icon
              small
              disabled={isBulkEnabled}
              onClick={() => {
                const modalLink = createLinkToModal(
                  screenAsModal ? modal.location : location
                )("/ideas/create");
                navigate(modalLink);
              }}
            >
              <IconPlus
                width="14px"
                fill="var(--color-white)"
                className="mr8"
              />
              Create idea
            </Button>
          )}
          <div className="ideas-screen__sort">
            {!screenAsModal && (
              <div className="mr16">
                <Checkbox
                  name="bulk"
                  value={isBulkEnabled}
                  onChange={({ value }) => {
                    updateUrlParam({
                      // Use previous context if it was not bulk
                      context: value ? "bulk" : null
                    });
                    urlParams.context === "bulk" && setPickedIdeaIds([]);
                  }}
                >
                  Bulk actions
                </Checkbox>
              </div>
            )}
            <Text className="mr8">Sort by: </Text>
            <Select
              value={urlParams.order || "rank"}
              placeholder="Sort by .."
              onChange={option => {
                if (option) updateUrlParam({ order: option.value });
              }}
              options={[
                {
                  label: "Rank",
                  value: "rank"
                },
                {
                  label: "Oldest",
                  value: "created_at"
                },
                {
                  label: "Newest",
                  value: "reverse:created_at"
                },
                {
                  label: "Most valuable",
                  value: "reverse:value"
                }
              ]}
            />
          </div>
        </div>
        <div className="ideas-screen__grid">
          {data?.ideas.items.map(idea => (
            <div className="ideas-screen__idea" key={idea.id}>
              <Idea
                highlight={
                  (isBulkEnabled || isPickEnabled) &&
                  pickedIdeaIds.includes(idea.id.toString())
                }
                idea={idea}
                currency={organization?.currency}
                showInno
                showComments
                showTags
                showDetail
                showValue
                onClick={handleIdeaClick(idea)}
              />

              {getIdeaOrder(idea, urlParams.campaign)}
            </div>
          ))}
          {loading && <LoadingSquares count={11} height="248px" />}
        </div>
        <div className="ideas-screen__info-toast">
          {error && (
            <Text small center color="error">
              {error.toString()}
            </Text>
          )}
          {!loading && !data?.ideas.items.length && (
            <Text small center color="gray-dark">
              We found no ideas with your filter settings.
            </Text>
          )}
        </div>
      </div>

      {(isBulkEnabled || isPickEnabled) && (
        <Footer {...{ modal, urlParams, pickedIdeaIds, auth, organization }} />
      )}
    </div>
  );
}
