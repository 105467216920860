import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import Headline from "../ui/Headline";

const getPercentage = item => {
  if (item.invitedUsersCount && item.votedUsersCount)
    return Math.round(
      (100 * parseInt(item.votedUsersCount)) / parseInt(item.invitedUsersCount)
    );
  return 0;
};

const getIdeaCampaigns = ideas => {
  if (!ideas) return;
  let ret = [];

  for (let i in ideas) {
    if (!ideas[i].name) continue;
    let item = {
      name: ideas[i].name,
      ideaCount: ideas[i].ideaCount,
      percentage: getPercentage(ideas[i])
    };
    ret.push(item);
  }

  return ret;
};

const yTickFormatter = val => {
  return `${val} %`;
};

export default function ChartCampaignStats({ campaignStats }) {
  const ideasCampaignsData = getIdeaCampaigns(campaignStats);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload) {
      return (
        <div
          className="recharts-default-tooltip"
          style={{ backgroundColor: "white" }}
        >
          <div
            className="recharts-tooltip-label"
            style={{ padding: "10px", border: "1px solid #eee" }}
          >
            {payload[0].payload.name} ( {payload[0].payload.ideaCount} )
            <div style={{ color: "var(--color-chart-1)" }}>
              Percentage of votes: {payload[0].payload.percentage} %
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="organization-dashboard__graph-container">
      <Headline small className="mb4">
        Polls votes
      </Headline>
      <div className="organization-dashboard__graph">
        <ResponsiveContainer width="100%" height={220}>
          <BarChart
            width={500}
            height={300}
            data={ideasCampaignsData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="name"
              axisLine={true}
              tickLine={false}
              height={20}
            />
            <YAxis tickFormatter={yTickFormatter} />
            <Tooltip content={<CustomTooltip />} />
            <Bar dataKey="percentage" stackId="a" fill="var(--color-chart-1)" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
