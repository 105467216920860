import React from "react";
import TargetSelect from "../../../components/ingrid/TargetSelect";
import Text from "../../../components/ui/Text";
// import IconWarning from "../../../assets/icons/icon-warning.svg";
// import Select from "../../../components/ui/Select";
import "./Target.css";
import useCampaignAvailableTargets from "../../../hooks/useCampaignAvailableTargets";
import * as format from "../../../library/format";

Target.validate = ({ target, targetIds = [] } = {}) => {
  if (target === "ORGANIZATION") return true;
  if (targetIds.length > 0) return true;
  return false;
};

export default function Target({
  campaign = {},
  updateCampaign,
  organization
}) {
  const availableTargetIds = useCampaignAvailableTargets({
    campaign,
    organization
  });

  return (
    <div className="create-poll-wizard__target">
      <div className="wizard-campaign__section">
        <Text block className="mb8">
          You can set specific target group for your poll. Everyone selected can
          participate in this poll.
        </Text>

        {/* <Text small block>
          <IconWarning width="12px" fill="var(--color-alert)" /> Every user/team
          can have only one running poll. Change your poll start or end date to
          unlock more teams for selection.
        </Text> */}

        <TargetSelect
          enableUsers
          {...{ organization, availableTargetIds, campaign }}
          onChange={updateCampaign}
          value={campaign}
        >
          {{
            error: (
              <div className="create-poll-wizard__target__error">
                <Text color="error">
                  There are already campaigns running in your organization from{" "}
                  <b>{format.onlyDate(campaign?.from)}</b> to{" "}
                  <b>{format.onlyDate(campaign?.to)}</b>. Please select specific
                  teams to participate or change the date.
                </Text>
              </div>
            )
          }}
        </TargetSelect>
      </div>
    </div>
  );
}
