import React, { useState } from "react";
import "./ImageUpload.css";
import Text from "../components/ui/Text";
import IconImageUpload from "../../src/assets/icons/icon-upload.svg";

export default function ImageUpload({ onChange }) {
  const [preview, setPreview] = useState(null);
  var imageButtonText = "";
  preview
    ? (imageButtonText = "Change image")
    : (imageButtonText = "Upload image");

  const handlePreviewChange = ({
    target: {
      files: [file]
    }
  }) => {
    if (file) {
      let reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }

    if (onChange) onChange({ value: file });
  };
  return (
    <div className="image-upload__container">
      <input
        id="image-upload"
        className="image-upload__input"
        type="file"
        onChange={handlePreviewChange}
      />
      <label className="image-upload__label" htmlFor="image-upload">
        {preview ? (
          <div className="image-upload__button image-upload__preview">
            <div className="image-upload__hover-text">{imageButtonText}</div>
            <img
              className="image-upload__image"
              src={preview}
              alt="your upload preview"
            />
          </div>
        ) : (
          <div className="image-upload__button image-upload__preview--default">
            <IconImageUpload fill="#505661" width="32" />
            <Text>{imageButtonText}</Text>
          </div>
        )}
      </label>
    </div>
  );
}
