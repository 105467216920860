import React, { useState } from "react";
import "./CurrentPoll.css";
import { navigate } from "@reach/router";
import { createLinkToModal } from "../../components/Modal";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import Select from "../../components/ui/Select";
import Button from "../../components/ui/Button";
import Headline from "../../components/ui/Headline";
import Text from "../../components/ui/Text";

import Idea from "../../components/ingrid/Idea";
import cs from "classnames";
import Card from "../../components/ui/Card";

const notificationsWhere = () => {
  const currentDate = new Date().toISOString();
  return { sendAt: { gt: currentDate } };
};

const RUNNING_CAMPAIGNS_QUERY = gql`
  query RunningPolls($organizationId: ID!) {
    campaigns(
      organizationId: $organizationId
      state: RUNNING
      where: { type: "PASSIVE" }
    ) {
      items {
        id
        name
        campaignIdeas(limit: 9, order: "organization_order") {
          order
          idea {
            id
            image
            value
            innovationProcess {
              state
            }
            description
            createdBy {
              id
              email
            }
          }
        }
      }
    }
  }
`;

const READY_CAMPAIGNS_QUERY = gql`
  query($organizationId: ID!) {
    campaigns(
      organizationId: $organizationId
      state: READY
      where: { type: "PASSIVE" }
    ) {
      items {
        id
        name
      }
    }
  }
`;

export default function CurrentPolls({ location, organization }) {
  const [selectedCampaignId, selectCampaignId] = useState(null);

  const organizationId = organization?.id;

  const { error, loading, data: { campaigns } = {} } = useQuery(
    RUNNING_CAMPAIGNS_QUERY,
    {
      variables: { organizationId, notificationsWhere: notificationsWhere() },
      skip: !organizationId,
      onCompleted: ({
        campaigns: {
          items: [firstCampaign]
        }
      }) => {
        if (!selectedCampaignId && firstCampaign)
          selectCampaignId(firstCampaign.id);
      }
    }
  );

  const { data: { campaigns: nextCampaigns } = {} } = useQuery(
    READY_CAMPAIGNS_QUERY,
    {
      variables: { organizationId },
      skip: !organizationId
    }
  );

  if (error) {
    return (
      <div className="current-poll current-poll--error">
        <Text small center color="error">
          {error.toString()}
        </Text>
      </div>
    );
  }

  if (!loading && campaigns?.items.length === 0) {
    return (
      <Card className="current-poll--empty">
        <Text block className="mb16">
          There are no polls running in the moment{" "}
          {nextCampaigns?.items.length > 0 ? (
            <span>
              and <b>{nextCampaigns?.items.length}</b> scheduled.
            </span>
          ) : (
            <span>and nothing scheduled.</span>
          )}
        </Text>

        <Button
          onClick={() => {
            navigate("/polls/create");
          }}
        >
          Create new poll
        </Button>
      </Card>
    );
  }

  const selectedCampaign = (campaigns?.items || []).find(
    ({ id }) => id === selectedCampaignId
  );

  return (
    <div>
      <div className="current-poll__main-header">
        <div className="current-poll__main-headline">
          <Headline>Currently running polls</Headline>
        </div>
        <div className="current-poll__select">
          {" "}
          <Select
            options={campaigns?.items.map(({ id, name }) => ({
              value: id,
              label: name
            }))}
            value={selectedCampaignId}
            onChange={option => {
              if (option) selectCampaignId(option.value);
            }}
          />
        </div>
      </div>
      <div className="current-poll">
        <div className="current-poll__ideas">
          <div className="current-poll__header">
            <Headline className="current-poll__headline" small>
              Top ideas
            </Headline>
          </div>
          <div className="current-poll__ideas-grid__scroll">
            {selectedCampaign?.campaignIdeas?.length > 0 ? (
              <div className="current-poll__ideas-list">
                {selectedCampaign?.campaignIdeas?.map(idea => (
                  <div key={idea.idea.id} className="current-poll__idea">
                    {console.log(idea)}
                    <Idea
                      idea={idea.idea}
                      currency={organization.currency}
                      showInno
                      onClick={() =>
                        navigate(
                          createLinkToModal(location)(`/ideas/${idea.idea.id}`)
                        )
                      }
                    />
                    <div
                      className={cs(
                        "current-poll__idea-order",
                        `current-poll__idea-order--${idea.order}`
                      )}
                    >
                      {idea.order}.
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div>Nobody voted yet.</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
