import { useMutation } from "@apollo/react-hooks";
import { Link, navigate } from "@reach/router";
import gql from "graphql-tag";
import React from "react";
import { useAlert } from "react-alert";
import IconDelete from "../../assets/icons/icon-cross.svg";
import IconEdit from "../../assets/icons/icon-edit.svg";
import IconMenu from "../../assets/icons/icon-kebab-big.svg";
import IconUser from "../../assets/icons/icon-user-solid.svg";
import { createLinkToModal } from "../../components/Modal";
import Dropdown from "../../components/ui/Dropdown";
import Text from "../ui/Text";
import Badge from "./Badge";
import "./TeamRow.css";

const DELETE_TEAM_MUTATION = gql`
  mutation($id: ID!) {
    deleteTeam(id: $id)
  }
`;

export default function TeamRow({
  team,
  organizationId,
  loaded,
  location,
  linkToEdit
}) {
  const alert = useAlert();
  const [deleteTeam] = useMutation(DELETE_TEAM_MUTATION, {
    refetchQueries: ["orgTeams"]
  });
  const handleDeleteTeam = async () => {
    if (window.confirm("Are you sure to delete this team?")) {
      await deleteTeam({ variables: { id: team.id } });
      alert.show("Team was deleted", { type: "success" });
    }
  };
  const handleEditTeam = () => {
    navigate(createLinkToModal(location)(`/users/teams/${team.id}/edit`));
  };
  return (
    <div className="ingrid-team-row">
      {loaded ? (
        <div className="ingrid-team-row__container">
          <div className="ingrid-team-row__column ingrid-team-row__column--left">
            <Badge string={team.name} small className="mr8" />
            <Text small>{team.name}</Text>
          </div>
          <div className="ingrid-team-row__column">
            <Text small>{team.timezone}</Text>
          </div>
          <div className="ingrid-team-row__column">
            <Link to={`/users/?team=${team.id}`}>
              <Text small>
                <IconUser
                  width="8px"
                  className="mr8"
                  fill="var(--color-gray-light)"
                />
                {team.userCount}
              </Text>
            </Link>
          </div>
          <div className="ingrid-team-row__column">
            <Link to={`/ideas/?team=${team.id}`}>
              <Text small>{team.ideaCount}</Text>
            </Link>
          </div>

          <div className="ingrid-team-row__column ingrid-team-row__column--right">
            <div className="mr4">
              <div className="idea-campaign__dropdown">
                <div className="idea-campaign__dropdown__text">
                  <IconMenu width="24px" />
                </div>
                <Dropdown
                  style={{ backgroundColor: "white" }}
                  left
                  actions={[
                    {
                      Icon: IconEdit,
                      label: "Edit team",
                      handler: handleEditTeam
                    },
                    {
                      Icon: IconDelete,
                      label: "Delete team",
                      color: "danger",
                      handler: handleDeleteTeam
                    }
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="ingrid-team-row__placeholder" />
      )}
    </div>
  );
}

TeamRow.HEIGHT_NORMAL = 48;
