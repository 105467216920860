import { getPhotoExtenstion, PhotoType } from "../../library/photo";
import md5 from "md5";

type UserType = {
  id: string
};

type PayloadType = {
  user: UserType,
  description: string,
  photo: PhotoType
};

export const getPhotoHash = ({ user, description }: PayloadType) =>
  md5(user.id + description + Math.random() + new Date().toString());

const getS3PhotoURI = ({ photo, user, description }: PayloadType) => {
  const ext = getPhotoExtenstion(photo);
  const hash = getPhotoHash({ user, description });
  return `${hash}.${ext}`;
};

export default async function upload(
  { user, description, photo }: PayloadType,
  { url, fields }
) {
  const filename = getS3PhotoURI({ user, description, photo });
  const form = new FormData();

  form.append("key", `photos/${filename}`);

  form.append("x-amz-credential", fields.X_Amz_Credential);
  form.append("X-Amz-Algorithm", fields.X_Amz_Algorithm);
  form.append("X-Amz-Date", fields.X_Amz_Date);
  form.append("Policy", fields.Policy);
  form.append("X-Amz-Signature", fields.X_Amz_Signature);

  form.append("file", photo, filename);

  const response = await fetch(url, {
    method: "POST",
    body: form
  });

  if (!response.ok) {
    throw Error("Upload error", response.statusText);
  }

  return `${url}/photos/${filename}`;
}
