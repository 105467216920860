import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useState } from "react";
import Button from "../../components/ui/Button";
import Select from "../../components/ui/Select";
import Text from "../../components/ui/Text";
import "./OrganizationRemove.css";

export const ORGANIZATIONS_QUERY = gql`
  query {
    organizations {
      id
      name
    }
  }
`;

const REMOVE_ORG_MUTATION = gql`
  mutation($id: ID!) {
    deleteOrganization(id: $id) {
      id
      name
    }
  }
`;

export default function OrganizationRemoveScreen({ organization, modal }) {
  const [selectedOrganization, selectOrganization] = useState(organization?.id);
  const { data: { organizations } = {} } = useQuery(ORGANIZATIONS_QUERY);
  const [mutate, { error }] = useMutation(REMOVE_ORG_MUTATION, {
    // mby move to library/resolvers
    update(cache) {
      const { organizations } = cache.readQuery({
        query: ORGANIZATIONS_QUERY
      });

      cache.writeQuery({
        query: ORGANIZATIONS_QUERY,
        data: {
          organizations: organizations.filter(
            ({ id }) => id.toString() !== selectedOrganization
          )
        }
      });
    },
    refetchQueries: ["SAOrganizationList"]
  });
  const handleSelectOrganiztion = option => {
    if (!option) return;
    if (option.value) selectOrganization(String(option.value));
  };
  const handleSubmit = async event => {
    event.preventDefault();
    if (!selectedOrganization) return;

    const confirmed = window.confirm("You sure?");
    if (!confirmed) return;
    try {
      await mutate({ variables: { id: selectedOrganization } });
      // TODO: switch selected organization if just deleted
      modal.close();
    } catch (error) {}
  };

  return (
    <div className="org-remove-screen">
      <h2>
        <Text large>Remove organization</Text>
      </h2>
      <form onSubmit={handleSubmit} className="org-remove-screen__form">
        <Select
          maxOptionsHeight="180px"
          searchable
          value={selectedOrganization}
          onChange={handleSelectOrganiztion}
          options={organizations?.map(({ id, name }) => ({
            value: id,
            label: name
          }))}
        />
        <div>
          {error && (
            <div className="org-remove-screen__form">
              <Text color="error" center>
                {error.toString()}
              </Text>
            </div>
          )}
          <Button
            danger
            fullwidth
            type="submit"
            disabled={!selectedOrganization}
          >
            Delete
          </Button>
        </div>
      </form>
    </div>
  );
}
