import React, { useState, useEffect } from "react";
import CampaignCategory from "./CampaignCategory";
import { LocationProvider } from "@reach/router";
import Checkbox from "../../../components/ui/Checkbox";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

const pickCategories = (categories = []) => [
  // primary category
  categories.find(({ main }) => main) || {
    main: true,
    type: "INNO",
    name: "",
    enabled: true
  },
  // secondary. this is weak, we can get more than 2 categories
  categories.filter(({ main }) => !main)[0] || {
    main: false,
    type: "FUN",
    name: "",
    enabled: false
  }
];

const UPSERT_CATEGORY_MUTT = gql`
  mutation(
    $id: ID
    $main: Boolean!
    $campaignId: ID!
    $name: String!
    $description: String
    $ideaIds: [ID]
    $type: category_type
    $enabled: Boolean
  ) {
    upsertCategory(
      id: $id
      main: $main
      campaignId: $campaignId
      name: $name
      description: $description
      ideaIds: $ideaIds
      type: $type
      enabled: $enabled
    ) {
      id
      main
      name
      type
      description
      campaignId
      enabled
      ideaIds
    }
  }
`;

Categories.validate = ({ categories, name: campaignName } = {}) => {
  if (!categories?.length) return false;

  return (categories || []).reduce(
    (isValid, { name, description, enabled, main }) =>
      (isValid && (name || (main && campaignName))) || !enabled,
    true
  );
};

export default function Categories({
  campaign = {},
  campaignId // it's from url, so its immediately
} = {}) {
  const loaded = campaignId === campaign.id;
  const { name: campaignName, categories = [] } = campaign;
  const [enableSecondCategory, setEnableSecondCategory] = useState(false);
  const [primaryCategory, secondaryCategory] = pickCategories(categories);

  useEffect(() => {
    if (!loaded) return;

    // [2] enable second category if exists
    if (categories.length > 1) {
      setEnableSecondCategory(categories.find(({ main }) => main === false));
    }
  }, [categories, loaded]); // [1] IF categories or loading state changed

  const [mutate] = useMutation(UPSERT_CATEGORY_MUTT, {
    refetchQueries: ["CampaignDraft"]
  });

  const updateCategory = categoryId => updates => {
    const category = categories.find(({ id }) => categoryId === id); // Must be loaded from fresh categories prop
    if (!campaignId || !category?.id) return new Promise(re => re()); // Never allow to create category WA campaign

    const name =
      !updates.name && updates.name !== undefined
        ? ""
        : updates.name ||
          category.name ||
          campaignName ||
          `${category.type} category`;

    // Has to return promise
    return mutate({
      variables: {
        campaignId: Number(campaignId),
        ...category,
        ...updates,
        name
      },
      optimisticResponse: {
        __typename: "Mutation",
        upsertCategory: {
          campaignId: Number(campaignId),
          ...category,
          ...updates,
          __typename: "category",
          name
        }
      }
    });
  };
  return (
    <div className="campaign-wizard-categories">
      <p>
        Setup your campaign categories. Usually you want one innovation category
        to get as much inovations from your users as possible, but also a fun
        category to engage them to use the app often.
      </p>

      <Checkbox
        name="enabled"
        value={secondaryCategory?.enabled}
        onChange={({ value: enabled }) => {
          mutate({
            variables: { ...secondaryCategory, campaignId, enabled },
            optimisticResponse: {
              upsertCategory: {
                ...secondaryCategory,
                campaignId,
                enabled,
                __typename: "category"
              }
            }
          });
        }}
      >
        Enable secondary category
      </Checkbox>
      <LocationProvider>
        {({ location }) => (
          <div className="campaign-wizard-categories__container">
            <CampaignCategory
              location={location}
              category={primaryCategory}
              campaignId={campaignId}
              onCategoryUpdate={updateCategory(primaryCategory?.id)}
              campaignName={campaignName}
            />
            <CampaignCategory
              secondary
              enabled={enableSecondCategory}
              location={location}
              category={secondaryCategory}
              campaignId={campaignId}
              onCategoryUpdate={updateCategory(secondaryCategory?.id)}
            />
          </div>
        )}
      </LocationProvider>
    </div>
  );
}
