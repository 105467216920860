import { useState, useEffect } from "react";
import queryString from "query-string";
import { navigate } from "@reach/router";

const parse = search => queryString.parse(search);
const stringIsNumber = string =>
  Boolean(string) && Number.isNaN(Number(string)) === false;

export default function useUrlParams({ search, pathname }) {
  const [params, setParams] = useState(parse(search));

  useEffect(() => {
    const raw = parse(search);
    const decoded = Object.keys(raw).reduce((whole, name) => {
      let value = decodeURI(raw[name]);
      if (value === "true") value = true;
      return { ...whole, [name]: value };
    }, {});

    setParams(decoded);
  }, [search, setParams]);

  return [
    params,
    {
      updateUrlParam: updates => {
        const sanitized = Object.keys(updates).reduce(
          (whole, name) => ({
            ...whole,
            [name]: updates[name] ? encodeURI(updates[name]) : undefined
          }),
          {}
        );

        const newSearch = { ...params, ...sanitized };

        navigate(
          (pathname.endsWith("/") ? pathname : pathname + "/") +
            "?" +
            queryString.stringify(newSearch)
        );
      },
      clearAllUrlParams: () => {
        navigate(pathname);
      },

      urlInfo: {
        // We assume that numer in url (pathname or params) means ID
        get doUrlParamsIncludeAnyIdentificator() {
          return Boolean(Object.values(params).find(stringIsNumber));
        },
        get doesPathnameIncludeAnyIdentificator() {
          return Boolean(pathname.split("/").find(stringIsNumber));
        }
      }
    }
  ];
}
