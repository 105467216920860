import React, { useState, useEffect, useRef } from "react";
import "./Dropdown.css";
import cs from "classnames";

export default function Dropdown({ actions, left, bottom }) {
  const [showMenu, setShowMenu] = useState(false);

  function useOutsideEvent(ref) {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowMenu(false);
      }
    }

    useEffect(() => {
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    });
  }

  const wrapperRef = useRef(null);
  useOutsideEvent(wrapperRef);

  return (
    <div
      ref={wrapperRef}
      className={cs("dropdown__icon")}
      onClick={() => showMenu === false && setShowMenu(true)}
    >
      {showMenu && (
        <div className="dropdown">
          <div
            className={cs(
              "dropdown__menu",
              left && "dropdown__menu--left",
              bottom && "dropdown__menu--bottom"
            )}
            onClick={() => setShowMenu(false)}
          >
            {actions.map(({ handler, label, Icon, color, link }, index) =>
              !link ? (
                <button
                  key={index}
                  className="dropdown__button"
                  style={
                    color && {
                      fill: `var(--color-${color})`,
                      color: `var(--color-${color})`
                    }
                  }
                  onClick={handler}
                >
                  {Icon && <Icon className="dropdown__button-icon" />}
                  {label}
                </button>
              ) : (
                <a key={index} href={link}>
                  <div className="dropdown__link">
                    {Icon && <Icon className="dropdown__button-icon" />}
                    {label}
                  </div>
                </a>
              )
            )}
          </div>
        </div>
      )}
    </div>
  );
}
