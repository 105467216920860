import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import Campaign from "../../components/ingrid/Campaign";
import Headline from "../../components/ui/Headline";
import CampaignDashboard from "./CampaignDashboard";
import "./Campaigns.css";
import CurrentCampaigns from "./CurrentCampaigns";
import EvaluateCampaigns from "./EvaluateCampaigns";
import LoadingSquares from "../../components/ui/LoadingSquares";

export const CAMPAIGNS_QUERY = gql`
  query CampaignsGrid($organizationId: ID!) {
    campaigns(
      organizationId: $organizationId
      order: "reverse:to"
      where: { type: "ACTIVE" }
    ) {
      items {
        id
        name
        from
        to
        target
        state
        isApprovable
        campaignStats {
          activeUsersCount
          ideaCount
        }
        categories {
          id
          name
          color
          type
          ideaCount
        }
        teams {
          id
          name
        }
        targetIds
      }
      meta {
        totalItems
      }
    }
    userEngagement(organizationId: $organizationId) {
      engagement {
        date
        count
      }
      notifications {
        date
        count
        titles
      }
    }
  }
`;

const TEAMS_QUERY = gql`
  query campaignTeams($orgId: ID!) {
    teams(organizationId: $orgId) {
      id
      name
    }
  }
`;

export default function CampaignsScreen({ organization, location }) {
  const { loading, error, data, refetch } = useQuery(CAMPAIGNS_QUERY, {
    variables: {
      organizationId: organization?.id
    },
    skip: !organization,
    fetchPolicy: "cache-and-network"
  });

  const { data: { teams = [] } = {} } = useQuery(TEAMS_QUERY, {
    variables: {
      orgId: organization?.id
    },
    skip: !organization,
    fetchPolicy: "cache-and-network"
  });

  const showLoadingSquares = loading && !data?.campaigns?.items;

  return (
    <div className="campaigns-screen">
      <CampaignDashboard data={data} />
      <div className="campaigns-screen__container">
        <EvaluateCampaigns campaigns={data?.campaigns} />
        <CurrentCampaigns {...{ location, organization }} />
        <div className="campaigns-screen__all-campaigns-header">
          <div className="campaigns-screen__all-campaigns-headline">
            <Headline id="all" className="mt32">
              All your campaigns
            </Headline>
          </div>
        </div>

        <div className="campaigns-screen__grid">
          {showLoadingSquares && <LoadingSquares count={7} height="216px" />}
          {data?.campaigns.items
            .filter(({ state }) => state !== "DRAFT")
            .map(campaign => (
              <Campaign
                key={campaign.id}
                campaign={campaign}
                teamList={teams}
                afterCampaignRemove={refetch}
              />
            ))}

          <div>{error && error.toString()}</div>
        </div>
      </div>
    </div>
  );
}
