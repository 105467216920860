import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import Headline from "../../components/ui/Headline";

const getIdeaCampaigns = ideas => {
  if (!ideas) return;
  let ret = [];

  for (let i in ideas) {
    if (ideas[i].state === "DRAFT") continue;

    let item = {
      name: ideas[i].name,
      INNO: 0,
      FUN: 0
    };

    for (let j in ideas[i].categories) {
      item[ideas[i].categories[j].type] += ideas[i].categories[j].ideaCount;
    }

    ret.push(item);
  }

  return ret;
};

export default function ChartIdeasCampaigns({ campaigns }) {
  const ideasCampaignsData = getIdeaCampaigns(campaigns?.items);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload) {
      return (
        <div
          className="recharts-default-tooltip"
          style={{ backgroundColor: "white" }}
        >
          <div
            className="recharts-tooltip-label"
            style={{ padding: "10px", border: "1px solid #eee" }}
          >
            {payload[0].payload.name}

            <div style={{ color: "var(--color-chart-1)" }}>
              INNO: {payload[0].payload.INNO}
            </div>
            <div style={{ color: "var(--color-chart-2)" }}>
              FUN: {payload[0].payload.FUN}
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="organization-dashboard__graph-container">
      <Headline small className="mb4">
        Ideas in campaigns
      </Headline>
      <div className="organization-dashboard__graph">
        <ResponsiveContainer width="100%" height={220}>
          <BarChart
            width={500}
            height={300}
            data={ideasCampaignsData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={2} axisLine={true} tickLine={false} height={10} />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Bar dataKey="INNO" stackId="a" fill="var(--color-chart-1)" />
            <Bar dataKey="FUN" stackId="a" fill="var(--color-chart-2)" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
