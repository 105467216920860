import { useQuery } from "@apollo/react-hooks";
import { navigate } from "@reach/router";
import cs from "classnames";
import gql from "graphql-tag";
import React from "react";
import IconValue from "../../assets/icons/icon-money.svg";
import IconPlus from "../../assets/icons/icon-plus.svg";
import IconUsers from "../../assets/icons/icon-users.svg";
import IconPdf from "../../assets/icons/icon-file-pdf.svg";
import IconExcel from "../../assets/icons/icon-file-excel.svg";
import IconImages from "../../assets/icons/icon-file-images.svg";
import ChartIdeasInnovation from "../../components/ingrid/ChartIdeasInnovation";
import ChartUsersEngagement from "../../components/ingrid/ChartUsersEngagement";
import ChartUsersFunnel from "../../components/ingrid/ChartUsersFunnel";
import Idea from "../../components/ingrid/Idea";
import InnovProcessValue from "../../components/ingrid/InnovProcessValue";
import Leaderboard from "../../components/ingrid/Leaderboard";
import { createLinkToModal } from "../../components/Modal";
import Button from "../../components/ui/Button";
import DropdownButton from "../../components/ui/DropdownButton";
import Card from "../../components/ui/Card";
import Headline from "../../components/ui/Headline";
import IconStars from "../../components/ui/IconStars";
import Text from "../../components/ui/Text";
import * as format from "../../library/format";
import { absoluteLink } from "../../library/url";
import "./CampaignDetail.css";

const CAMPAIGN_QUERY = gql`
  query CampaignQuery($campaignId: ID!, $organizationId: ID!) {
    userStatistics(organizationId: $organizationId, campaignId: $campaignId) {
      invited
      invited_to_campaign
      registered
      active
      engaged
    }
    innovationProcess(
      organizationId: $organizationId
      campaignId: $campaignId
    ) {
      id
      state
      count
      value
    }
    userEngagement(organizationId: $organizationId, campaignId: $campaignId) {
      engagement {
        date
        count
      }
      notifications {
        date
        count
        titles
      }
    }
    campaign(id: $campaignId) {
      id
      name
      from
      to
      campaignStats {
        ideaCount
        activeUsersCount
        rating
      }
      users(limit: 30) {
        id
        email
        rank
      }
      teams {
        id
        name
        rank
      }
      notifications {
        id
        title
        content
        sendAt
        target
      }
      campaignIdeas(limit: 30, order: "organization_order") {
        id
        order
        idea {
          id
          innovationProcess {
            state
          }
          value
          createdBy {
            id
            email
          }
          description
          image
        }
      }
    }
  }
`;

export default function CampaignDetailScreen({
  location,
  campaignId,
  auth,
  organization
}) {
  const organizationId = organization?.id;

  const {
    error,
    data: { campaign, userStatistics, innovationProcess, userEngagement } = {}
  } = useQuery(CAMPAIGN_QUERY, {
    variables: { campaignId, organizationId },
    skip: !organizationId
  });

  const handleExportIdeas = link => () => {
    const exportLink = absoluteLink(link);
    navigate(exportLink);
  };

  const exportDropdownActions = [
    {
      Icon: IconPdf,
      label: "Ideas to PDF",
      handler: handleExportIdeas(
        `/api/organization/${organizationId}/idea/export/pdf?x-access-token=${auth?.token}&campaignId=${campaignId}`
      )
    },
    {
      Icon: IconExcel,
      label: "Ideas to excel",
      handler: handleExportIdeas(
        `/api/organization/${organizationId}/idea/export/excel?x-access-token=${auth?.token}&campaignId=${campaignId}`
      )
    },
    {
      Icon: IconImages,
      label: "Download ideas as images",
      handler: handleExportIdeas(
        `/api/organization/${organizationId}/idea/export/zip?x-access-token=${auth?.token}&campaignId=${campaignId}`
      )
    }
  ];

  if (error) {
    return (
      <div className="current-campaigns current-campaigns--error">
        <Text small center color="error">
          {error.toString()}
        </Text>
      </div>
    );
  }

  const handleCreateNotification = location => {
    navigate(
      createLinkToModal(location)(`/notifications/create`, {
        campaign: campaign.id
      })
    );
  };

  const showNotificationDetail = (notificationId, location) => {
    navigate(
      createLinkToModal(location)(`/notifications/${notificationId}`, {
        campaign: campaign?.id
      })
    );
  };

  return (
    <div className="campaign-detail-screen">
      <div className="campaign-detail-dashboard">
        <div className="campaign-detail__header">
          <Headline large className="mb16">
            {campaign?.name}
          </Headline>
          <div className="campaign-detail__actions">
            <DropdownButton
              title="EXPORT"
              bottom
              small
              actions={exportDropdownActions}
            />
            <Button
              className="ml8"
              small
              onClick={() => navigate(`/campaigns/create/${campaign?.id}`)}
            >
              Edit campaign
            </Button>
          </div>
        </div>
        <div className="campaign-detail-dashboard__graphs">
          <div className="campaign-detail-dashboard__left">
            {/* KPIs */}
            <div className="campaign-detail-dashboard__graph-container">
              <Headline small className="mb8">
                Basic campaign stats
              </Headline>
              <div className="campaign-detail-dashboard__kpis">
                <div className="campaign-detail-dashboard__kpis__card mr16">
                  <div className="campaign-detail-dashboard__kpis__icon">
                    <IconUsers height="100%" />
                  </div>
                  <Text large bold block>
                    {campaign?.campaignStats.activeUsersCount}
                  </Text>
                  <Text small block>
                    Active users
                  </Text>
                </div>
                <div className="campaign-detail-dashboard__kpis__card mr16">
                  <div className="campaign-detail-dashboard__kpis__icon">
                    <IconValue height="80%" />
                  </div>
                  <InnovProcessValue
                    innovationProcess={innovationProcess}
                    organization={organization}
                  />
                  <Text small block>
                    Total campaign value
                  </Text>
                </div>
                <div className="campaign-detail-dashboard__kpis__card">
                  <div className="campaign-detail-dashboard__kpis__icon">
                    <IconStars filled={campaign?.campaignStats.rating} />
                  </div>
                  <Text small block>
                    Campaign user ranking
                  </Text>
                </div>
              </div>
            </div>

            <ChartUsersEngagement
              userEngagement={userEngagement}
              height={160}
              className="campaign-detail-dashboard"
              campaignFrom={campaign?.from}
              campaignTo={campaign?.to}
            />
          </div>
          <div className="campaign-detail-dashboard__right">
            <ChartUsersFunnel
              userStatistics={userStatistics}
              className="campaign-detail-dashboard"
              showInvited={false}
            />

            <ChartIdeasInnovation
              innovationProcess={innovationProcess}
              campaignId={campaign?.id}
            />
          </div>
        </div>
      </div>
      <div className="campaign-detail__leaderboards">
        <Card className="campaign-detail__users mr16">
          <Leaderboard {...{ campaign, organization }} />
        </Card>
        <Card className="campaign-detail__ideas">
          <div className="current-campaigns__header">
            <Headline small className="mb16">
              Top ideas
            </Headline>

            <Button
              small
              onClick={() => {
                const link = createLinkToModal(location)("/ideas", {
                  campaign: campaign.id,
                  context: "bulk"
                });
                //?ccampaign=714&ccategory=960&modal=%2Fideas
                navigate(link);
              }}
            >
              Batch actions
            </Button>
          </div>
          <div className="campaign-detail__ideas-grid">
            {campaign?.campaignIdeas?.map(idea => (
              <div key={idea.idea.id} className="campaign-detail__idea">
                <Idea
                  idea={idea.idea}
                  showInno
                  onClick={() =>
                    navigate(
                      createLinkToModal(location)(`/ideas/${idea.idea.id}`)
                    )
                  }
                />
                {idea.order && (
                  <div
                    className={cs(
                      "campaign-detail__idea-order",
                      `campaign-detail__idea-order--${idea.order}`
                    )}
                  >
                    {idea.order + "."}
                  </div>
                )}
              </div>
            ))}
          </div>
        </Card>
        <Card className="current-campaigns__notifications">
          <div className="current-campaigns__header">
            <Headline className="current-campaigns__headline" small>
              Campaign notifications
            </Headline>
            <Button
              secondary
              small
              icon
              onClick={() => {
                handleCreateNotification(location);
              }}
            >
              <IconPlus width="16px" />
            </Button>
          </div>
          <div className="current-campaigns__notifications-container">
            {campaign?.notifications?.map(notification => (
              <Card
                key={notification.id}
                className="campaign-detail__notification-card"
                onClick={() =>
                  showNotificationDetail(notification.id, location)
                }
              >
                <Text truncate>{notification.title}</Text>
                <Text bold>{format.date(notification.sendAt)}</Text>
              </Card>
            ))}
          </div>
        </Card>
      </div>
    </div>
  );
}
