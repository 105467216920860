import React from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import Text from "../../../components/ui/Text";
import Card from "../../../components/ui/Card";
import "./EventComponents.css";

import IconInvisible from "../../../assets/icons/icon-invisible.svg";
import IconRanked from "../../../assets/icons/icon-ranked.svg";
import IconUnranked from "../../../assets/icons/icon-unranked.svg";
import IconVisible from "../../../assets/icons/icon-visible.svg";
import IconExample from "../../../assets/icons/icon-idea-example.svg";
import IconCross from "../../../assets/icons/icon-cross.svg";
import IconWarning from "../../../assets/icons/icon-warning.svg";
import IconValue from "../../../assets/icons/icon-money.svg";
import IconDesc from "../../../assets/icons/icon-description.svg";
import IconInno from "../../../assets/icons/icon-inno-selected.svg";

const DELETE_COMMENT_MUT = gql`
  mutation($id: ID!) {
    deleteComment(id: $id)
  }
`;

export const Comment = ({ id, comment }) => {
  const [deleteComment] = useMutation(DELETE_COMMENT_MUT, {
    refetchQueries: ["IdeaDetail"] // FIXME update grid but do no scrobo
  });
  const handleDelete = async () => {
    await deleteComment({ variables: { id } });
  };

  return (
    <div>
      <Card small>
        <div className="timeline-comment__content">
          <Text small>{comment}</Text>
          <button
            className="timeline-comment__button"
            width="16px"
            onClick={() => {
              const confirmed = window.confirm(
                "Are you sure you want to delete this comment?"
              );
              if (!confirmed) return;
              handleDelete();
            }}
          >
            <IconCross width="16px" />
          </button>
        </div>
      </Card>
    </div>
  );
};

export const IdeaCreated = ({ image, description }) => {
  return (
    <div className="timeline__generic-content">
      <div
        className="timeline-idea-created__image"
        style={{ backgroundImage: `url(${image})` }}
      />
      <div>
        <Text small className="timeline-idea-created__text">
          Idea created.
        </Text>
        <Text small italic>
          "{description}"
        </Text>
      </div>
    </div>
  );
};

export const UpdateCampaignIdea = updateData => {
  // TODO @dan Tady bych potřeboval poradit, jak sem dostat campaign name
  return (
    <div className="timeline__generic-content">
      <div>
        {updateData.isHidden && (
          <Text small>
            {updateData.isHidden.new ? (
              <span className="timeline__update-campaign-idea">
                <IconInvisible width="12px" /> Idea is now invisible to users in{" "}
                <b>{updateData.campaignName}</b>.
              </span>
            ) : (
              <span className="timeline__update-campaign-idea">
                <IconVisible width="12px" /> Idea is now visible to users in{" "}
                <b>{updateData.campaignName}</b>.
              </span>
            )}
          </Text>
        )}
        {updateData.isExcludedFromStatistics && (
          <Text small>
            {updateData.isExcludedFromStatistics.new ? (
              <span className="timeline__update-campaign-idea">
                <IconUnranked width="12px" /> Idea excluded from rankings in{" "}
                <b>{updateData.campaignName}</b>.
              </span>
            ) : (
              <span className="timeline__update-campaign-idea">
                <IconRanked width="12px" /> Idea is now included to rankings in{" "}
                <b>{updateData.campaignName}</b>.
              </span>
            )}
          </Text>
        )}
        {updateData.isPromo && (
          <Text small>
            {updateData.isPromo.new ? (
              <span className="timeline__update-campaign-idea">
                <IconExample width="12px" /> Idea was set as an example in{" "}
                <b>{updateData.campaignName}</b>.
              </span>
            ) : (
              <span className="timeline__update-campaign-idea">
                <IconExample width="12px" /> Idea is no longer an example in{" "}
                <b>{updateData.campaignName}</b>.
              </span>
            )}
          </Text>
        )}
        {updateData.description && (
          <Text small>
            <span className="timeline__update-campaign-idea">
              <IconDesc width="12px" /> Idea description in{" "}
              <b>{updateData.campaignName}</b> was set to "
              <i>{updateData.description.new}</i>"
            </span>
          </Text>
        )}
      </div>
    </div>
  );
};

export const AddToCampaign = updateData => {
  return (
    <div className="timeline__generic-content">
      <Text small>
        Idea added to <b>{updateData.campaignName}</b>
      </Text>
    </div>
  );
};

export const UpdateIdea = updateData => {
  return (
    <div className="timeline__generic-content">
      {updateData.value && (
        <Text small>
          <span className="timeline__update-campaign-idea">
            <IconValue width="12px" className="mr8" />
            <span>Idea value set to </span>
            <b>
              {updateData.value.new}
              <span> </span>
              {updateData.value.currency}
            </b>
            .
          </span>
        </Text>
      )}
    </div>
  );
};

export const UpdateIdeaProcess = updateData => {
  return (
    <div className="timeline__generic-content">
      {updateData.state &&
        (updateData.state.new === "SELECTED" ? (
          <div className="timeline__update-campaign-idea--inno">
            <div className="timeline__update-campaign-idea__icon">
              <IconInno width="12px" />
            </div>
            <Text small>
              Idea was <b>{updateData.state.new}</b> to innovation process.
            </Text>
          </div>
        ) : (
          <div className="timeline__update-campaign-idea--inno">
            <div className="timeline__update-campaign-idea__icon">
              <IconInno width="12px" />
            </div>
            <Text small>
              Idea set to <b>{updateData.state.new}</b> in innovation process.
            </Text>
          </div>
        ))}
    </div>
  );
};

export const RemoveIdeaProcess = updateData => {
  return (
    <div className="timeline__generic-content">
      {" "}
      <div className="timeline__update-campaign-idea--inno">
        <div className="timeline__update-campaign-idea__icon">
          <IconCross width="10px" />
        </div>
        <Text small>Idea was removed from innovation process.</Text>
      </div>
    </div>
  );
};

// "SOME_TYPE_NAME" => "SomeTypeName"
export const Unknown = ({ type, ...data }) => {
  return (
    <div className="timeline__generic-content timeline__unknown">
      <IconWarning width="16px" className="mr8" />
      <Text small>Unknown type: {type}</Text>
    </div>
  );
};
