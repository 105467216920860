import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import "./NotificationDetail.css";
import useUrlParams from "../../hooks/useUrlParams";
import * as format from "../../library/format";

import Text from "../../components/ui/Text";

import IconBegin from "../../assets/icons/icon-circle-play.svg";
import IconEnd from "../../assets/icons/icon-circle-trophy.svg";
import IconFun from "../../assets/icons/campaign-badges/badge-category-fun.svg";
import IconInno from "../../assets/icons/campaign-badges/badge-category-inno.svg";

import { navigate } from "@reach/router";
import { createLinkToModal } from "../../components/Modal";

import Headline from "../../components/ui/Headline";
import Card from "../../components/ui/Card";

export const NOTIFICATION_QUERY = gql`
  query NotificationData($organizationId: ID!, $where: SequelizeJSON) {
    notifications(organizationId: $organizationId, where: $where) {
      id
      title
      content
      sendAt
      type
      campaignId
    }
  }
`;

const CAMPAIGN_QUERY = gql`
  query NotificationCampaign($organizationId: ID!, $where: SequelizeJSON) {
    campaigns(organizationId: $organizationId, where: $where, limit: 1) {
      items {
        id
        name
        users(limit: 3) {
          id
          email
          rank
        }
        categories {
          id
          name
          type
          description
        }

        campaignIdeas(limit: 3, order: "organization_order") {
          order
          idea {
            id
            image
            value
            innovationProcess {
              state
            }
            description
            createdBy {
              id
              email
            }
          }
        }
      }
    }
  }
`;

export default function NotificationDetailScreen({
  location,
  organization,
  notificationId,
  modal
}) {
  const [urlParams] = useUrlParams(modal.location);
  const campaignId = urlParams.campaign;
  const { error, data: { notifications } = {} } = useQuery(NOTIFICATION_QUERY, {
    variables: {
      organizationId: organization?.id,
      where: { id: notificationId }
    },
    skip: !organization
  });
  const { data: { campaigns } = {} } = useQuery(CAMPAIGN_QUERY, {
    variables: {
      organizationId: organization?.id,
      where: { id: campaignId }
    },
    skip: !organization || !campaignId
  });

  const selectedNotification = notifications?.slice(0)[0] || {};
  const selectedCampaign = campaigns?.items?.slice(0)[0] || {};

  const handleIdeaClick = idea => () => {
    const ideaId = idea.id.toString(); // meh
    const path = `/ideas/${ideaId}`;
    navigate(createLinkToModal(modal.location)(path));
  };

  return (
    <Card className="notification-detail__card">
      <div className="notification-detail__headline">
        {selectedNotification.type === "BEGIN" && (
          <IconBegin width="24px" fill="var(--color-green)" className="mr8" />
        )}
        {selectedNotification.type === "END" && (
          <IconEnd width="24px" fill="var(--color-black)" className="mr8" />
        )}
        <Headline>{format.date(selectedNotification.sendAt)}</Headline>
      </div>
      <Text bold>Notification content:</Text>
      <div className="notification-detail__text">
        <Text small bold>
          {selectedNotification.title}
        </Text>
        <Text small>{selectedNotification.content}</Text>
      </div>
      <Text bold>Campaign info:</Text>
      <div className="notification-detail__campaign">
        <Text bold>{selectedCampaign.name}</Text>
        <Text>{selectedCampaign.description || <i>No description</i>}</Text>
        {selectedNotification.type === "BEGIN" && (
          <div>
            {selectedCampaign?.categories?.map(category => (
              <div key={category.id}>
                <div className="notification-detail__categories">
                  {category.type === "INNO" && (
                    <IconInno width="16px" className="mr4" />
                  )}
                  {category.type === "FUN" && (
                    <IconFun width="16px" className="mr4" />
                  )}
                  <Text bold>{category.name}</Text>
                </div>
                <Text small block className="notification-detail__description">
                  {category.description}
                </Text>
              </div>
            ))}
          </div>
        )}
      </div>
      {selectedNotification.type === "END" && (
        <div>
          <Text bold>Top ideas:</Text>
          <div className="notification-detail__campaign">
            <div className="notification-detail__idea-grid">
              {selectedCampaign?.campaignIdeas?.map(idea => (
                <div key={idea.idea?.id} onClick={handleIdeaClick(idea.idea)}>
                  <img
                    className="notification-detail__idea"
                    src={idea.idea?.image}
                    alt="top idea"
                  />
                </div>
              ))}
            </div>
          </div>
          <Text bold>Top users:</Text>
          <div className="notification-detail__campaign">
            <div>
              {selectedCampaign?.users?.map(user => (
                <div key={user.id}>
                  <Text bold>{user.rank}. </Text>
                  <Text>{user.email}</Text>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {error && error.toString()}
    </Card>
  );
}
