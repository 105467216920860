import React from "react";
import "./Wizard.css";
import Headline from "../../../components/ui/Headline";
import IconCheckTrue from "../../../assets/icons/color/icon-checkbox-completed-big.svg";
import IconCheckFalse from "../../../assets/icons/color/icon-checkbox-empty-big.svg";

export default function wizard(
  Component,
  { order = 1, title = "", completed = true, campaign, ...props } = {}
) {
  const validate = Component.validate || (() => false);

  return (
    <div className="create-poll-wizard">
      <div className="poll-wizard__header">
        <Headline inline>
          {order}. {title}
        </Headline>
        {validate(campaign) ? (
          <IconCheckTrue className="poll-wizard__header__check" width="24px" />
        ) : (
          <IconCheckFalse className="poll-wizard__header__check" width="24px" />
        )}
      </div>
      <Component {...props} campaign={campaign} />
    </div>
  );
}
