import { Link } from "@reach/router";
import React, { useEffect, useState } from "react";
import Logo from "../../assets/ingrid-logo.svg";
import Button from "../../components/ui/Button";
import Headline from "../../components/ui/Headline";
import Text from "../../components/ui/Text";
import "./Login.css";

export default function LoginScreen({ location, auth }) {
  const [formValues, setFormValues] = useState({ email: "", password: "" });
  const [error, setError] = useState(auth.error);

  const handleFormValueChange = valueType => ({ target: { value } }) => {
    setFormValues({
      ...formValues,
      [valueType]: value
    });
  };

  useEffect(
    () =>
      auth.subscribe(({ error: _error }) => {
        setError(_error);
      }),
    [auth]
  );

  const handleSubmit = event => {
    if (event) event.preventDefault();
    const { email, password } = formValues;
    auth.login({ email, password });
  };

  return (
    <div className="login-screen">
      <Logo className="login-screen__logo" width="64px" />
      <div className="login-screen__form">
        <Headline className="login-screen__headline">Admin login</Headline>
        <form onSubmit={handleSubmit}>
          <label>
            <Text color="gray-dark">E-mail address</Text>
            <input
              type="email"
              name="ingrid-email"
              value={formValues.email}
              onChange={handleFormValueChange("email")}
              className="ingrid-input"
            />
          </label>
          <label>
            <Text color="gray-dark">Password</Text>
            <input
              type="password"
              value={formValues.password}
              onChange={handleFormValueChange("password")}
              className="ingrid-input"
            />
          </label>
          <div className="login-screen__link">
            <Link to="/password">Forgot password?</Link>
          </div>
          <Button className="login-screen__submit" fullwidth type="submit">
            Log in
          </Button>
          {error && <div className="login-screen__error">{error}</div>}
        </form>
      </div>
    </div>
  );
}
