import React from "react";
import Badge from "../../../components/ingrid/Badge";
import Text from "../../../components/ui/Text";
import * as format from "../../../library/format";
import * as EventComponents from "./EventComponents";
import "./Timeline.css";

const EventComponent = ({ type, data = {}, date, user, ...pass }) => {
  if (!type) throw new Error("Event has to have a type.");

  const typeName = type
    .split("_")
    .map(chunk => chunk.charAt(0) + chunk.slice(1).toLowerCase())
    .join(""); // "SOME_TYPE_NAME" => "SomeTypeName"
  const Component =
    EventComponents[typeName || "Unknown"] || EventComponents.Unknown;

  return (
    <div className="timeline__event">
      <div className="timeline__event__header">
        <Badge invert small string={user} className="timeline__event__badge" />
        <Text className="timeline__event__user" bold small>
          {user}
        </Text>
        <Text small>{format.date(date)}</Text>
      </div>
      <div className={`timeline__event--${type.toLowerCase()}`}>
        <Component type={type} {...data} {...pass} />
      </div>
    </div>
  );
};

export default function Timeline({ events = [], filterComments }) {
  return (
    <div className="timeline">
      {events
        .filter(f => (filterComments ? f.type === "COMMENT" : true))
        .map(event => (
          <div>
            <EventComponent
              key={`${event.type}-${event.id}`}
              type={event.type}
              data={event.data}
              campaignName={event.campaign?.name}
              date={event.created_at}
              user={event.created_by?.email}
            />
          </div>
        ))}
    </div>
  );
}
