import React from "react";
import "./Checkbox.css";

export default function Checkbox({ value, onChange, name, children: text }) {
  const handleChange = ({ target: { checked } }) => {
    if (onChange) onChange({ value: checked });
  };
  return (
    <div className="ingrid-checkbox">
      <input
        className="ingrid-checkbox__icon"
        type="checkbox"
        id={name}
        name={name}
        checked={value}
        onChange={handleChange}
      />
      <label className="ingrid-checkbox__clickable-area" htmlFor={name}>
        <span />
        {text}
      </label>
    </div>
  );
}
