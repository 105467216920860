import gql from "graphql-tag";

const __storage_key_org = "selected-organization";
const __storage_key_super = "super-organization";
const initialData = {
  selectedOrganization: null,
  superOrganization: false
};

// FIXME: query only selected organization
const ORGANIZATIONS_QUERY = gql`
  query {
    organizations {
      id
      name
      currency
      pollsEnabled
      teamsEnabled
      innovationProcessEnabled
      sendInvitationEmailsEnabled
      activeCampaignsEnabled
      voteOutsideTeamEnabled
      teams {
        id
        name
      }
    }
  }
`;

export const restoreCache = async (client, canQueryData = false) => {
  client.cache.writeData({ data: initialData });
  const storedId = localStorage.getItem(__storage_key_org);
  const storedSuper = localStorage.getItem(__storage_key_super);

  if (canQueryData) {
    const {
      data: { organizations }
    } = await client.query({ query: ORGANIZATIONS_QUERY });

    client.cache.writeData({
      data: {
        // ...data, not sure if this is patch or rewrite
        selectedOrganization:
          organizations.find(({ id }) => id.toString() === storedId) ||
          organizations[0],
        superOrganization: storedSuper
      }
    });
  }
};

export const typeDefs = gql`
  extend type Query {
    selectedOrganization: organization!
  }

  extend type Mutation {
    selectOrganization(id: ID!): Bool
  }

  extend type Mutation {
    superOrganization(active: Bool!): Bool
  }
`;

/**
 * It seems that this is only for local (@client) state
 */
export const resolvers = {
  Query: {},
  Mutation: {
    selectOrganization: (_, { id }, { cache }) => {
      const { organizations } = cache.readQuery({ query: ORGANIZATIONS_QUERY });
      const selectedOrganization =
        organizations.find(org => org.id.toString() === id) || null;

      cache.writeData({
        data: {
          selectedOrganization
        }
      });

      localStorage.setItem(__storage_key_org, id);

      return true;
    },

    superOrganization: (_, { active }, { cache }) => {
      cache.writeData({
        data: {
          superOrganization: active
        }
      });

      if (active) {
        localStorage.setItem(__storage_key_super, "yeah");
      } else {
        localStorage.removeItem(__storage_key_super);
      }

      return true;
    }
  }
};
