import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

const CAMPAIGN_AVAILABLE_TARGET_QUERY = gql`
  query CampaignAvailableTargets(
    $organizationId: ID!
    $excludeCampaignId: ID
    $from: Date!
    $to: Date!
    $type: campaign_types!
    $target: campaign_target_types
  ) {
    campaignAvailableTargets(
      organizationId: $organizationId
      from: $from
      to: $to
      type: $type
      target: $target
      excludeCampaignId: $excludeCampaignId 
    ) {
      targetIds
    }
  }
`;

export default function useCampaignAvailableTargets({
  organization,
  campaign
}) {
  const skipping =
    !campaign?.from ||
    !campaign?.to ||
    !campaign?.target ||
    !organization?.id ||
    new Date(campaign.from) > new Date(campaign.to);

  const {
    data: { campaignAvailableTargets: { targetIds = [] } = {} } = {}
    // loading
  } = useQuery(CAMPAIGN_AVAILABLE_TARGET_QUERY, {
    variables: {
      organizationId: organization?.id,
      from: campaign?.from,
      to: campaign?.to,
      type: campaign?.type,
      target: campaign?.target,
      excludeCampaignId: campaign?.id
    },
    skip: skipping
  });

  return targetIds;
}
