import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import IconFun from "../../assets/icons/campaign-badges/badge-category-fun.svg";
import IconInno from "../../assets/icons/campaign-badges/badge-category-inno.svg";
import ImageUpload from "../../components/ImageUpload";
import Button from "../../components/ui/Button";
import Headline from "../../components/ui/Headline";
import Select from "../../components/ui/Select";
import Text from "../../components/ui/Text";
import useUrlParams from "../../hooks/useUrlParams";
import "./IdeaCreate.css";
import upload from "./upload";

// import Idea from "../../components/ingrid/Idea";

const PRESIGNET_POST_LINK_QUERY = gql`
  query {
    presignedPostLink {
      url
      fields {
        key
        acl
        success_action_redirect
        Content_Type
        x_amz_meta_uuid
        x_amz_server_side_encryption
        X_Amz_Credential
        X_Amz_Algorithm
        X_Amz_Date
        x_amz_meta_tag
        Policy
        X_Amz_Signature
      }
    }
  }
`;

const CAMPAIGNS_QUERY = gql`
  query($organizationId: ID!) {
    campaigns(organizationId: $organizationId) {
      items {
        id
        name
        state
        categories {
          id
          name
          main
          type
        }
      }
    }
  }
`;

const CREATE_IDEA_MUTT = gql`
  mutation(
    $organizationId: ID
    $image: String!
    $description: String
    $campaign: campaign_idea_input
  ) {
    createIdea(
      organizationId: $organizationId
      image: $image
      description: $description
      campaign: $campaign
    ) {
      id
      image
      description
      createdBy {
        id
        email
      }
      created_at
      campaignIdeas {
        id
        isPromo
        campaign {
          id
          name
        }
        category {
          id
          name
          type
        }
      }
      commentCount
    }
  }
`;

export default function IdeaCreateScreen({
  user,
  campaignId,
  organization,
  modal,
  location
}) {
  const [urlParams] = useUrlParams(modal.location);
  const [formValues, setFormValues] = useState({
    imageFile: null,
    description: "",
    // Use ids from fiven url params (in case modal open from create campaign)
    campaign: urlParams.ccampaign && Number(urlParams.ccampaign),
    category: urlParams.ccategory && Number(urlParams.ccategory)
  });
  const descriptionFieldRef = useRef(null);
  const alert = useAlert();
  const handleFormValueChange = fieldName => ({ value, target }) => {
    if (target === undefined && value === undefined) return;
    setFormValues({
      ...formValues,
      [fieldName]: value === undefined ? target?.value : value
    });

    if (fieldName === "imageFile") {
      descriptionFieldRef.current.focus();
    }
  };

  const { data: { presignedPostLink } = {} } = useQuery(
    PRESIGNET_POST_LINK_QUERY,
    {
      skip: !formValues.imageFile
    }
  );

  const [mutate, { error: serverError }] = useMutation(CREATE_IDEA_MUTT);

  const { data: { campaigns: { items: campaigns } = {} } = {} } = useQuery(
    CAMPAIGNS_QUERY,
    {
      skip: !organization,
      variables: {
        organizationId: organization?.id
      }
    }
  );

  const campaign = campaigns?.find(({ id }) => id === formValues.campaign);

  useEffect(() => {
    if (campaign && !formValues.category) {
      // select first category if no defined yet
      setFormValues({ ...formValues, category: campaign.categories?.[0]?.id });
    }
  }, [campaign]); // eslint-disable-line

  const handleSubmit = async event => {
    if (event) event.preventDefault();
    const { description, imageFile: photo, category } = formValues;

    if (!photo) return;

    const imageUrl = await upload(
      { user, description, photo },
      presignedPostLink
    );

    const createIdeaAsPromo = modal.location.pathname.includes(
      "/campaigns/create"
    );

    const {
      data: { createIdea: idea }
    } = await mutate({
      variables: {
        image: imageUrl,
        description,
        organizationId: organization?.id,
        campaign: !campaign
          ? null
          : {
              id: campaign.id, // eslint-disable-line
              categoryId: category, // eslint-disable-line
              isPromo: createIdeaAsPromo, // eslint-disable-line
              isExcludedFromStatistics: createIdeaAsPromo // eslint-disable-line
            } // eslint-disable-line
      }
      // refetchQueries: ["IdeasGrid"] // FIXME update grid but do no scrobo
    });

    modal.closeWithParams({
      [`createdIdea${urlParams.suffix || ""}`]: idea.id
    });
    alert.show("Idea created", { type: "success" });
  };

  return (
    <form className="idea-create-screen" onSubmit={handleSubmit}>
      <Headline>Create new idea</Headline>
      <div className="idea-create-screen__section">
        <p className="idea-create-screen__label">
          Upload a photo of your idea:
        </p>
        <ImageUpload onChange={handleFormValueChange("imageFile")} />{" "}
      </div>
      <div className="idea-create-screen__section">
        <p className="idea-create-screen__label">Description:</p>
        <textarea
          ref={descriptionFieldRef}
          className="ingrid-textarea"
          onChange={handleFormValueChange("description")}
          value={formValues.description}
        />
      </div>
      <div className="idea-create-screen__section">
        <p className="idea-create-screen__label">Campaign:</p>
        <Select
          options={campaigns
            ?.filter(({ state }) => state !== "FINISHED")
            .sort((a, b) => (a.state > b.state ? -1 : 1))
            .map(({ name, id, state }) => ({
              label: name + (state === "DRAFT" ? String(" (DRAFT)") : ""),
              value: id
            }))}
          clearable
          searchable
          dropup
          value={formValues.campaign}
          onChange={campaign => {
            setFormValues({
              ...formValues,
              campaign: campaign ? campaign.value : null,
              category: null
            });
          }}
        />
      </div>
      {campaign && (
        <div className="idea-create-screen__section">
          <p className="idea-create-screen__label">Category:</p>
          {campaign.categories.slice(0, 2).map(category => (
            <div key={category.id} className="idea-create-screen-radio">
              <input
                className="idea-create-screen-radio__input"
                type="radio"
                name="category"
                id={category.id}
                value={category.id}
                checked={category.id === formValues.category}
                onChange={({ target }) => {
                  setFormValues({
                    ...formValues,
                    category: Number(target.value)
                  });
                }}
              />
              <label
                className="idea-create-screen-radio__label"
                htmlFor={category.id}
              >
                <div className="idea-create-screen-radio__button">
                  {category.type === "INNO" && (
                    <IconInno width="24px" className="mr8" />
                  )}
                  {category.type === "FUN" && (
                    <IconFun width="24px" className="mr8" />
                  )}
                  {category.name || campaign.name}
                </div>
              </label>
            </div>
          ))}
        </div>
      )}

      {serverError && (
        <Text center color="error">
          {serverError.toString()}
        </Text>
      )}
      <Button fullwidth disabled={!formValues.imageFile} type="submit">
        Create idea
      </Button>
    </form>
  );
}
