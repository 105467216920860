import React from "react";
import cn from "classnames";
// import IconMenu from "../../assets/icons/icon-kebab-big.svg";
import Dropdown from "./Dropdown";
import "./DropdownButton.css";

type ActionType = {
  Icon: JSX.Element,
  label: String,
  link: String,
  handler: () => void,
  color: "danger"
};

export default function DropdownButton({
  // kebab = false,
  children,
  actions,
  bottom,
  left,
  top,
  title = "button",
  primary,
  disabled,
  small
}: {
  children: [ActionType],
  actions: [ActionType],
  title: String
}) {
  if (!actions && children) actions = children;
  if (!actions) disabled = true;

  return (
    <div className="dropdown-button-container">
      <div
        className={cn(
          "dropdown-button",
          primary && "dropdown-button--primary",
          disabled && "dropdown-button--disabled",
          small && "dropdown-button--small"
        )}
      >
        <div className="dropdown-button__text">{title.toUpperCase()}</div>
        {!disabled && (
          <Dropdown
            {...{ left, bottom, top }}
            actions={actions.filter(x => Boolean(x))}
          />
        )}
      </div>
    </div>
  );
}
