import { useMutation } from "@apollo/react-hooks";
import { Link, navigate } from "@reach/router";
import gql from "graphql-tag";
import React from "react";
import { useAlert } from "react-alert";
import IconDelete from "../../assets/icons/icon-cross.svg";
import IconEdit from "../../assets/icons/icon-edit.svg";
import IconInvite from "../../assets/icons/icon-invite.svg";
import IconMenu from "../../assets/icons/icon-kebab-big.svg";
import StarEmptyIcon from "../../assets/icons/icon-star-empty.svg";
import StarFullIcon from "../../assets/icons/icon-star-full.svg";
import { createLinkToModal } from "../../components/Modal";
import Dropdown from "../../components/ui/Dropdown";
import * as format from "../../library/format";
import Badge from "../ingrid/Badge";
import Text from "../ui/Text";
import IngridUser from "./User";
import "./UserRow.css";

const PROMOTE_USER_MUTT = gql`
  mutation($organizationId: ID!, $id: ID!, $admin: Boolean!) {
    promoteUser(organizationId: $organizationId, id: $id, admin: $admin) {
      id
      role
    }
  }
`;

const REINVITE_USER_MUTT = gql`
  mutation($organizationId: ID!, $emails: [String]!) {
    inviteUser(
      organizationId: $organizationId
      emails: $emails
      reinvite: true
    ) {
      invited
    }
  }
`;

const REMOVE_USER_MUTT = gql`
  mutation($organizationId: ID!, $emails: [String]!) {
    removeUser(organizationId: $organizationId, emails: $emails) {
      removed
    }
  }
`;

export default function UserRow({ user, loaded, organization, location }) {
  const alert = useAlert();

  const [promoteUser] = useMutation(PROMOTE_USER_MUTT);
  const [reinviteUser] = useMutation(REINVITE_USER_MUTT);
  const [removeUser] = useMutation(REMOVE_USER_MUTT);

  const handlePromoteUser = ({ id, email } = {}, admin) => async () => {
    if (!id) return;
    await promoteUser({
      variables: { id, admin, organizationId: organization?.id }
    });
    alert.show(
      admin
        ? `User ${email} promoted to admin`
        : `User ${email} demoted to regular user`,
      { type: "success" }
    );
  };

  return (
    <div className="ingrid-user-row">
      {loaded ? (
        <div className="ingrid-user-row__container">
          <div className="ingrid-user-row__column--left">
            <IngridUser
              small
              className="ingrid-user-row__user"
              badge={false}
              user={user}
            />
          </div>
          <div className="ingrid-user-row__column--big">
            <Text small>
              {user?.lastLoggedAt
                ? format.date(user?.lastLoggedAt)
                : "Not logged yet"}
            </Text>
          </div>
          <div className="ingrid-user-row__column--big">
            <Text small>{user?.invitedAt && format.date(user.invitedAt)}</Text>
          </div>
          <div className="ingrid-user-row__column--big">
            <Text small>
              {user?.invitationAcceptedAt &&
                format.date(user.invitationAcceptedAt)}
            </Text>
          </div>
          <div className="ingrid-user-row__column">
            <Link
              className="ingrid-user-row__link"
              to={`../ideas/?search=${user?.email}`}
            >
              <Text small>{user?.ideaCount}</Text>
            </Link>
          </div>
          <div className="ingrid-user-row__column">
            <Text small>{user?.rank ? user?.rank : "-"}</Text>
          </div>
          {organization.teamsEnabled && (
            <div className="ingrid-user-row__column">
              {user?.team ? <Badge small string={user.team.name} /> : "N/A"}
            </div>
          )}

          <div className="ingrid-user-row__column ingrid-user-row__column--menu">
            <div className="idea-campaign__dropdown">
              <div className="idea-campaign__dropdown__text">
                <IconMenu width="24px" />
              </div>
              <Dropdown
                bottom
                style={{ backgroundColor: "white" }}
                actions={[
                  organization?.teamsEnabled && {
                    Icon: IconEdit,
                    label: "Change user's team",
                    handler: () => {
                      navigate(
                        createLinkToModal(location)(
                          `/userteam/${user.id}/${user.team?.id}`
                        )
                      );
                    }
                  },
                  /* eslint-disable */
                  user?.role !== "admin"
                    ? {
                        Icon: StarEmptyIcon,
                        label: "Promote to admin",
                        handler: handlePromoteUser(user, true)
                      }
                    : {
                        Icon: StarFullIcon,
                        label: "Demote to user",
                        handler: handlePromoteUser(user, false)
                      },
                  /* eslint-enable */
                  {
                    Icon: IconInvite,
                    label: "Invite user again",
                    handler: async () => {
                      await reinviteUser({
                        variables: {
                          organizationId: organization?.id,
                          emails: [user.email]
                        }
                      });
                      alert.show(`User ${user.email} re-invited`, {
                        type: "success"
                      });
                    }
                  },
                  {
                    Icon: IconDelete,
                    label: "Remove user",
                    color: "danger",
                    handler: async () => {
                      if (window.confirm("Are you sure?")) {
                        await removeUser({
                          variables: {
                            organizationId: organization?.id,
                            emails: [user.email]
                          },
                          refetchQueries: ["UsersList"]
                        });

                        alert.show(`Farawell, ${user.email}`, {
                          type: "success"
                        });
                      }
                    }
                  }
                ].filter(x => Boolean(x))}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="ingrid-user-row__placeholder" />
      )}
    </div>
  );
}

UserRow.HEIGHT_NORMAL = 48;
