import React, { useState } from "react";
import cs from "classnames";
import "./Idea.css";

// import gql from "graphql-tag";
// import { useMutation } from "@apollo/react-hooks";

import Card from "../ui/Card";
import Text from "../ui/Text";
import BadgeCategoryInno from "../../assets/icons/campaign-badges/badge-category-inno.svg";
import BadgeCategoryFun from "../../assets/icons/campaign-badges/badge-category-fun.svg";
import BadgePoll from "../../assets/icons/campaign-badges/badge-poll.svg";
import BadgeUser from "../../assets/icons/campaign-badges/badge-user.svg";

import IconIsHidden from "../../assets/icons/icon-invisible.svg";
import IconIsPromo from "../../assets/icons/icon-idea-example.svg";
import IconIsUnranked from "../../assets/icons/icon-unranked.svg";
import IconCampaign from "../../assets/icons/icon-campaign.svg";
import IconEdit from "../../assets/icons/icon-edit.svg";
import IconVisible from "../../assets/icons/icon-visible.svg";

import BadgeValue from "../../assets/icons/campaign-badges/badge-value.svg";
import BadgeInnoSelected from "../../assets/icons/campaign-badges/badge-inno-selected.svg";
import BadgeInnoApproved from "../../assets/icons/campaign-badges/badge-inno-approved.svg";
import BadgeInnoImplementing from "../../assets/icons/campaign-badges/badge-inno-implementing.svg";
import BadgeInnoDone from "../../assets/icons/campaign-badges/badge-inno-done.svg";
import { photo } from "../../library/cloudinary";
import Button from "../ui/Button";

// const DELETE_IDEA_MUTT = gql`
//   mutation($id: ID!) {
//     deleteIdea(id: $id)
//   }
// `;

export const IdeaPlaceholder = ({ className }) => (
  <Card className={`idea idea--placeholder ${className}`}>
    <div className="idea__photo idea__photo--placeholder" />
    <div className="idea__description idea__description--placeholder" />
    <div className="idea__attributes idea__attributes--placeholder">
      <div className="idea__attribute idea__attribute--placeholder" />
      <div className="idea__attribute idea__attribute--placeholder" />
      <div className="idea__attribute idea__attribute--placeholder" />
    </div>
  </Card>
);

export default function Idea({
  idea: {
    image,
    value,
    innovationProcess,
    description,
    campaignIdeas,
    createdBy,
    commentCount,
    replicated,
    tags
  },
  onClick,
  onImageClick,
  className,
  highlight,
  currency,
  showInno,
  showComments,
  showUser,
  showDetail,
  showTags,
  showValue,
  example,
  onDescriptionEdit
}) {
  const [editableDescription, setEditableDescription] = useState(false);
  const [editedDescription, setEditedDescription] = useState(
    example?.description || description || ""
  );

  const hasOtherAttributes =
    (value !== 0 && showValue) ||
    showUser ||
    (showTags && tags?.length > 0) ||
    (showInno && innovationProcess);

  const handleEditDescription = value => {
    setEditableDescription(value);
  };

  return (
    <Card
      className={cs("idea", className, highlight && "idea--highlight")}
      onClick={onClick}
    >
      <div
        className="idea__photo"
        onClick={onImageClick}
        style={{
          backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.1), transparent 33%), url(${photo(
            image,
            { width: 280 }
          )})`
        }}
      >
        {showDetail && (
          <div className="idea__photo-detail__icon">
            <IconVisible width="20px" />
            <div className="idea__photo-detail">
              <img
                className="idea__photo-detail__image"
                src={image}
                alt="idea-detail"
                width="100%"
              />
              <Text>{description}</Text>
            </div>
          </div>
        )}
        {showComments && commentCount !== 0 && (
          <div className="idea__comment" data-title="Number of comments.">
            <Text small title="comments">
              {commentCount <= 9 ? commentCount : "9+"}
            </Text>
          </div>
        )}
        {replicated > 0 && (
          <div
            className="idea__replicated"
            data-title="This idea was replicated by some users."
          >
            <Text color="white" small bold>
              {replicated}
            </Text>
          </div>
        )}

        {highlight && <div className="idea__selected-checkbox" />}
        {example && <div className="idea__removable-overlay" />}
      </div>
      <div className="idea__description">
        {!editableDescription && (
          <Text small truncate>
            {example?.description || description || <i>No description.</i>}
          </Text>
        )}
        {example &&
          onDescriptionEdit &&
          (!editableDescription ? (
            <div
              className="idea__edit-description-button"
              data-title="Change description of the example idea for this campaign."
              onClick={() => handleEditDescription(true)}
            >
              <IconEdit width="12px" className="ml8" />
            </div>
          ) : (
            <form
              className="idea__edit-textarea"
              onSubmit={event => {
                if (event) event.preventDefault();
                if (onDescriptionEdit && editedDescription !== description) {
                  onDescriptionEdit(editedDescription);
                }
                handleEditDescription(false);
              }}
            >
              <textarea
                rows="3"
                className="ingrid-textarea"
                value={editedDescription}
                onChange={({ target: { value } }) => {
                  setEditedDescription(value);
                }}
              />
              <div className="idea__edit-textarea__butts">
                <Button small fullwidth type="submit">
                  Save
                </Button>
                <Button
                  type="reset"
                  small
                  secondary
                  onClick={event => {
                    if (event) event.preventDefault();
                    handleEditDescription(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </form>
          ))}
      </div>

      <div className="idea-attributes">
        {campaignIdeas && (
          <div className="idea-attributes__campaigns">
            {campaignIdeas
              ?.slice(0, campaignIdeas.length > 3 ? 2 : 3)
              .map(campaignIdea => (
                <div key={campaignIdea.id} className="idea-attribute">
                  {campaignIdea.category.type === "INNO" ? (
                    campaignIdea.campaign.type === "ACTIVE" ? (
                      <div>
                        <BadgeCategoryInno className="idea__badge" />
                      </div>
                    ) : (
                      <div>
                        <BadgePoll className="idea__badge" />
                      </div>
                    )
                  ) : (
                    <div>
                      <BadgeCategoryFun className="idea__badge" />
                    </div>
                  )}

                  <Text truncate small bold color="black">
                    {campaignIdea.campaign?.name}
                  </Text>

                  <div className="idea-attribute__icons">
                    {/* {campaignIdea.reported > 0 && (
                      <div data-title="This idea was reported by a user.">
                        <Text small title="comments">
                          {campaignIdea.reported}
                        </Text>
                      </div>
                    )} */}
                    {campaignIdea.isHidden && (
                      <div
                        className="idea-attribute__icon"
                        data-title="This idea is invisible to users in this campaign."
                      >
                        <IconIsHidden />
                      </div>
                    )}
                    {campaignIdea.isPromo && (
                      <div
                        className="idea-attribute__icon"
                        data-title="This idea is used as an example in this campaign."
                      >
                        <IconIsPromo />
                      </div>
                    )}
                    {campaignIdea.isExcludedFromStatistics && (
                      <div
                        className="idea-attribute__icon"
                        data-title="This idea is excluded from rankings in this campaign."
                      >
                        <IconIsUnranked />
                      </div>
                    )}
                  </div>
                </div>
              ))}
            {campaignIdeas && campaignIdeas.length > 3 && (
              <div className="idea-attribute">
                <IconCampaign className="idea__icon" />

                <Text truncate small color="black">
                  In <b>{campaignIdeas.length - 2}</b> more campaigns ...
                </Text>
              </div>
            )}
          </div>
        )}
        {hasOtherAttributes && (
          <div className="idea-attributes__others">
            {value !== 0 && !example && showValue && (
              <div
                className="idea-attribute"
                data-title="Monetary value of the idea."
              >
                <BadgeValue className="idea__badge" />
                <Text truncate small bold color="black">
                  {value} {currency}
                </Text>
              </div>
            )}
            {showInno && innovationProcess && (
              <div className="idea-attribute">
                {innovationProcess.state === "SELECTED" && (
                  <BadgeInnoSelected className="idea__badge" />
                )}
                {innovationProcess.state === "APPROVED" && (
                  <BadgeInnoApproved className="idea__badge" />
                )}
                {innovationProcess.state === "IMPLEMENTING" && (
                  <BadgeInnoImplementing className="idea__badge" />
                )}
                {innovationProcess.state === "DONE" && (
                  <BadgeInnoDone className="idea__badge" />
                )}
                <Text truncate small color="black">
                  <b>{innovationProcess.state}</b> in innovations
                </Text>
              </div>
            )}
            {showTags && tags?.length > 0 && (
              <div className="idea-attribute__tags">
                {tags?.map(tag => (
                  <div className="idea-attribute__tag" key={tag.id}>
                    {tag.tag}
                  </div>
                ))}
              </div>
            )}
            {showUser && (
              <div className="idea-attribute idea-attribute">
                <BadgeUser className="idea__badge" />
                <Text truncate tiny color="black">
                  by <b>{createdBy?.email}</b>
                </Text>
              </div>
            )}
          </div>
        )}
      </div>
    </Card>
  );
}
