/**
 * Dirty solution how to listen on scroll end reached but in different component than listener is used.
 * We have main container defined inside AppLayout but want to handle end reaches inside screen components.
 *
 * Original source: https://github.com/karl-run/react-bottom-scroll-listener/blob/master/src/hook/index.tsx
 */
import { useCallback, useEffect } from "react";
import lodashDebounce from "lodash.debounce";
import { scrollElementId } from "../components/AppLayout";

const createCallback = (
  debounce: number,
  handleOnScroll: () => void
): (() => void) => {
  if (debounce) {
    return lodashDebounce(handleOnScroll, debounce, { trailing: true });
  } else {
    return handleOnScroll;
  }
};

/**
 *  A react hook that invokes a callback when user scrolls to the bottom
 *
 * @param onBottom Required callback that will be invoked when scrolled to bottom
 * @param offset Offset from bottom of page in pixels. E.g. 300 will trigger onBottom 300px from the bottom of the page
 * @param debounce Optional debounce in milliseconds, defaults to 200ms
 * @return DOM.Element Optionally you can use this to pass to a element to use that as the scroll container
 */

export default function useScreenEndReached(
  onBottom: () => void,
  { offset = 0, debounce = 200, container } = {}
) {
  const containerRef = container || document.getElementById(scrollElementId);

  const handleOnScroll = useCallback(() => {
    const scrollNode = containerRef;
    const scrollContainerBottomPosition = Math.round(
      scrollNode.scrollTop + scrollNode.clientHeight
    );
    const scrollPosition = Math.round(scrollNode.scrollHeight - offset);

    if (scrollPosition <= scrollContainerBottomPosition) {
      onBottom();
    }
  }, [offset, onBottom, containerRef]);

  useEffect(() => {
    const callback = createCallback(debounce, handleOnScroll);

    if (containerRef) containerRef.addEventListener("scroll", callback);

    return () => {
      if (containerRef) containerRef.removeEventListener("scroll", callback);
    };
  }, [handleOnScroll, debounce, containerRef]);

  return containerRef;
}
