import { DateTime } from "luxon";

// const { userLanguage, language } = window.navigator;

/**
 *  Returns localized date and time
 * @param {string} isoDate
 */
export const date = isoDate => {
  return DateTime.fromISO(isoDate).toLocaleString(DateTime.DATETIME_SHORT);
};

/**
 **  Returns ONLY localized date
 * @param {string} isoDate
 */
export const onlyDate = isoDate => {
  window.DateTime = DateTime;
  return DateTime.fromISO(isoDate).toLocaleString();
};
