import { navigate } from "@reach/router";
import React from "react";
import IngridAddCard from "../../../components/ingrid/AddCard";
import Notification from "../../../components/ingrid/Notification";
import { createLinkToModal } from "../../../components/Modal";
import "./Notifications.css";

Notifications.validate = () => true;

export default function Notifications({ location, campaign }) {
  const handleCreateNotification = () => {
    navigate(
      createLinkToModal(location)(`/notifications/create`, {
        campaign: campaign.id
      })
    );
  };
  return (
    <div className="campaign-wizard-notifications">
      <div className="notifications__timeline__container">
        <IngridAddCard onClick={handleCreateNotification} />

        <div>
          {campaign?.notifications?.map(notification => (
            <div
              key={notification.id}
              className="notifications__timeline notifications__timeline--upcoming"
            >
              <Notification
                notification={notification}
                campaign={campaign}
                editable
                deletable={notification.type === "CUSTOM"}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
