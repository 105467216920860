import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

const CREATE_MUTT = gql`
  mutation($organizationId: ID!, $type: campaign_types!) {
    upsertCampaign(organizationId: $organizationId, type: $type) {
      id
    }
  }
`;

export default function useCreateCampaign(
  { organization, type = "ACTIVE" },
  callback
) {
  const [mutate] = useMutation(CREATE_MUTT);

  const refetchQueries = ["RunningCampaigns"];
  if (type === "ACTIVE") refetchQueries.push("CampaignDrafts");

  return () => {
    mutate({
      variables: { organizationId: organization.id, type },
      refetchQueries
    }).then(
      ({
        data: {
          upsertCampaign: { id }
        }
      }) => {
        callback(id);
      }
    );
  };
}
