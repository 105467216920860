import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

const UPDATE_CAMPAIGN_IDEA_MUT = gql`
  mutation($campaignId: ID!, $ideas: [campaign_idea_update]!) {
    updateCampaignIdea(campaignId: $campaignId, ideas: $ideas) {
      id
      ideaId
      isHidden
      isExcludedFromStatistics
      description
    }
  }
`;

export default function useUpdateCampaignIdeas({ campaignId }, callback) {
  const [updateCampaignIdeasMut] = useMutation(UPDATE_CAMPAIGN_IDEA_MUT, {
    refetchQueries: ["IdeasGrid", "IdeaDetail", "CampaignExampleIdeas"],
    onCompleted: callback
  });
  return async function updateCampaignIdeas(
    ideasUpdates,
    { campaignId: lazyCampaignId } = {}
  ) {
    updateCampaignIdeasMut({
      variables: {
        campaignId: campaignId || lazyCampaignId,
        ideas: ideasUpdates // like { isHidden, description }
      }
    });
  };
}
