import React, { useCallback } from "react";
import "./PollForm.css";
import wizard from "./Wizard";
import Basic from "./Wizard/Basic";
import Ideas from "./Wizard/Ideas";
import Target from "./Wizard/Target";
import Notifications from "./Wizard/Notifications";
import Button from "../../components/ui/Button";
import { navigate } from "@reach/router";
import { useMutation, useQuery, useApolloClient } from "@apollo/react-hooks";
import gql from "graphql-tag";
import debounce from "../../library/debounce";

export const CAMPAIGN_QUERY = gql`
  query PollDraft($id: ID!) {
    campaign(id: $id) {
      id
      name
      description
      from
      to
      type
      target
      targetIds
      draft
      notifications {
        id
        title
        content
        sendAt
        target
        type
        draft
      }
      categories {
        id
        ideaIds
        campaignId
        main
      }
    }
  }
`;

const UPSERT_CAMPAIGN_MUTT = gql`
  mutation(
    $organizationId: ID!
    $id: ID
    $name: String
    $description: String
    $from: Date
    $to: Date
    $target: campaign_target_types
    $targetIds: [ID]
  ) {
    upsertCampaign(
      id: $id
      organizationId: $organizationId
      type: PASSIVE
      name: $name
      description: $description
      from: $from
      to: $to
      target: $target
      targetIds: $targetIds
    ) {
      id
      name
      description
      from
      to
      type
      target
      targetIds
      draft
      categories {
        id
        ideaIds
        main
      }
      notifications {
        id
        title
        content
        sendAt
        target
        type
        draft
      }
    }
  }
`;

const PUBLISH_CAMPAIGN_MUTT = gql`
  mutation($id: ID!) {
    publishCampaign(id: $id)
  }
`;

export default function PollFormScreen({ campaignId, organization, location }) {
  const { data: { campaign } = {} } = useQuery(CAMPAIGN_QUERY, {
    variables: { id: campaignId },
    skip: !campaignId
  });

  const [mutateUpsertCampaign] = useMutation(UPSERT_CAMPAIGN_MUTT);
  const apollo = useApolloClient();

  const update = useCallback(
    updates => {
      const updatedCampaign = {
        id: Number(campaignId),
        ...campaign,
        ...updates
      };

      if (campaign.draft) {
        mutateUpsertCampaign({
          variables: {
            organizationId: organization?.id,
            id: campaignId,
            ...updates
          },
          optimisticResponse: {
            upsertCampaign: {
              ...updatedCampaign,
              __typename: "campaign"
            }
          }
        });
      } else {
        // Just store this locally, no mutations. We'll grap the current `campaign` object when we'd like to save updates
        apollo.writeQuery({
          query: CAMPAIGN_QUERY,
          variables: { id: campaignId },
          data: {
            campaign: updatedCampaign
          }
        });
      }
    },
    [mutateUpsertCampaign, organization, campaignId, campaign, apollo]
  );

  const [mutatePublishCampaign] = useMutation(PUBLISH_CAMPAIGN_MUTT);

  const handlePublish = async () => {
    const { errors } = await mutatePublishCampaign({
      variables: { id: campaignId },
      refetchQueries: ["PollsGrid"]
    });
    if (!errors) navigate("/polls", { replace: true });
  };

  const handleSave = async () => {
    const { errors } = await mutateUpsertCampaign({
      variables: {
        organizationId: organization?.id,
        ...campaign
      },
      optimisticResponse: {
        upsertCampaign: {
          id: Number(campaignId),
          ...campaign,
          __typename: "campaign"
        }
      }
    });
    if (!errors) navigate("/polls", { replace: true });
  };

  const isCampaignReady = [Basic, Ideas, Target, Notifications].reduce(
    (isReady, WizardComponent) => isReady && WizardComponent.validate(campaign),
    true
  );
  const debouncedUpdate = useCallback(debounce(update, 300), [update]);
  const passProps = {
    campaignId,
    campaign,
    organization,
    updateCampaign: debouncedUpdate,
    location
  };

  return (
    <div className="poll-form-screen">
      {wizard(Basic, { order: 1, title: "Basic setup", ...passProps })}
      {wizard(Target, { order: 2, title: "User targets", ...passProps })}
      {wizard(Ideas, { order: 3, title: "Ideas", ...passProps })}
      {wizard(Notifications, {
        order: 4,
        title: "Notifications",
        ...passProps
      })}
      {campaign?.draft ? (
        <Button onClick={handlePublish} disabled={!isCampaignReady}>
          PUBLISH POLL
        </Button>
      ) : (
        <Button onClick={handleSave} disabled={!isCampaignReady}>
          SAVE POLL
        </Button>
      )}
    </div>
  );
}
