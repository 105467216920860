import cn from "classnames";
import React, { useState } from "react";
import IconSearch from "../../assets/icons/icon-search.svg";
import Text from "../../components/ui/Text";
import "./FilterRow.css";

export default function FilterRow({
  values,
  onChange,
  className,
  itemsFilteredCount,
  children
}) {
  const [searchValue, setSearchValue] = useState(values.search || "");
  const handleTextInputChange = kind => ({ target: { value } }) => {
    setSearchValue(value);
    onChange({ [kind]: value });
  };

  return (
    <div className={cn("filter-row", className)}>
      <div className="filter-row__item filter-row__item--search">
        <label className="filter-row__label">
          <IconSearch className="filter-row__item-icon" width="16" />

          <input
            className="ingrid-input ingrid-input--small filter-row__search-input"
            type="search"
            placeholder="Search ..."
            value={searchValue}
            onChange={handleTextInputChange("search")}
          />
        </label>
      </div>

      <div className="filter-row__item">
        {itemsFilteredCount && (
          <Text nowrap>
            Filtered <b>{itemsFilteredCount.data}</b>
            {itemsFilteredCount.label}.
          </Text>
        )}
      </div>
      <div className="filter-row__toolbox">{children}</div>
    </div>
  );
}
