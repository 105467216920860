import React from "react";
import Headline from "../../components/ui/Headline";

export default function EmptyScreen({ location: { search, pathname } }) {
  return (
    <div>
      <Headline>¯\_(ツ)_/¯</Headline>

      <pre>Empty Screen for pathname {pathname}</pre>
    </div>
  );
}
