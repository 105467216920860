import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import IconTeamEdit from "../../assets/icons/icon-team-edit.svg";
import Button from "../../components/ui/Button";
import Headline from "../../components/ui/Headline";
import Select from "../../components/ui/Select";
import Text from "../../components/ui/Text";
import "./UserTeam.css";

export const CHANGE_USER_TEAM_MUTATION = gql`
  mutation($organizationId: ID!, $userId: ID!, $teamId: ID!) {
    changeUserTeam(
      organizationId: $organizationId
      userId: $userId
      teamId: $teamId
    )
  }
`;

export default function UserTeamFormScreen({
  modal,
  organization,
  userId,
  teamId
}) {
  const [formFields, setFormFields] = useState({
    teamId: parseInt(teamId)
  });

  const [updateUserTeam] = useMutation(CHANGE_USER_TEAM_MUTATION, {
    refetchQueries: ["UsersList", "orgTeams"]
  });

  const alert = useAlert();

  const handleFieldChange = typeName => arg => {
    const value = arg?.target?.value || arg?.value;
    setFormFields({ ...formFields, [typeName]: value });
  };

  const handleSubmit = async event => {
    event.preventDefault();
    await updateUserTeam({
      variables: {
        organizationId: organization?.id,
        userId,
        ...formFields
      }
    });
    modal.close();
    alert.show("User team has been changed", {
      type: "success"
    });
  };
  return (
    <div className="userteam-form-screen">
      <form onSubmit={handleSubmit}>
        <div className="team-form-screen__icon">
          <IconTeamEdit width="96" fill="var(--color-primary)" />
        </div>
        <Headline center>Change user's team</Headline>
        <Text center block className="mb16">
          Edit team of this user
        </Text>

        <div className="userteam-form-screen__icon">
          <div className="mb32">
            <Headline small>Team</Headline>
            <Select
              maxMenuHeight={180}
              searchable
              dropup
              name="teamId"
              value={formFields.teamId}
              options={organization?.teams.map(team => ({
                label: team.name,
                value: team.id
              }))}
              onChange={handleFieldChange("teamId")}
            />
          </div>
          <Button small fullwidth>
            Change user's team
          </Button>
        </div>
      </form>
    </div>
  );
}
