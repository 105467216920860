import React, { useState, useEffect } from "react";
import "./Basic.css";
import Headline from "../../../components/ui/Headline";
import Text from "../../../components/ui/Text";
import DateTimeInput from "../../../components/ui/DateTimeInput";
import { useMemo } from "react";
import useCampaignAvailableTargets from "../../../hooks/useCampaignAvailableTargets";

Basic.validate = ({ name, description, from, to } = {}) => {
  if (!name) return false;
  // if (!description || description.length < 5) return false;
  if (!from || !to) return false;
  if (new Date(from) > new Date(to)) return false;

  return true;
};

export default function Basic({ campaign, updateCampaign, organization }) {
  const [campaignValues, setCampaignValues] = useState({
    name: "",
    description: "",
    from: "",
    to: ""
  });

  useEffect(() => {
    if (!campaign) return;
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    setCampaignValues({
      name: campaign.name,
      description: campaign.description,
      from: campaign.from || today.toISOString(),
      to: campaign.to || tomorrow.toISOString()
    });
  }, [Boolean(campaign)]); // eslint-disable-line

  const handleFormValueChange = typeName => ({ target: { value } = {} }) => {
    const newCampaignValues = {
      ...campaignValues,
      [typeName]: value
    };

    setCampaignValues(newCampaignValues);
    updateCampaign(newCampaignValues);
  };

  const crossedDurationDates = useMemo(
    () => new Date(campaignValues.from) > new Date(campaignValues.to),
    [campaignValues.from, campaignValues.to]
  );

  const availableTargetIds = useCampaignAvailableTargets({
    campaign,
    organization
  });

  const showAvailabilityError =
    !crossedDurationDates && !availableTargetIds.length;

  return (
    <div className="poll-wizard-basic">
      <label>
        <div className="poll-wizard__section">
          <Headline small className="poll-wizard__headline">
            Poll name<Text color="danger">*</Text>
          </Headline>
          <div>
            <input
              className="ingrid-input ingrid-input--small"
              type="text"
              value={campaignValues.name || ""}
              onChange={handleFormValueChange("name")}
            />
          </div>
        </div>
      </label>
      <label>
        <div className="poll-wizard__section">
          <Headline small className="poll-wizard__headline">
            Poll description
          </Headline>

          <textarea
            className="ingrid-textarea ingrid-input--small"
            rows="4"
            onChange={handleFormValueChange("description")}
            value={campaignValues.description || ""}
          >
            {campaignValues.description}
          </textarea>
        </div>
      </label>
      <div className="poll-wizard__section--dates">
        <label className="poll-wizard-basic__label mr8">
          <Headline small className="poll-wizard__headline">
            Date from:<Text color="danger">*</Text>
          </Headline>
          <DateTimeInput
            value={campaignValues.from}
            onChange={handleFormValueChange("from")}
          />
        </label>
        <label className="poll-wizard-basic__label">
          <Headline small className="poll-wizard__headline">
            Date to:<Text color="danger">*</Text>
          </Headline>
          <DateTimeInput
            value={campaignValues.to}
            onChange={handleFormValueChange("to")}
          />
        </label>
      </div>

      {crossedDurationDates && (
        <div className="poll-wizard-basic__error">
          <Text color="error">End date can't be before starting date. </Text>
        </div>
      )}

      {showAvailabilityError && (
        <div className="poll-wizard-basic__error">
          <Text color="error">
            There are no available targets for selected poll duration.
          </Text>
        </div>
      )}
    </div>
  );
}
