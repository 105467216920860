import { useMutation } from "@apollo/react-hooks";
import cs from "classnames";
import gql from "graphql-tag";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import IconFile from "../../assets/icons/icon-file.svg";
import IconManual from "../../assets/icons/icon-keyboard.svg";
import IconUpload from "../../assets/icons/icon-upload.svg";
import Button from "../../components/ui/Button";
import Headline from "../../components/ui/Headline";
import { absoluteLink } from "../../library/url";
import "./UsersRemove.css";

const REMOVE_BY_EMAILS_MUTT = gql`
  mutation RemoveUsersByEmails($organizationId: ID!, $emails: [String]!) {
    removeUser(organizationId: $organizationId, emails: $emails) {
      # totalItems
      removed
      notFound
      logLink
      errorCount
      errorList
    }
  }
`;
const REMOVE_BY_FILE_MUTT = gql`
  mutation RemoveUsersByCsvFile($organizationId: ID!, $file: Upload!) {
    removeUsers(organizationId: $organizationId, file: $file) {
      # totalItems
      removed
      notFound
      logLink
      errorCount
      errorList
    }
  }
`;

const isValidCsvFile = file => file && file.type === "text/csv";

export default function UsersRemoveScreen({ location, organization, modal }) {
  const [removeType, setInviteType] = useState("manual");
  const [csvFile, setCsvFile] = useState(null);
  const [emailList, setEmailList] = useState([]);
  const [csvFileError, setCsvFileError] = useState(false);
  const [submiting, setSubmiting] = useState(false);
  const alert = useAlert();

  const removeMutationOptions = {
    refetchQueries: ["UsersList"]
  };

  const [removeByEmails] = useMutation(
    REMOVE_BY_EMAILS_MUTT,
    removeMutationOptions
  );
  const [removeByFile] = useMutation(
    REMOVE_BY_FILE_MUTT,
    removeMutationOptions
  );

  const handleSubmit = async event => {
    event.preventDefault();

    if (!window.confirm("Are you sure?")) {
      return;
    }

    const handleSubmitingDone = ({
      removed,
      notFound,
      logLink,
      errorCount,
      errorList
    }) => {
      modal.close();

      if (removed === 1) {
        alert.show(`Removed one new user`, { type: "success" });
      } else if (removed > 1) {
        alert.show(`Removed ${removed} users`, { type: "success" });
      }

      if (notFound) {
        alert.show(`Skipped ${notFound} not found users`, {
          type: "info"
        });
      }

      if (errorCount) {
        alert.show(
          <span>
            Ignored {errorCount} invalid emails. Check out{" "}
            <a
              href={absoluteLink(logLink)}
              target="_blank"
              rel="noopener noreferrer"
            >
              import log
            </a>{" "}
            file.
          </span>,
          {
            type: "error",
            timeout: 0 // persistent alert
          }
        );
      }
    };

    if (removeType === "manual") {
      setSubmiting(true);
      const { data: { removeUser } = {} } = await removeByEmails({
        variables: { organizationId: organization?.id, emails: emailList }
      });

      handleSubmitingDone(removeUser || {});
    }

    if (removeType === "file") {
      setSubmiting(true);
      const { data: { removeUsers } = {} } = await removeByFile({
        variables: { organizationId: organization?.id, file: csvFile }
      });

      handleSubmitingDone(removeUsers || {});
    }
  };

  return (
    <form className="users-remove-screen" onSubmit={handleSubmit}>
      <Headline className="users-remove__headline">Remove Users</Headline>
      <div className="users-remove__buttons">
        <div className="users-remove-radio  mr16">
          <input
            className="users-remove-radio__input"
            type="radio"
            name="selectType"
            id="manual"
            value="manual"
            checked={removeType === "manual"}
            onChange={() => {
              setInviteType("manual");
              setCsvFileError(null);
            }}
          />
          <label className="users-remove-radio__label" htmlFor={"manual"}>
            <div className="users-remove-radio__button">
              <IconManual width="24px" className="mb4" />
              Manual
            </div>
          </label>
        </div>
        <div className="users-remove-radio">
          <input
            className="users-remove-radio__input"
            type="radio"
            name="selectType"
            id="file"
            value="file"
            checked={removeType === "file"}
            onChange={() => setInviteType("file")}
          />
          <label className="users-remove-radio__label" htmlFor={"file"}>
            <div className="users-remove-radio__button">
              <IconFile width="24px" className="mb4" />
              From file
            </div>
          </label>
        </div>
      </div>
      <div className="users-remove__container">
        {removeType === "manual" && (
          <div className="users-remove__manual">
            <p>
              Insert user e-mails you want to <strong>remove</strong> to your
              organization.
            </p>
            <textarea
              className="ingrid-textarea"
              rows="5"
              placeholder={`Insert e-mails ...\n...`}
              value={emailList.join("\n")}
              onChange={({ target: { value } }) => {
                setEmailList(value.split("\n"));
              }}
            />
          </div>
        )}
        {removeType === "file" && (
          <div className="users-remove__file-upload">
            <p>Upload a file with user e-mails from your computer.</p>
            <input
              className="users-remove__file-upload__input"
              type="file"
              name="usersInviteFile"
              id="users-remove-file"
              onChange={({
                target: {
                  files: [file]
                }
              }) => {
                if (!isValidCsvFile(file)) {
                  setCsvFileError(true);
                } else {
                  setCsvFile(file);
                }
              }}
            />
            <label
              className="users-remove__file-upload__label"
              htmlFor="users-remove-file"
            >
              <div
                className={cs(
                  "users-remove__file-upload__button",
                  csvFileError && "users-remove__file-upload__button--error"
                )}
              >
                <IconUpload width="20px" className="mr4" />

                {csvFile ? (
                  <p>{`File ready to use ${csvFile.name}`}</p>
                ) : (
                  <p className={csvFileError ? "color-error" : ""}>
                    Upload <b>.csv</b> from your computer
                  </p>
                )}
              </div>
            </label>
          </div>
        )}
      </div>

      <div className="users-remove__footer">
        <div className="users-remove__confirm">
          <Button
            small
            type="submit"
            danger
            disabled={
              (removeType === "file" && !isValidCsvFile(csvFile)) ||
              (removeType === "manual" && !emailList.length) ||
              submiting
            }
          >
            Remove selected users
          </Button>
        </div>
      </div>
    </form>
  );
}
