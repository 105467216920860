import { useMutation, useQuery } from "@apollo/react-hooks";
import classNames from "classnames";
import gql from "graphql-tag";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import templateImage from "../../assets/default-idea.png";
import IconCheck from "../../assets/icons/icon-checkmark.svg";
import IconComment from "../../assets/icons/icon-comment.svg";
import IconDelete from "../../assets/icons/icon-cross.svg";
import IconDownload from "../../assets/icons/icon-download.svg";
import IconInno from "../../assets/icons/icon-inno.svg";
import IconMoney from "../../assets/icons/icon-money.svg";
import IconPlus from "../../assets/icons/icon-plus.svg";
import User from "../../components/ingrid/User";
import Button from "../../components/ui/Button";
import BadgeMoney from "../../assets/icons/campaign-badges/badge-value.svg";

import IconInnoSelected from "../../assets/icons/icon-inno-selected.svg";
import IconInnoApproved from "../../assets/icons/icon-inno-approved.svg";
import IconInnoImplementing from "../../assets/icons/icon-inno-implementing.svg";
import IconInnoDone from "../../assets/icons/icon-inno-done.svg";

import DropdownButton from "../../components/ui/DropdownButton";
import Headline from "../../components/ui/Headline";
import Tag from "../../components/ui/Tag";
import Text from "../../components/ui/Text";
import useRemoveIdeasFromInno from "../../hooks/useRemoveIdeasFromInno";
import useSendIdeasToInno from "../../hooks/useSendIdeasToInno";
import * as format from "../../library/format";
import CommentForm from "./CommentForm";
import IdeaCampaigns from "./IdeaCampaigns";
import "./IdeaDetail.css";
import Timeline from "./Timeline/Timeline";

export const IDEA_QUERY = gql`
  query IdeaDetail($ideaId: ID!) {
    idea(id: $ideaId) {
      innovationProcess {
        id
        state
      }
      id
      organization_id
      image
      createdAt
      description
      value
      createdBy {
        id
        email
      }
      tags {
        id
        tag
        ideaId
      }
      campaignIdeas {
        id
        order
        idea_id
        description
        isPromo
        isHidden
        team {
          id
          name
        }
        isExcludedFromStatistics
        reactions {
          likes
          dislikes
        }
        campaign {
          id
          name
          state
          type
        }
        category {
          id
          type
        }
      }
      timelineEvents {
        id
        data
        type
        created_at
        created_by {
          id
          email
        }
        campaign {
          id
          name
        }
        created_by_user_id
      }
    }
  }
`;

const UPDATE_IDEA_MUT = gql`
  mutation($id: ID!, $description: String, $value: Int) {
    updateIdea(id: $id, description: $description, value: $value) {
      id
      description
      value
    }
  }
`;

const DELETE_IDEA_MUTT = gql`
  mutation($id: ID!) {
    deleteIdea(id: $id)
  }
`;

const ADD_TAG = gql`
  mutation($idea_id: ID!, $tag: String!) {
    createTagOnIdea(idea_id: $idea_id, tag: $tag) {
      idea_id
      tag
    }
  }
`;

const DELETE_TAG_MUT = gql`
  mutation($id: ID!) {
    deleteTag(id: $id)
  }
`;

export default function IdeaDetailScreen({
  modal,
  ideaId,
  campaignId,
  organization
}) {
  const { error, data: { idea } = {}, refetch } = useQuery(IDEA_QUERY, {
    variables: {
      ideaId
    }
  });

  const [updateIdea] = useMutation(UPDATE_IDEA_MUT, {
    refetchQueries: ["IdeaDetail", "IdeasInno"]
  });

  const [addTag] = useMutation(ADD_TAG, {
    refetchQueries: ["IdeaDetail"]
  });

  const [deleteTag] = useMutation(DELETE_TAG_MUT, {
    refetchQueries: ["IdeaDetail"]
  });

  const alert = useAlert();

  const sendIdeasToInno = useSendIdeasToInno(([inno]) => {
    if (inno) alert.show("Idea added to innovations", { type: "success" });
  });

  const removeIdeasFromInno = useRemoveIdeasFromInno(({ success }) => {
    if (success)
      alert.show("Idea removed from innovations", { type: "success" });
  });

  const handleDeleteTag = async tagId => {
    await deleteTag({ variables: { id: tagId } });
  };

  const handleAddTag = event => {
    if (event) event.preventDefault();
    addTag({
      variables: {
        idea_id: ideaId,
        tag: newTagName
      }
    });
    setNewTagName("");
    setEditableTag(false);
    alert.show("Tag added to idea", { type: "success" });
  };

  const [deleteIdea] = useMutation(DELETE_IDEA_MUTT, {
    // refetchQueries: ["IdeasGrid"]
  });

  const handleRemoveIdea = async () => {
    const id = ideaId;
    await deleteIdea({ variables: { id } });
    modal.close();
    alert.show("Idea removed", { type: "success" });
  };

  const [collapsed, setCollapsed] = useState(false);
  const handleClick = () => {
    setCollapsed(!collapsed);
  };

  const [editableValue, setEditableValue] = useState(false);
  const handleEditValue = () => {
    setEditableValue(!editableValue);
    setEditableTag(false);
  };

  const [editableTag, setEditableTag] = useState(false);
  const handleEditTag = () => {
    setEditableTag(!editableTag);
    setEditableValue(false);
  };

  const [newTagName, setNewTagName] = useState("");
  const [newValue, setNewValue] = useState("");

  const [filterComments, setFilterComments] = useState(true);
  const handleFilterComments = () => {
    setFilterComments(!filterComments);
  };

  const ideaInnoState = idea?.innovationProcess?.state;

  const ideaCreatedEvent = {
    created_at: idea?.createdAt,
    created_by: {
      id: idea?.createdBy.id,
      email: idea?.createdBy.email,
      __typename: "user_audit"
    },
    data: { description: idea?.description, image: idea?.image },
    type: "IDEA_CREATED"
  };

  const timelineEvents = idea ? [...idea.timelineEvents, ideaCreatedEvent] : [];

  const handleSubmitValue = event => {
    if (event) event.preventDefault();
    updateIdea({
      variables: {
        id: ideaId,
        value: Number(newValue)
      }
    });
    setEditableValue(false);
  };

  const ideaDropdownActions = [
    {
      Icon: IconDownload,
      label: "Download image",
      link: idea?.image
    },
    {
      Icon: IconMoney,
      label: "Edit monetary value",
      handler: handleEditValue
    }
  ];

  if (idea?.innovationProcess === null) {
    ideaDropdownActions.push({
      Icon: IconInno,
      label: "Add to innovations",
      handler: sendIdeasToInno([idea])
    });
  }

  if (idea?.innovationProcess !== null) {
    ideaDropdownActions.push({
      Icon: IconInno,
      color: "danger",
      label: "Remove from innovations",
      handler: removeIdeasFromInno([idea])
    });
  }

  ideaDropdownActions.push({
    Icon: IconDelete,
    label: "Delete idea",
    color: "danger",
    handler: handleRemoveIdea
  });

  return (
    <div className="idea-detail__layout">
      <div
        className={classNames(
          "idea-detail__photo",
          collapsed && "idea-detail__photo--collapsed"
        )}
        onClick={handleClick}
      >
        <div
          style={{ backgroundImage: `url(${idea?.image || templateImage})` }}
          className={classNames(
            "idea-detail__photo__image",
            collapsed && "idea-detail__photo__image--collapsed"
          )}
        />
      </div>
      <div className="idea-detail__content">
        <div className="idea-detail__info">
          <div className="idea-detail__author">
            {idea && <User small user={idea.createdBy} />}
            {idea && (
              <Text nowrap color="black" block className="ml8">
                {idea?.createdAt && format.date(idea.createdAt)}
              </Text>
            )}
            <DropdownButton
              title="Actions"
              left
              small
              actions={ideaDropdownActions}
            />
          </div>
          <div className="idea-detail__info-sides">
            <div className="idea-detail__block-left">
              <Headline small>Description</Headline>
              <Text className="idea-detail__description mb16">
                {idea?.description || <i>No description.</i>}
              </Text>{" "}
            </div>
            <div className="idea-detail__block-right">
              <div>
                {editableValue ? (
                  <form onSubmit={handleSubmitValue}>
                    <div className="monetary-value__input-container">
                      <input
                        type="number"
                        autoFocus
                        defaultValue={idea.value}
                        onChange={({ target: { value } }) => setNewValue(value)}
                        className="ingrid-input ingrid-input--small ingrid-input--monetary-value"
                      />
                      <div className="ingrid-input--monetary-value__currency">
                        {organization.currency}
                      </div>
                    </div>
                    <div className="monetary-value__buttons">
                      <Button
                        fullwidth
                        small
                        className="mr4"
                        onClick={handleSubmitValue}
                        type="submit"
                      >
                        Save
                      </Button>
                      <Button
                        fullwidth
                        small
                        secondary
                        onClick={() => setEditableValue(false)}
                        type="button"
                      >
                        Cancel
                      </Button>
                    </div>
                  </form>
                ) : (
                  <div className="monetary-value__currency mb4">
                    <BadgeMoney className="monetary-value__icon" />
                    <b>
                      {idea?.value} {organization?.currency}
                    </b>
                    <div
                      className="idea-detail__value-edit"
                      onClick={handleEditValue}
                    >
                      Edit
                    </div>
                  </div>
                )}
              </div>
              <div>
                <div className="monetary-value__currency">
                  <div className="idea-detail__inno-circle">
                    {ideaInnoState === "SELECTED" && (
                      <IconInnoSelected
                        width="100%"
                        fill="var(--color-white)"
                      />
                    )}
                    {ideaInnoState === "APPROVED" && (
                      <IconInnoApproved
                        width="100%"
                        fill="var(--color-white)"
                      />
                    )}
                    {ideaInnoState === "IMPLEMENTING" && (
                      <IconInnoImplementing
                        width="100%"
                        fill="var(--color-white)"
                      />
                    )}
                    {ideaInnoState === "DONE" && (
                      <IconInnoDone width="100%" fill="var(--color-white)" />
                    )}
                    {!ideaInnoState && (
                      <IconInno width="12px" fill="var(--color-white)" />
                    )}
                  </div>
                  {ideaInnoState ? (
                    <div className="idea-detail__inno-label">
                      <b>{ideaInnoState}</b>{" "}
                      <div
                        className="idea-detail__inno-edit"
                        onClick={removeIdeasFromInno([idea])}
                      >
                        Remove
                      </div>
                    </div>
                  ) : (
                    <button
                      className="idea-detail__innovation-button"
                      onClick={sendIdeasToInno([idea])}
                    >
                      Add to innovations
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="idea-detail__tags-panel">
            <div className="idea-detail__tag-headline">
              <Headline small>Tags:</Headline>
            </div>
            <div className="idea-detail__tags-list">
              <button
                className={classNames(
                  "idea-detail__tags-add",
                  editableTag && "idea-detail__tags-add--rotated"
                )}
                onClick={handleEditTag}
              >
                <IconPlus
                  width="16px"
                  fill="var(--color-white)"
                  className={classNames(
                    "idea-detail__tags-plus--normal",
                    editableTag && "idea-detail__tags-plus--rotated"
                  )}
                />
              </button>
              {editableTag && (
                <form onSubmit={handleAddTag}>
                  <div className="tag__input-container">
                    <input
                      type="text"
                      maxLength="32"
                      autoFocus
                      onChange={({ target: { value } }) => setNewTagName(value)}
                      value={newTagName}
                      className="idea-detail__input--tag"
                    />
                    <Button
                      className="idea-detail__tags-confirm"
                      onClick={handleSubmitValue}
                      type="submit"
                    >
                      <IconCheck width="12px" fill="var(--color-white)" />
                    </Button>
                  </div>
                </form>
              )}
              {idea?.tags.map(tag => (
                <Tag
                  key={tag.id}
                  className="mr4 mb4"
                  onClick={() => {
                    const confirmed = window.confirm(
                      "Are you sure you want to delete this tag?"
                    );
                    if (!confirmed) return;
                    handleDeleteTag(tag.id);
                  }}
                >
                  {tag.tag}
                </Tag>
              ))}
            </div>
          </div>
        </div>
        {error && (
          <div>
            <Text color="error">{error.toString()}</Text>
          </div>
        )}
        {idea?.campaignIdeas && (
          <IdeaCampaigns
            campaignIdeas={idea?.campaignIdeas}
            ideaId={ideaId}
            ideaDescription={idea?.description}
          />
        )}
      </div>

      <div className="idea-detail__backlog">
        <div className="idea-detail__backlog-header">
          <Headline small className="idea-detail__backlog-headline">
            Backlog
          </Headline>
          <button
            onClick={handleFilterComments}
            className={classNames(
              "idea-detail__backlog-filter",
              filterComments && "idea-detail__backlog-filter--selected"
            )}
          >
            <IconComment width="14px" className="mr8" />
            {filterComments ? (
              <Text>Only comments are visible</Text>
            ) : (
              <Text>Everything is visible</Text>
            )}
          </button>
        </div>
        <Text block className="mb8">
          Write a comment:
        </Text>
        <CommentForm
          small
          ideaId={ideaId}
          orgId={idea?.organization_id}
          campaignId={campaignId}
          afterSubmit={refetch}
        />
        <Timeline events={timelineEvents} filterComments={filterComments} />
      </div>
    </div>
  );
}
