import React from "react";
import { Link } from "@reach/router";
import classNames from "classnames";
import "./AppSidebar.css";
import IconOrganization from "../assets/icons/icon-organization.svg";
import IconUser from "../assets/icons/icon-user.svg";
import IconTeam from "../assets/icons/icon-team.svg";
import IconIdea from "../assets/icons/icon-idea.svg";
import IconCampaign from "../assets/icons/icon-campaign.svg";
import IconPoll from "../assets/icons/icon-poll.svg";
import IconInno from "../assets/icons/icon-inno.svg";
import IconNotification from "../assets/icons/icon-notification.svg";
import IconLogout from "../assets/icons/icon-logout.svg";
import IconSuperadmin from "../assets/icons/icon-superadmin.svg";
import IconTemplate from "../assets/icons/icon-campaign.svg";
import AppCopyright from "./AppCopyright";
import { createLinkToModal } from "./Modal";

const navigationTree = [
  {
    link: "/superadmin",
    title: "Super admin",
    Icon: IconSuperadmin,
    superadmin: true
  },
  {
    link: "/",
    title: "Organization",
    Icon: IconOrganization
  },
  {
    link: "/users",
    title: "Users",
    Icon: IconUser
  },
  {
    link: "/users/teams",
    title: "Teams",
    Icon: IconTeam,
    teams: true
  },
  {
    link: "/ideas",
    title: "Ideas",
    Icon: IconIdea
    // children: [{ link: "/ideas/create", title: "Create idea", modal: true }]
  },
  {
    link: "/campaigns",
    title: "Campaigns",
    Icon: IconCampaign,
    // children: [{ link: "/campaigns/create", title: "Create campaign" }],
    campaigns: true
  },
  {
    link: "/templates",
    title: "Templates",
    Icon: IconTemplate,
    superadmin: true,
    superOrganization: true,
    children: []
  },
  {
    link: "/polls",
    title: "Polls",
    Icon: IconPoll,
    // children: [{ link: "/polls/create", title: "Create poll" }],
    polls: true
  },
  {
    link: "/inno",
    title: "Innovations",
    Icon: IconInno,
    children: [],
    inno: true
  },
  {
    link: "/notifications",
    title: "Notifications",
    Icon: IconNotification,
    children: []
  },
  { link: "/logout", title: "Logout", Icon: IconLogout, children: [] }
];

export default function AppSidebar({
  location: { pathname, hash, search },
  user,
  organization,
  superOrganization
}) {
  const activeClass = link => classNames(pathname + hash === link && "active");
  const modalLink = createLinkToModal({
    pathname,
    hash,
    search
  });

  return (
    <div className="sidebar">
      <nav className="sidebar__navbar">
        <ul>
          {navigationTree
            .filter(({ superadmin }) => !superadmin || user.superadmin)
            .filter(({ teams }) => !teams || organization?.teamsEnabled)
            .filter(({ polls }) => !polls || organization?.pollsEnabled)
            .filter(
              ({ campaigns }) =>
                !campaigns || organization?.activeCampaignsEnabled
            )
            .filter(
              ({ inno }) => !inno || organization?.innovationProcessEnabled
            )
            .filter(item => !item.superOrganization || superOrganization)
            .map(({ link, title, Icon, children = [] }, index) => (
              <li key={index} className={activeClass(link)}>
                <Link to={link}>
                  <div className="sidebar__iconFrame">
                    <Icon fill="#ffffff" height="16" />
                  </div>
                  {title}
                </Link>
                {children.length > 0 && (
                  <ul>
                    {children
                      .filter(
                        ({ superadmin }) => !superadmin || user.superadmin
                      )
                      .map(({ link, title, modal }, index) => (
                        <li key={index} className={activeClass(link)}>
                          <Link to={!modal ? link : modalLink(link)}>
                            {title}
                          </Link>
                        </li>
                      ))}
                  </ul>
                )}
              </li>
            ))}
        </ul>
      </nav>
      <div className="sidebar__copy">
        <AppCopyright location={{ pathname, hash, search }} />
      </div>
    </div>
  );
}
