import React, { useState } from "react";
import "./InnovationProcess.css";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { navigate } from "@reach/router";
import { createLinkToModal } from "../../components/Modal";
import Idea from "../../components/ingrid/Idea";
import Text from "../../components/ui/Text";
import Button from "../../components/ui/Button";

import IconInnoSelected from "../../assets/icons/icon-inno-selected.svg";
import IconInnoApproved from "../../assets/icons/icon-inno-approved.svg";
import IconInnoImplementing from "../../assets/icons/icon-inno-implementing.svg";
import IconInnoDone from "../../assets/icons/icon-inno-done.svg";
import useSendIdeasToNewPoll from "../../hooks/useSendIdeasToNewPoll";
import LoadingSquares from "../../components/ui/LoadingSquares";

export const IDEAS_INNO_QUERY = gql`
  query IdeasInno($organizationId: ID!) {
    ideas(
      organizationId: $organizationId
      innovationState: [SELECTED, APPROVED, IMPLEMENTING, DONE]
    ) {
      items {
        innovationProcess {
          id
          state
        }
        id
        image
        value
        description
        createdBy {
          id
          email
        }
        created_at
        campaignIdeas {
          id
          isHidden
          isExcludedFromStatistics
          isPromo
          campaign {
            id
            name
          }
          category {
            id
            type
          }
        }
        commentCount
      }
      meta {
        totalItems
      }
    }
  }
`;

const UPDATE_INNO_MUT = gql`
  mutation($ideas: [idea_inno_process_admin_upsert]) {
    upsertIdeaInnovationProcess(ideas: $ideas) {
      id
      state
    }
  }
`;

export default function InnovationScreen({ location, organization }) {
  const { error, loading, data: { ideas } = {} } = useQuery(IDEAS_INNO_QUERY, {
    variables: { organizationId: organization?.id },
    skip: !organization,
    fetchPolicy: "cache-and-network"
  });

  const [updateIdeasInno] = useMutation(UPDATE_INNO_MUT, {
    refetchQueries: ["IdeasInno"]
  });

  const [ideaDragged, setIdeaDragged] = useState("");

  const dragIdea = ideaId => event => {
    setIdeaDragged(ideaId);
  };

  const dropIdea = ideaState => event => {
    updateIdeasInno({
      variables: {
        ideas: [{ id: ideaDragged, state: ideaState }]
      }
    });
    event.currentTarget.classList.remove("inno-process-column--drag");
  };

  const ideaDraggedOver = event => {
    event.preventDefault();
    event.currentTarget.classList.add("inno-process-column--drag");
  };

  const ideaDraggedOut = event => {
    event.preventDefault();
    event.currentTarget.classList.remove("inno-process-column--drag");
  };

  const ideasByState = state =>
    ideas?.items.filter(
      ({ innovationProcess }) => innovationProcess?.state === state
    ) || [];

  const totalValue = (total, { value }) => total + value;

  const sendIdeasToNewPoll = useSendIdeasToNewPoll({ organization }, poll => {
    navigate(`/polls/create/${poll.id}`);
  });

  return (
    <div className="inno-process-screen">
      <div
        className="inno-process-column--first"
        onDragOver={ideaDraggedOver}
        onDragLeave={ideaDraggedOut}
        onDrop={dropIdea("SELECTED")}
      >
        <div className="inno-process-column__header">
          <IconInnoSelected width="24px" className="mb4" />
          <Text bold>Selected</Text>
          <Text small>
            {ideasByState("SELECTED").reduce(totalValue, 0)}{" "}
            {organization?.currency}
          </Text>
        </div>
        <div id="selected" className="inno-process-container">
          <div className="mb8 center-flex">
            <Button
              small
              onClick={sendIdeasToNewPoll(ideasByState("SELECTED"))}
              disabled={!ideasByState("SELECTED").length}
            >
              create new poll
            </Button>
          </div>
          <div className="inno-process-grid">
            {loading && <LoadingSquares count={5} height="248px" />}
            {ideasByState("SELECTED").map(idea => (
              <div key={idea.id} draggable onDragStart={dragIdea(idea.id)}>
                <Idea
                  onClick={() =>
                    navigate(createLinkToModal(location)(`/ideas/${idea.id}`))
                  }
                  className="mb8"
                  idea={idea}
                  currency={organization.currency}
                />
              </div>
            ))}
          </div>
          {error && (
            <Text small center color="error">
              {error.toString()}
            </Text>
          )}
        </div>
      </div>
      <div className="inno-process-divider" />
      <div
        className="inno-process-column mr16"
        onDragOver={ideaDraggedOver}
        onDragLeave={ideaDraggedOut}
        onDrop={dropIdea("APPROVED")}
      >
        <div className="inno-process-column__header">
          <IconInnoApproved width="24px" className="mb4" />
          <Text bold>Approved</Text>
          <Text small>
            {ideasByState("APPROVED").reduce(totalValue, 0)}{" "}
            {organization?.currency}
          </Text>
        </div>
        <div id="selected" className="inno-process-container">
          <div>
            {ideasByState("APPROVED").map(idea => (
              <div key={idea.id} draggable onDragStart={dragIdea(idea.id)}>
                <Idea
                  onClick={() =>
                    navigate(createLinkToModal(location)(`/ideas/${idea.id}`))
                  }
                  className="mb8"
                  key={idea.id}
                  idea={idea}
                  currency={organization.currency}
                />
              </div>
            ))}
          </div>
          {error && (
            <Text small center color="error">
              {error.toString()}
            </Text>
          )}
        </div>
      </div>
      <div
        className="inno-process-column"
        onDragOver={ideaDraggedOver}
        onDragLeave={ideaDraggedOut}
        onDrop={dropIdea("IMPLEMENTING")}
      >
        <div className="inno-process-column__header">
          <IconInnoImplementing width="24px" className="mb4" />
          <Text bold>Implementing</Text>
          <Text small>
            {ideasByState("IMPLEMENTING").reduce(totalValue, 0)}{" "}
            {organization?.currency}
          </Text>
        </div>
        <div id="selected" className="inno-process-container">
          <div>
            {ideasByState("IMPLEMENTING").map(idea => (
              <div key={idea.id} draggable onDragStart={dragIdea(idea.id)}>
                <Idea
                  onClick={() =>
                    navigate(createLinkToModal(location)(`/ideas/${idea.id}`))
                  }
                  className="mb8"
                  idea={idea}
                  currency={organization.currency}
                />
              </div>
            ))}
          </div>
          {error && (
            <Text small center color="error">
              {error.toString()}
            </Text>
          )}
        </div>
      </div>
      <div className="inno-process-divider" />
      <div
        className="inno-process-column"
        onDragOver={ideaDraggedOver}
        onDragLeave={ideaDraggedOut}
        onDrop={dropIdea("DONE")}
      >
        <div className="inno-process-column__header">
          <IconInnoDone width="24px" className="mb4" />
          <Text bold>Done</Text>
          <Text small>
            {ideasByState("DONE").reduce(totalValue, 0)}{" "}
            {organization?.currency}
          </Text>
        </div>
        <div id="selected" className="inno-process-container">
          <div>
            {ideasByState("DONE").map(idea => (
              <div key={idea.id} draggable onDragStart={dragIdea(idea.id)}>
                <Idea
                  onClick={() =>
                    navigate(createLinkToModal(location)(`/ideas/${idea.id}`))
                  }
                  className="mb8"
                  idea={idea}
                  currency={organization.currency}
                />
              </div>
            ))}
          </div>
          {error && (
            <Text small center color="error">
              {error.toString()}
            </Text>
          )}
        </div>
      </div>
    </div>
  );
}
