import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

const LOAD_FROM_TEMPLATE_MUTT = gql`
  mutation($id: ID!) {
    loadFromTemplate(id: $id) {
      id
    }
  }
`;

export default function useLoadFromTemplate(
  callback: ({ id: Number }) => void
) {
  const [loadFromTemplate] = useMutation(LOAD_FROM_TEMPLATE_MUTT, {
    refetchQueries: ["CampaignDrafts"]
  });

  return id => {
    loadFromTemplate({
      variables: { id }
    }).then(({ data: { loadFromTemplate: campaign } }) => {
      callback(campaign);
    });
  };
}
