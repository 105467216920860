import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import Poll from "../../components/ingrid/Poll";
import Headline from "../../components/ui/Headline";
import LoadingSquares from "../../components/ui/LoadingSquares";
import CurrentPoll from "./CurrentPoll";
import PollDashboard from "./PollDashboard";
import "./Polls.css";

export const CAMPAIGNS_QUERY = gql`
  query PollsGrid($organizationId: ID!) {
    campaigns(
      organizationId: $organizationId
      order: "reverse:to"
      where: { type: "PASSIVE" }
    ) {
      items {
        id
        name
        from
        to
        target
        state
        campaignStats {
          activeUsersCount
          ideaCount
        }
        categories {
          id
          name
          color
          type
          ideaCount
        }
        teams {
          id
          name
        }
      }
      meta {
        totalItems
      }
    }

    campaignStats(organizationId: $organizationId, where: { type: "PASSIVE" }) {
      name
      type
      ideaCount
      votedUsersCount
      invitedUsersCount
    }
  }
`;

export default function PollsScreen({ location, organization }) {
  const { loading, error, data, refetch } = useQuery(CAMPAIGNS_QUERY, {
    variables: {
      organizationId: organization?.id
    },
    fetchPolicy: "cache-and-network",
    skip: !organization
  });

  return (
    <div className="polls-screen">
      <PollDashboard campaignStats={data?.campaignStats} />
      <div className="polls-screen__container">
        <CurrentPoll
          location={location}
          organizationId={organization?.id}
          organization={organization}
        />

        <div className="polls-screen__all-campaigns-header">
          <div className="polls-screen__all-campaigns-headline">
            <Headline id="all">All your polls</Headline>
          </div>
        </div>

        <div className="polls-screen__grid">
          {loading && <LoadingSquares count={7} height="218px" />}
          {data?.campaigns.items
            .filter(({ state }) => state !== "DRAFT")
            .map(campaign => (
              <Poll
                key={campaign.id}
                campaign={campaign}
                afterCampaignRemove={refetch}
                location={location}
              />
            ))}
          <div>{error && error.toString()}</div>
        </div>
      </div>
    </div>
  );
}
