import React from "react";
import classNames from "classnames";
import "./Card.css";

export default function Card({
  className,
  fullwidth,
  small,
  children: content,
  onClick,
  ...pass
}) {
  return (
    <div
      className={classNames(
        "card",
        className,
        small && "card--small",
        fullwidth && "card--fullwidth"
      )}
      onClick={onClick}
      {...pass}
    >
      {content}
    </div>
  );
}
