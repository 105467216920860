import React, { useState, useEffect } from "react";
import TemplateCategory from "./TemplateCategory";
import { LocationProvider } from "@reach/router";
import Checkbox from "../../../components/ui/Checkbox";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

const pickCategories = (categories = []) => [
  // primary category
  categories.find(({ main }) => main) || {
    main: true,
    type: "INNO",
    name: "",
    enabled: true
  },
  // secondary. this is weak, we can get more than 2 categories
  categories.filter(({ main }) => !main)[0] || {
    main: false,
    type: "FUN",
    name: "",
    enabled: false
  }
];

const UPSERT_CATEGORY_MUTT = gql`
  mutation(
    $id: ID
    $main: Boolean!
    $templateId: ID!
    $name: String!
    $description: String
    $ideaIds: [ID]
    $type: plan_template_category_type!
    $enabled: Boolean
  ) {
    upsertPlanTemplateCategory(
      id: $id
      main: $main
      planTemplateId: $templateId
      name: $name
      description: $description
      ideaIds: $ideaIds
      type: $type
      enabled: $enabled
    ) {
      id
      main
      name
      type
      description
      planTemplateId
      enabled
      ideaIds
    }
  }
`;

Categories.validate = ({
  planTemplateCategories: categories,
  name: templateName
} = {}) => {
  if (!categories?.length) return false;

  return (categories || []).reduce(
    (isValid, { name, description, enabled, main }) =>
      (isValid && (name || (main && templateName))) || !enabled,
    true
  );
};

export default function Categories({
  template: {
    name: templateName,
    planTemplateCategories: categories = []
  } = {},
  templateId // it's from url, so its immediately
} = {}) {
  const template = { name: templateName, categories };
  const loaded = templateId === template.id;

  const [enableSecondCategory, setEnableSecondCategory] = useState(false);
  const [primaryCategory, secondaryCategory] = pickCategories(categories);

  useEffect(() => {
    if (!loaded) return;

    // [2] enable second category if exists
    if (categories.length > 1) {
      setEnableSecondCategory(categories.find(({ main }) => main === false));
    }
  }, [categories, loaded]); // [1] IF categories or loading state changed

  const [upsertCategory] = useMutation(UPSERT_CATEGORY_MUTT, {
    refetchQueries: ["Template", "TemplateIdeas"]
  });
  const updateCategory = categoryId => updates => {
    const category = categories.find(({ id }) => categoryId === id); // Must be loaded from fresh categories prop
    if (!templateId || !category?.id) return new Promise(re => re()); // Never allow to create category WA template

    // Not sure about this
    const name =
      updates.name ||
      category.name ||
      templateName ||
      `${category.type} category`;

    // Has to return promise
    return upsertCategory({
      variables: {
        templateId: Number(templateId),
        ...category,
        ...updates,
        name
      },
      optimisticResponse: {
        __typename: "Mutation",
        upsertPlanTemplateCategory: {
          planTemplateId: Number(templateId),
          ...category,
          ...updates,
          __typename: "plan_template_category",
          name
        }
      }
    });
  };
  return (
    <div className="template-wizard-categories">
      <p>
        Setup your template categories. Usually you want one innovation category
        to get as much inovations from your users as possible, but also a fun
        category to engage them to use the app often.
      </p>

      <Checkbox
        name="enabled"
        value={secondaryCategory?.enabled}
        onChange={({ value: enabled }) => {
          upsertCategory({
            variables: { ...secondaryCategory, templateId, enabled }
            // idk how to fake id
            // optimisticResponse: {
            //   upsertPlanTemplateCategory: {
            //     ...secondaryCategory,
            //     description: secondaryCategory?.description || "",
            //     planTemplateId: templateId,
            //     enabled,
            //     ideaIds: [],
            //     __typename: "plan_template_category"
            //   }
            // }
          });
        }}
      >
        Enable secondary category
      </Checkbox>
      <LocationProvider>
        {({ location }) => (
          <div className="template-wizard-categories__container">
            <TemplateCategory
              location={location}
              category={primaryCategory}
              onCategoryUpdate={updateCategory(primaryCategory?.id)}
              templateId={templateId}
              templateName={templateName}
            />
            <TemplateCategory
              secondary
              enabled={enableSecondCategory}
              location={location}
              category={secondaryCategory}
              onCategoryUpdate={updateCategory(secondaryCategory?.id)}
              templateId={templateId}
              templateName={templateName}
            />
          </div>
        )}
      </LocationProvider>
    </div>
  );
}
