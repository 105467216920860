import axios from "axios";

export default function createAxiosInstance({ serverAddress, auth }) {
  const instance = axios.create({
    baseURL: serverAddress,
    timeout: 10000,
    validateStatus: status => status < 500 // Reject only if the status code is greater than or equal to 500
  });
  return instance;
}
