import { useQuery } from "@apollo/react-hooks";
import { navigate } from "@reach/router";
import gql from "graphql-tag";
import React from "react";
import IconDelete from "../../assets/icons/icon-cross.svg";
import IconUserInvite from "../../assets/icons/icon-user-invite.svg";
import FilterRow from "../../components/ingrid/FilterRow";
import UserRow from "../../components/ingrid/UserRow";
import { createLinkToModal } from "../../components/Modal";
import Button from "../../components/ui/Button";
import Card from "../../components/ui/Card";
import Dropdown from "../../components/ui/Dropdown";
import SortIcon from "../../components/ui/SortIcon";
// import Button from "../../components/ui/Button";
import Text from "../../components/ui/Text";
import VirtualizedList from "../../components/VirtualizedList";
import useUrlParams from "../../hooks/useUrlParams";
import "./Users.css";

const USERS_PER_SCREEN = 16;
const USERS_QUERY = gql`
  query UsersList($orgId: ID!, $offset: Int, $teamId: ID, $where: SequelizeJSON, $order: String, $isAdmin: Boolean) {
    users(
      organizationId: $orgId,
      offset: $offset,
      limit: ${USERS_PER_SCREEN}
      order: $order
      teamId: $teamId
      isAdmin: $isAdmin
      where: $where
    ) {
      items {
        id
        email
        ideaCount
        rank
        team {
          id
          name
        }
        lastLoggedAt
        hasBeenActive
        invitedAt
        invitationAcceptedAt
        role
        superadmin
        state
      }
      meta {
        totalItems
      }
    }
  }
`;
const ORDER_ALLOWED = [
  "email",
  "invitedAt",
  "rank",
  "ideaCount",
  "invitationAcceptedAt",
  "lastLoggedAt",
  "ideaCount",
  "rank"
];
const ORDER_PREFIX = "reverse";

const getOrderParams = urlParams => {
  if (!urlParams.order) {
    return;
  }
  const orderArray = urlParams.order.split(":");
  const field = orderArray.pop();
  const isReversed = orderArray.length === 1 && orderArray[0] === ORDER_PREFIX;

  var full = "email";
  if (ORDER_ALLOWED.includes(field)) {
    full = urlParams.order;
  }
  return { field, isReversed, full };
};

export default function UsersScreen({ location, organization }) {
  const [urlParams, { updateUrlParam }] = useUrlParams(location);
  const orderParams = getOrderParams(urlParams);
  const { networkStatus, error, data, fetchMore } = useQuery(USERS_QUERY, {
    variables: {
      orgId: organization?.id,
      teamId: urlParams.team,
      order: orderParams?.full,
      isAdmin: urlParams.admin,
      where: {
        ...(urlParams.search
          ? { email: { iLike: "%" + urlParams.search + "%" } }
          : {}),
        ...(urlParams.userState
          ? { state: urlParams.userState.toUpperCase() }
          : {})
      }
    },
    notifyOnNetworkStatusChange: true, // to get updated networkStatus
    skip: !organization
  });

  const loading = [1, 3].includes(networkStatus);

  const loadMore = ({ startIndex, stopIndex }) => {
    if (!organization) return;
    return fetchMore({
      query: USERS_QUERY,
      variables: {
        orgId: organization?.id,
        offset: startIndex, // (data?.users?.length || 0) + USERS_PER_SCREEN
        where: {
          ...(urlParams.search
            ? { email: { iLike: "%" + urlParams.search + "%" } }
            : {}),
          ...(urlParams.userState
            ? { state: urlParams.userState.toUpperCase() }
            : {})
        },
        teamId: urlParams.team,
        isAdmin: urlParams.admin,
        order: orderParams?.full
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        return {
          ...previousResult,
          users: {
            items: previousResult.users.items.concat(
              fetchMoreResult.users.items
            ),
            meta: fetchMoreResult.users.meta,
            __typename: "users_list"
          }
        };
      }
    });
  };

  const sortBy = field => {
    var order = field;
    if (orderParams?.field === field) {
      if (orderParams?.isReversed) {
        order = false;
      } else {
        order = ORDER_PREFIX + ":" + order;
      }
    }
    urlParams.order = order;
    updateUrlParam(urlParams);
  };

  const sortByIndicator = field => {
    if (orderParams?.field === field) {
      return orderParams?.isReversed ? "down" : "up";
    }
  };

  return (
    <div className="users-screen">
      <FilterRow
        showUserState
        teams={organization?.teamsEnabled || false}
        campaign={false}
        className="users-screen__filter-row"
        values={urlParams}
        onChange={updateUrlParam}
        organizationId={organization?.id}
        itemsFilteredCount={{
          data: data?.users.meta.totalItems,
          label: " users"
        }}
      >
        <Button
          icon
          small
          onClick={() => {
            const modalLink = createLinkToModal(location)("/users/invite");
            navigate(modalLink);
          }}
        >
          <IconUserInvite
            width="14px"
            fill="var(--color-white)"
            className="mr8"
          />
          Invite users
        </Button>
        <div className="ingrid-user__dropdown">
          <div className="ingrid-user__dropdown__text">ACTIONS</div>
          <Dropdown
            bottom
            style={{ backgroundColor: "white" }}
            actions={[
              // {
              //   Icon: IconStar,
              //   label: "Promote admin ...",
              //   handler: () => {}
              // },
              {
                Icon: IconDelete,
                label: "Remove users ...",
                handler: () => {
                  navigate(createLinkToModal(location)(`/users/remove`));
                },
                color: "danger"
              }
            ].filter(x => Boolean(x))}
          />
        </div>
      </FilterRow>

      {error && (
        <div className="users-screen__error">
          <Text color="error">{error.toString()}</Text>
        </div>
      )}
      <div className="users-screen__content">
        <Card fullwidth>
          <div className="users-screen__header">
            <div
              className="users-screen__header-column--first users-screen__header-column--sort"
              onClick={() => sortBy("email")}
            >
              <Text small bold>
                Username
              </Text>
              <SortIcon state={sortByIndicator("email")} />
            </div>
            <div
              className="users-screen__header-column--big users-screen__header-column--sort"
              onClick={() => sortBy("lastLoggedAt")}
            >
              <Text small bold>
                Last activity
              </Text>
              <SortIcon state={sortByIndicator("lastLoggedAt")} />
            </div>
            <div
              className="users-screen__header-column--big users-screen__header-column--sort"
              onClick={() => sortBy("invitedAt")}
            >
              <Text small bold>
                Invited
              </Text>
              <SortIcon state={sortByIndicator("invitedAt")} />
            </div>
            <div
              className="users-screen__header-column--big users-screen__header-column--sort"
              onClick={() => sortBy("invitationAcceptedAt")}
            >
              <Text small bold>
                Registered
              </Text>
              <SortIcon state={sortByIndicator("invitationAcceptedAt")} />
            </div>
            <div
              className="users-screen__header-column users-screen__header-column--sort"
              onClick={() => sortBy("ideaCount")}
            >
              <Text small bold>
                Ideas
              </Text>
              <SortIcon state={sortByIndicator("ideaCount")} />
            </div>
            <div
              className="users-screen__header-column users-screen__header-column--sort"
              onClick={() => sortBy("rank")}
            >
              <Text small bold>
                Rank
              </Text>
              <SortIcon state={sortByIndicator("rank")} />
            </div>
            {organization?.teamsEnabled && (
              <div className="users-screen__header-column">
                <Text small bold>
                  Team
                </Text>
              </div>
            )}
            <div className="users-screen__header-column users-screen__header-column--menu" />
          </div>
          <div className="users-screen__list-container">
            <VirtualizedList
              list={data?.users.items || []}
              loaded={loading === false}
              loadMore={loadMore}
              rowHeight={UserRow.HEIGHT_NORMAL}
              className="users-screen__list"
              totalItemsCount={data?.users.meta.totalItems}
              placeholder="No users in this organization."
            >
              {user => (
                <UserRow
                  key={user?.id}
                  user={user}
                  loaded={!loading}
                  organization={organization}
                  location={location}
                />
              )}
            </VirtualizedList>
          </div>
        </Card>
      </div>
    </div>
  );
}
