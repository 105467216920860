import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./Badge.css";
import { emailToInitials } from "../../library/string";
import { isEmail } from "../../library/validation";

Badge.propTypes = {
  className: PropTypes.string,
  string: PropTypes.string,
  large: PropTypes.bool,
  small: PropTypes.bool,
  invert: PropTypes.bool
};

export default function Badge({
  string,
  small,
  large,
  team,
  className,
  invert
}) {
  if (!string) {
    return null;
  }

  const initials = isEmail(string)
    ? emailToInitials(string, { max: 2 }).join("")
    : string.substr(0, 2);

  const teamName =
    team === "ORGANIZATION" || team === "USER"
      ? string
      : string.substr(0, 2).toUpperCase();

  return (
    <div
      className={classNames(
        "badge",
        small && "badge--small",
        large && "badge--large",
        invert && "badge--invert",
        (team === "ORGANIZATION" || team === "USER") && "badge--organization",
        className
      )}
    >
      <div
        className={classNames(
          "badge__background",
          invert && "badge__background--invert",
          team && "badge__background--team"
        )}
      >
        <span
          className={classNames(
            "badge__initials",
            invert && "badge__initials--invert",
            team && "badge__initials--team"
          )}
        >
          {team ? teamName : initials.toUpperCase()}
        </span>
      </div>
    </div>
  );
}
