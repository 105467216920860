// import IconIdeas from "../../assets/icons/icon-idea.svg";
// import IconInnoSelected from "../../assets/icons/icon-inno-selected.svg";
// import IconInnoApproved from "../../assets/icons/icon-inno-approved.svg";
// import IconInnoImplementation from "../../assets/icons/icon-inno-implementing.svg";
// import IconInnoDone from "../../assets/icons/icon-inno-done.svg";
import { useQuery } from "@apollo/react-hooks";
import { navigate } from "@reach/router";
import gql from "graphql-tag";
import React from "react";
import IconIdeas from "../../assets/icons/icon-idea.svg";
import IconReplication from "../../assets/icons/icon-replication.svg";
import IconValue from "../../assets/icons/icon-money.svg";
import IconSettings from "../../assets/icons/icon-settings.svg";
import IconUsers from "../../assets/icons/icon-users.svg";
import ChartIdeasCampaigns from "../../components/ingrid/ChartIdeasCampaigns";
import ChartIdeasInnovation from "../../components/ingrid/ChartIdeasInnovation";
import ChartUsersEngagement from "../../components/ingrid/ChartUsersEngagement";
import ChartUsersFunnel from "../../components/ingrid/ChartUsersFunnel";
import InnovProcessValue from "../../components/ingrid/InnovProcessValue";
import { createLinkToModal } from "../../components/Modal.js";
import Button from "../../components/ui/Button";
import Headline from "../../components/ui/Headline";
import IconStars from "../../components/ui/IconStars";
import Text from "../../components/ui/Text";
import InfoPanel from "./InfoPanel";
import "./Organization.css";

const ORGANIZATION_QUERY = gql`
  query OrganizationDashboard($orgId: ID!) {
    organization(id: $orgId) {
      id
      name
      currency
      timezone
      created_by {
        id
        email
      }
      pollsEnabled
      teamsEnabled
      innovationProcessEnabled
      sendInvitationEmailsEnabled
      activeCampaignsEnabled
      voteOutsideTeamEnabled
    }
    userStatistics(organizationId: $orgId) {
      invited
      registered
      active
      engaged
      innovator
      replicator
    }
    innovationProcess(organizationId: $orgId) {
      state
      value
      count
    }
    userEngagement(organizationId: $orgId) {
      engagement {
        date
        count
      }
      notifications {
        date
        count
        titles
      }
    }
    campaignStats(organizationId: $orgId) {
      rating
    }
    campaigns(organizationId: $orgId, order: "to", where: { type: "ACTIVE" }) {
      items {
        name
        state
        to
        categories {
          type
          ideaCount
        }
      }
    }
  }
`;

export default function OrganizationsScreen({ organization, location, auth }) {
  const showOrganizationSettingsModal = () => {
    const link = createLinkToModal(location)("/organization/settings");
    navigate(link);
  };

  const {
    error,
    data: {
      organization: organizationDetail,
      userStatistics,
      innovationProcess,
      campaigns,
      userEngagement,
      campaignStats
    } = {}
  } = useQuery(ORGANIZATION_QUERY, {
    variables: { orgId: organization?.id },
    skip: !organization,
    fetchPolicy: "cache-and-network"
  });

  if (error) {
    return (
      <div className="current-campaigns current-campaigns--error">
        <Text small center color="error">
          {error.toString()}
        </Text>
      </div>
    );
  }

  return (
    <div className="organization-screen">
      <div className="organization-screen__dashboard">
        <div className="organization-screen__header">
          <Headline large>{organization?.name}</Headline>
          {organizationDetail && (
            <InfoPanel organization={organizationDetail} />
          )}
          {
            <Button icon onClick={showOrganizationSettingsModal}>
              <IconSettings
                width="24px"
                fill="var(--color-white)"
                className="mr8"
              />
              Settings
            </Button>
          }
        </div>
        <div className="organization-dashboard">
          <div>
            <Headline small className="mb4">
              Basic info
            </Headline>
            <div className="organization-dashboard__kpis">
              <div className="organization-dashboard__kpis__card mr16">
                <div className="organization-dashboard__kpis__icon">
                  <IconUsers height="100%" />
                </div>
                <Text large bold block>
                  {userStatistics?.active}
                </Text>
                <Text center small block>
                  Active users
                </Text>
              </div>
              {organization?.innovationProcessEnabled && (
                <div className="organization-dashboard__kpis__card mr16">
                  <div className="organization-dashboard__kpis__icon">
                    <IconValue height="80%" />
                  </div>
                  <InnovProcessValue
                    innovationProcess={innovationProcess}
                    organization={organization}
                  />
                  <Text center small block>
                    Total campaign value
                  </Text>
                </div>
              )}
              <div className="organization-dashboard__kpis__card">
                <div className="organization-dashboard__kpis__icon">
                  <IconStars filled={campaignStats?.[0]?.rating} />
                </div>
                <Text center small block>
                  Campaign user ranking
                </Text>
              </div>
            </div>
          </div>
          {organization?.innovationProcessEnabled && (
            <ChartIdeasInnovation
              innovationProcess={innovationProcess}
              className="organization-dashboard"
            />
          )}
          <ChartUsersFunnel userStatistics={userStatistics} />
          <ChartIdeasCampaigns campaigns={campaigns} />
          <ChartUsersEngagement userEngagement={userEngagement} height={160} />
          <div className="organization-dashboard__graph-container">
            <Headline className="mb4" small>
              Power users
            </Headline>
            <div className="organization-dashboard__inno">
              <div
                className="organization-dashboard__card mr8"
                onClick={() => {
                  navigate("/users/?userState=innovator");
                }}
              >
                <IconIdeas width="32px" className="mb8" />
                <Text large bold>
                  {userStatistics?.innovator | 0}
                </Text>
                <Text>Innovators</Text>
              </div>
              <div
                className="organization-dashboard__card mr8"
                onClick={() => {
                  navigate("/users/?userState=replicator");
                }}
              >
                <IconReplication width="32px" className="mb8" />
                <Text large bold>
                  {userStatistics?.replicator | 0}
                </Text>
                <Text>Replicators</Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
