// import React, { useState } from "react";

import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

const REMOVE_INNO_MUTT = gql`
  mutation($ideaIds: [ID!]) {
    deleteIdeaFromInnovationProcess(ideaIds: $ideaIds)
  }
`;

export default function useRemoveIdeasFromInno(callback: ideasInno => void) {
  const [mutateRemove] = useMutation(REMOVE_INNO_MUTT, {
    refetchQueries: ["IdeaDetail", "IdeasInno"]
  });

  return function removeIdeasFromInno(ideas) {
    const ideaIds = ideas.map(idea => idea?.id || idea);

    return async () => {
      if (!ideas.length) return;

      const {
        data: { deleteIdeaFromInnovationProcess: success }
      } = await mutateRemove({
        variables: { ideaIds }
      });

      callback({ success });
    };
  };
}
