import { useMutation } from "@apollo/react-hooks";
import { navigate } from "@reach/router";
import cs from "classnames";
import gql from "graphql-tag";
import React from "react";
import IconCampaign from "../../assets/icons/icon-campaign.svg";
import IconPoll from "../../assets/icons/icon-poll.svg";
import IconBegin from "../../assets/icons/icon-circle-play.svg";
import IconEnd from "../../assets/icons/icon-circle-trophy.svg";
import IconDelete from "../../assets/icons/icon-cross.svg";
import IconEdit from "../../assets/icons/icon-edit.svg";
import IconMenu from "../../assets/icons/icon-kebab-big.svg";
import IconNotification from "../../assets/icons/icon-notification.svg";
import { createLinkToModal } from "../../components/Modal";
import Dropdown from "../../components/ui/Dropdown";
import * as format from "../../library/format";
import Badge from "../ingrid/Badge";
// import gql from "graphql-tag";
// import { useMutation } from "@apollo/react-hooks";
import Card from "../ui/Card";
import Text from "../ui/Text";
import "./Notification.css";

const DELETE_NOTIFICATION_MUTT = gql`
  mutation($id: ID!) {
    deleteNotification(id: $id)
  }
`;

const getTeamNameById = (array, id) => array.find(item => item.id === id)?.name;

export default function Notification({
  notification,
  campaign,
  teamList,
  editable,
  deletable,
  className
}) {
  const [deleteNotification] = useMutation(DELETE_NOTIFICATION_MUTT, {
    refetchQueries: [
      "NotificationsList",
      "CampaignQuery",
      "CampaignDraft",
      "PollDraft"
    ]
  });

  const handleEditNotification = location => {
    if (editable)
      navigate(
        createLinkToModal(location)(`/notifications/edit/${notification?.id}`)
      );
  };

  const showNotificationDetail = location => {
    navigate(
      createLinkToModal(location)(`/notifications/${notification?.id}`, {
        campaign: campaign?.id
      })
    );
  };

  const handleRemoveNotification = async () => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this notification?"
    );
    if (!confirmed) return;
    await deleteNotification({ variables: { id: notification?.id } });
  };

  const notificationActionList = [
    {
      Icon: IconNotification,
      label: "Show detail",
      handler: showNotificationDetail
    }
  ];

  if (editable) {
    notificationActionList.push({
      Icon: IconEdit,
      label: "Edit notification",
      handler: handleEditNotification
    });
  }

  if (deletable) {
    notificationActionList.push({
      Icon: IconDelete,
      color: "danger",
      label: "Delete notification",
      handler: handleRemoveNotification
    });
  }

  return (
    <div className="notification-card-container">
      <Card
        className={cs(
          "notification",
          editable && "notification--editable",
          className
        )}
      >
        <div className="notification__header">
          <div
            onClick={showNotificationDetail}
            className="notification__header--left"
          >
            {notification?.type === "BEGIN" && (
              <div data-title="Notification will be sent at the beginning of it's campaign or poll.">
                <IconBegin
                  width="24px"
                  className="mr8"
                  fill="var(--color-green)"
                />
              </div>
            )}
            {notification?.type === "END" && (
              <div data-title="Notification will be sent at the end of it's campaign or poll.">
                <IconEnd
                  width="24px"
                  className="mr8"
                  fill="var(--color-black)"
                />
              </div>
            )}
            <Text>
              {Boolean(notification?.sendAt) ? (
                format.date(notification?.sendAt)
              ) : (
                <i>Date not set</i>
              )}
            </Text>
          </div>
          {
            <div className="notification__header-menu">
              <IconMenu width="24" />

              <div className="notification__header-dropdown">
                <Dropdown bottom actions={notificationActionList} />
              </div>
            </div>
          }
        </div>
        <div className="notification__content">
          <Text small bold block className="mr4">
            {notification?.title}
            {/* {notification?.draft && (
              <Text tiny italic block className="ml4">
                draft
              </Text>
            )} */}
          </Text>
          <Text small truncate>
            {notification?.content}
          </Text>
        </div>
        <div className="notification__footer">
          <div className="notification__team-badges">
            {notification?.target === "TEAM" &&
              notification?.targetIds?.map(teamId => (
                <div
                  key={teamId}
                  data-title={getTeamNameById(teamList, teamId)}
                  className="ml4"
                >
                  <Badge
                    string={getTeamNameById(teamList, teamId)}
                    small
                    team
                  />
                </div>
              ))}
            {notification?.target === "USER" && (
              <div className="ml4">
                <Badge
                  string={notification?.targetIds.length + " users"}
                  small
                  team={notification?.target}
                />
              </div>
            )}
            {notification?.target === "ORGANIZATION" && (
              <div key="id" className="ml4">
                <Badge
                  string="Organization"
                  small
                  team={notification?.target}
                />
              </div>
            )}
          </div>
          {campaign && (
            <div className="notification__card__campaign">
              {campaign.type === "ACTIVE" ? (
                <div data-title="Campaign name">
                  <IconCampaign
                    width="12px"
                    className="ml4 mr8"
                    fill="var(--color-gray-dark)"
                  />
                </div>
              ) : (
                <div data-title="Poll name">
                  <IconPoll
                    width="12px"
                    className="ml4 mr8"
                    fill="var(--color-gray-dark)"
                  />
                </div>
              )}
              <Text small>
                {campaign.type === "ACTIVE"
                  ? campaign?.name || <i>Campaign name not set</i>
                  : campaign?.name || <i>Poll name not set</i>}
              </Text>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
}
